import { ManagerAccount } from './managerAccount';
import styles from './managerPage.module.scss';
import React from 'react';

export const ManagerForm = ({ user }) => {
  return (
    <>
      <div className={styles.administratorBody}>
        <div className={styles.bodyText}>Administrator</div>
        <div className={styles.managerText}>
          You can add and assign roles of managers and admins to manage the company&apos;s business
          account.
        </div>
        <ManagerAccount user={user} />
      </div>
    </>
  );
};
