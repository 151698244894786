import React from 'react';
import styles from '../../account.module.scss';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

const SelectComponent = ({
  getValues,
  values,
  name,
  valueField,
  labelField,
  placeholder,
  changeValue,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  return (
    <FormControl error={errors[name]} fullWidth>
      <Select
        placeholder={placeholder}
        className={styles.accountSelect}
        value={getValues(name)}
        displayEmpty
        defaultValue=""
        sx={{
          padding: '0px',
          color: 'rgba(33, 33, 33, 1)',
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '12px',
            borderColor: 'rgba(239, 239, 239, 1)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderRadius: '12px',
            borderColor: 'rgba(239, 239, 239, 1)',
          },
        }}
        inputProps={register(name, {
          required: 'Please fill out the rest fields. This is mandatory.',
        })}
        onChange={(e) => {
          setValue(name, e.target.value);
          changeValue(e.target.value);
          if (name === 'industry_group') {
            setValue('subindustry', '');
          }
        }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: 'rgba(117, 117, 117, 1)' }}>{placeholder}</span>
        </MenuItem>
        {values.map((item, index) => (
          <MenuItem key={index} value={item[valueField]}>
            {item[labelField]}
          </MenuItem>
        ))}
      </Select>
      {errors[name] && (
        <FormHelperText sx={{ marginLeft: 0 }}>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectComponent;
