import React, { useContext } from 'react';
import userContext from '../../context/User/userContext';
import ProductAndServicesPage from '../../components/pages/ProductAndServicesPage';

export const ProductAndServices = ({ isProduction }) => {
  const { user } = useContext(userContext);

  return (
    <ProductAndServicesPage
      user={user}
      isProduction={isProduction}
      isSeller={false}
      pageType="marketplace"
    />
  );
};
