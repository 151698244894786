import { Box, Checkbox, FormControlLabel, Hidden, Menu, styled, Typography } from '@mui/material';
import styles from './deliveryForm.module.scss';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from '@apollo/client';
import LocationModal from '../../../../locationModal';
import {
  CREATE_ADDRESS_LOCATION,
  CREATE_OR_UPDATE_COMPANY_PICKUP_ADDRESSES,
  DELETE_COMPANY_PICKUP_ADDRESS,
} from '../../../../../api';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Image from '../../../../Image';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import { Button, HelpTooltip } from 'tt-ui-kit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import { AddNewPickupModal } from '../../../companyProfilePage/forms/deliveryForm/addNewPickupModal';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import DeleteItemModal from '../../../../deleteItemModal';

let deliveryMethods = [
  {
    label: 'Pickup',
    name: 'delivery_pickup',
    helpMessage:
      'If applicable, check the box and enter details on your delivery pickup policy. Provide the address of your store, buying club drop-off location, etc Pay attention to the fact that the more flexible and diverse methods of delivery you offer your Buyers, the more attractive you will be to them as the Seller.',
    disabled: false,
  },
  // {
  //   label: 'Digital',
  //   name: 'delivery_digital',
  //   disabled: true,
  // },
  // {
  //   label: 'By post',
  //   name: 'delivery_shipping',
  //   helpMessage:
  //     'This way delivery can expand the geography of sales and is for the manufacturer of honey, mushrooms, herbal collections, etc.If you are ready to deliver your products, goods or services by postal, courier or transport companies to the addresses indicated by the Buyers in their applications, check the box to the left of this menu item.',
  //   disabled: true,
  // },
  {
    label: 'Seller Delivery',
    name: 'delivery_hand',
    helpMessage:
      'If applicable, check the box and enter details on your delivery pickup policy. Receive proof of delivery (photos, documents) when you hand over the goods to the buyer. Pay attention to the fact that the more flexible and diverse methods of delivery you offer your Buyers, the more attractive you will be to them as the Seller.',
    disabled: false,
  },
];

const checkSx = {
  fontFamily: 'Montserrat',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#212121',
  padding: '9px',
};

const CustomizedAccordion = {
  '& .MuiButtonBase-root': {
    '&.Mui-expanded': {
      transform: 'none',
    },
    padding: '0px !important',
    margin: '0px !important',
    minHeight: '0px !important',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: '15px',
      '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#212121',
      },
    },
  },
  '&.MuiPaper-root::before': {
    backgroundColor: 'transparent',
  },
};

export const DeliveryForm = ({
  product,
  currenciesList,
  fieldArrayIndex,
  register,
  control,
  getValues,
  watch,
  setValue,
  clearErrors,
  setError,
  errors,
  companyId,
  deliveryMethodsData,
  optionError,
}) => {
  const {
    fields: pickupFields,
    append: pickupAppend,
    remove: pickupRemove,
  } = useFieldArray({
    control,
    keyName: 'key',
    name: `options.[${fieldArrayIndex}].pickupIds`,
  });
  const pickupMethods = useForm();

  const [isLocationModal, setIsLocationModal] = useState('');
  const [delivery, setDelivery] = useState({});
  const [locationValue, setLocationValue] = useState({});
  const [selectLocation, setSelectLocation] = useState('');
  const [isNewPickupModalOpen, setIsNewPickupModalOpen] = useState(false);
  const [editPickupData, setEditPickupData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState(currenciesList[0]?.id);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editableFieldIndex, setEditableFieldIndex] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openOptions = Boolean(anchorEl);
  const [createAddressLocation] = useMutation(CREATE_ADDRESS_LOCATION);
  const [createOrUpdatePickups] = useMutation(CREATE_OR_UPDATE_COMPANY_PICKUP_ADDRESSES);
  const [deleteCompanyPickupAddress] = useMutation(DELETE_COMPANY_PICKUP_ADDRESS);
  const [expanded, setExpanded] = useState(0);
  const [pickupAddresses, setPickupAddresses] = useState([]);
  const [handAddress, setHandAddress] = useState('');
  const [deletePickupId, setDeletePickupId] = useState(null);
  const [showName, setShowName] = useState(true);
  const deliveryMethodRef = useRef(null);
  const openNewPickupModal = () => {
    setIsNewPickupModalOpen(true);
  };

  useEffect(() => {
    if (
      (optionError?.options &&
        optionError.options.length &&
        optionError.options[0].pickupIds &&
        !optionError.options[0].pickupIds[0].id.ref) ||
      (errors?.options &&
        errors.options.length &&
        errors.options[fieldArrayIndex] &&
        errors.options[fieldArrayIndex][deliveryMethods[0].name])
    ) {
      deliveryMethodRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [errors, optionError]);

  useEffect(() => {
    let pickups = [];
    let companyHandAddressId = '';
    if (deliveryMethodsData.shopAddresses) {
      pickups = deliveryMethodsData.shopAddresses.reduce((accumulator, currentValue) => {
        if (currentValue.is_pickup) {
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);
      companyHandAddressId = deliveryMethodsData.shopAddresses?.find((item) => !item.is_pickup);
      if (companyHandAddressId) {
        setHandAddress(companyHandAddressId.id);
      }
      setPickupAddresses(pickups);
    }
    if (product.id) {
      let productDeliveryData = {
        delivery_digital: !!product.productPrices[fieldArrayIndex].delivery_digital,
        delivery_hand: !!product.productPrices[fieldArrayIndex].delivery_hand,
        delivery_pickup: !!product.productPrices[fieldArrayIndex].delivery_pickup,
        delivery_shipping: !!product.productPrices[fieldArrayIndex].delivery_shipping,
      };
      setDelivery(productDeliveryData);
      setValue(
        `options.[${fieldArrayIndex}].delivery_digital`,
        productDeliveryData.delivery_digital
      );
      setValue(`options.[${fieldArrayIndex}].delivery_hand`, productDeliveryData.delivery_hand);
      setValue(`options.[${fieldArrayIndex}].delivery_pickup`, productDeliveryData.delivery_pickup);
      setValue(
        `options.[${fieldArrayIndex}].delivery_shipping`,
        productDeliveryData.delivery_shipping
      );
      if (
        product.productPrices[fieldArrayIndex].shopAddress &&
        product.productPrices[fieldArrayIndex].shopAddress.length
      ) {
        let pickupIds = product.productPrices[fieldArrayIndex].shopAddress.reduce(
          (accumulator, currentValue) => {
            if (currentValue.is_pickup) {
              accumulator.push({
                id: currentValue.id,
                is_auto_order: currentValue?.pivot?.is_auto_order,
              });
            }
            return accumulator;
          },
          []
        );

        pickupIds.forEach((item) => {
          const index = pickups.findIndex((el) => item.id === el.id);

          if (index !== -1) {
            const fieldPath = `options.[${fieldArrayIndex}].pickupIds.[${index}].id`;
            setValue(`options.[${fieldArrayIndex}].pickupIds.[${index}].id`, item.id);
            setValue(
              `options.[${fieldArrayIndex}].pickupIds.[${index}].is_auto_order`,
              item.is_auto_order
            );
          }
        });
      }

      let handAddressId = product.productPrices[fieldArrayIndex].shopAddress?.find(
        (item) => !item.is_pickup
      );

      if (handAddressId && handAddressId.id && companyHandAddressId.id) {
        setValue(`options.[${fieldArrayIndex}].handAddressId`, {
          id: handAddressId.id,
          is_auto_order: handAddressId.pivot?.is_auto_order,
        });
      }
    } else {
      if (Object.keys(deliveryMethodsData).length) {
        let deliveryData = {
          delivery_digital: deliveryMethodsData.delivery_digital,
          delivery_hand: deliveryMethodsData.delivery_hand,
          delivery_pickup: deliveryMethodsData.delivery_pickup,
          delivery_shipping: deliveryMethodsData.delivery_shipping,
        };
        setDelivery(deliveryData);
        setValue(`options.[${fieldArrayIndex}].delivery_digital`, deliveryData.delivery_digital);
        setValue(`options.[${fieldArrayIndex}].delivery_hand`, deliveryData.delivery_hand);
        setValue(`options.[${fieldArrayIndex}].delivery_pickup`, deliveryData.delivery_pickup);
        setValue(`options.[${fieldArrayIndex}].delivery_shipping`, deliveryData.delivery_shipping);

        if (companyHandAddressId && companyHandAddressId.id) {
          setValue(`options.[${fieldArrayIndex}].handAddressId`, {
            id: companyHandAddressId.id,
            is_auto_order: false,
          });
        } else {
          setValue(`options.[${fieldArrayIndex}].handAddressId`, false);
        }
        setValue(`options.[${fieldArrayIndex}].pickupIds`, []);
      }
    }
  }, [deliveryMethodsData]);

  const handleApplyLocation = async (value) => {
    if (isLocationModal === 'locationPickup') {
      setLocationValue(value);
      setSelectLocation(value.address);
    }
    if (isLocationModal === 'handAddressId') {
      setValue('handAddressId.address', value.address);
      const getLocationId = await createAddressLocation({
        variables: {
          input: {
            address: value.address,
            data: {
              latLng: {
                lat: value.coordinates.lat.toString(),
                lng: value.coordinates.lng.toString(),
              },
              country: value.locationData.country || '',
              country_code: value.locationData.country_code || '',
              locality: value.locationData.locality || '',
              postcode: value.locationData.postcode || '',
            },
          },
        },
      });
      let locationId = '';
      if (getLocationId.data && getLocationId.data.createAddressLocation) {
        locationId = getLocationId.data.createAddressLocation.id;
        setValue('handAddressId.address_location_id', locationId);
      }
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // ToDo make helper function
  function findOrderedDays(days) {
    days = [...days];
    days = days.sort((a, b) => a.id - b.id);
    const orderedDays = [];
    let startDay = days[0];
    let endDay = days[0];

    for (let i = 1; i < days.length; i++) {
      const currentDay = days[i];
      const previousDay = days[i - 1];

      if (currentDay.id === previousDay.id + 1) {
        endDay = currentDay;
      } else {
        if (startDay === endDay) {
          orderedDays.push(startDay.name);
        } else if (startDay.id + 1 !== endDay.id) {
          orderedDays.push(`${startDay.name}-${endDay.name}`);
        } else {
          orderedDays.push(startDay.name);
          orderedDays.push(endDay.name);
        }
        startDay = currentDay;
        endDay = currentDay;
      }
    }

    if (startDay === endDay) {
      orderedDays.push(startDay.name);
    } else {
      orderedDays.push(`${startDay.name}-${endDay.name}`);
    }

    return `${orderedDays.join(', ')} `;
  }

  const openCloseEpanded = (index) => {
    if (pickupAddresses.length > 0) {
      if (index === expanded) {
        setShowName(false);
        setTimeout(() => {
          setExpanded(null);
        }, 100);
      } else {
        setShowName(true);
        setExpanded(index);
      }
    }
  };

  const editPickupMobile = (e, pickup, index) => {
    e.stopPropagation();
    setEditPickupData(pickup);
    setIsEditModal(true);
    setEditableFieldIndex(index);
    openNewPickupModal();
    setExpanded(expanded);
    setAnchorEl(!anchorEl);
  };

  const deletePickupMobile = (e, index) => {
    e.stopPropagation();
    pickupRemove(index);
    setExpanded(expanded);
    setAnchorEl(!anchorEl);
  };

  const pickupEdit = async (data) => {
    await createOrUpdatePickup(data, true);
  };

  const createOrUpdatePickup = async (data, isEdit = false) => {
    const contactInfo = {};
    if (data.contact_person) contactInfo.contact_person = data.contact_person;
    if (data.email) contactInfo.email = data.email;
    if (data.tel) contactInfo.tel = data.tel;
    const schedules = data.schedules.map((schedule) => {
      let scheduleItem = {
        from: moment(dayjs.utc(schedule.from).tz(dayjs.tz.guess()).$d).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        to: moment(dayjs.utc(schedule.to).tz(dayjs.tz.guess()).$d).format('YYYY-MM-DD HH:mm:ss'),
        week_days: schedule.weekDays.map((day) => day.name),
      };
      if (schedule.id) {
        scheduleItem.id = schedule.id;
      }
      return scheduleItem;
    });
    let pickupAddressData = {
      address_location_id: data.address_location_id,
      delivery_pickup_price: +data.delivery_pickup_price || null,
      currency_id: data.currency_id || null,
      pickup_name: data.name,
      district: data.district || null,
      deadline_days: +data.deadline_days || null,
      availability: +data.availability || null,
      info: data.info || null,
      contact_info: Object.keys(contactInfo) ? contactInfo : null,
      schedules: schedules,
    };
    const res = await createOrUpdatePickups({
      variables: {
        input: {
          company_id: companyId,
          pickupAddress: pickupAddressData,
        },
      },
    });

    if (res.data && res.data.createOrUpdateCompanyPickupAddress && !isEdit) {
      setPickupAddresses((current) => [...current, res.data.createOrUpdateCompanyPickupAddress]);
    }

    if (res.data && res.data.createOrUpdateCompanyPickupAddress && isEdit) {
      const updatedPickupAddresses = pickupAddresses.map((item, index) => {
        if (index === editableFieldIndex) {
          return res.data.createOrUpdateCompanyPickupAddress;
        }
        return item;
      });
      setPickupAddresses(updatedPickupAddresses);
    }
  };

  const StyledMenu = styled((props) => (
    <Menu
      id="pickup-options-menu"
      anchorEl={anchorEl}
      open={openOptions}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));
  const removePickupAddress = async () => {
    const res = await deleteCompanyPickupAddress({ variables: { id: deletePickupId.id } });
    if (res.data && res.data.deletePickupAddress) {
      setDeletePickupId(null);
      pickupRemove(deletePickupId.index);
      const filteredList = pickupAddresses.filter((item) => item.id !== deletePickupId.id);
      setPickupAddresses(filteredList);
    }
  };

  return (
    <>
      <div className={styles.deliveryDiv} ref={deliveryMethodRef}>
        <div className={styles.deliveryText}>Delivery methods</div>
        <div style={{ display: 'flex' }}>
          <div
            className={styles.deliveryColumnLeft}
            style={delivery.delivery_pickup ? { gap: 28 } : { gap: 12 }}
          >
            {delivery.delivery_pickup && (
              <Button
                type="default"
                className={styles.addPickupButton}
                onClick={() => {
                  openNewPickupModal();
                  setIsEditModal(false);
                }}
              >
                <Image src="/images/company/plus.svg" />
                <Typography className={styles.btnText}>Add</Typography>
              </Button>
            )}
            {deliveryMethods.map((item, deliveryIndex) => (
              <>
                <Controller
                  control={control}
                  key={deliveryIndex}
                  {...register(`options.[${fieldArrayIndex}].${item.name}`, {
                    validate: (value, formValues) => {
                      if (
                        !(
                          !formValues.options[fieldArrayIndex].delivery_digital &&
                          !formValues.options[fieldArrayIndex].delivery_hand &&
                          !formValues.options[fieldArrayIndex].delivery_pickup &&
                          !formValues.options[fieldArrayIndex].delivery_shipping
                        )
                      ) {
                        clearErrors(`options.[${fieldArrayIndex}][${deliveryMethods[0].name}]`);
                        return true;
                      }
                      setError(`options.[${fieldArrayIndex}][${deliveryMethods[0].name}]`, {
                        type: 'validate',
                        message: '',
                      });
                      return false;
                    },
                  })}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <FormControlLabel
                          className={styles.formControl}
                          control={
                            <Checkbox
                              disabled={item.disabled}
                              onChange={(e) => {
                                onChange(e);
                                if (!e.target.checked && item.name === 'delivery_shipping') {
                                  setValue('delivery.is_post_worldwide', false);
                                }
                                if (!e.target.checked && item.name === 'delivery_hand') {
                                  setValue(
                                    `options.[${fieldArrayIndex}].handAddressId.is_auto_order`,
                                    e.target.checked
                                  );
                                  setValue(
                                    `options.[${fieldArrayIndex}].handAddressId`,
                                    e.target.checked
                                  );
                                }
                                if (e.target.checked && item.name === 'delivery_hand') {
                                  setValue(`options.[${fieldArrayIndex}].handAddressId`, {
                                    id: handAddress,
                                    is_auto_order: false,
                                  });
                                }
                                setDelivery((current) => ({
                                  ...current,
                                  [item.name]: e.target.checked,
                                }));
                              }}
                              checkedIcon={<CheckBoxOutlinedIcon sx={{ fill: '#212121' }} />}
                              checked={item.disabled ? false : value}
                              sx={checkSx}
                            />
                          }
                          label={
                            <Box className={styles.rowItem}>
                              <Box className={styles.checkboxItems}>
                                <Typography
                                  className={[
                                    styles.checkBoxLabel,
                                    item.disabled ? styles.disabledLabel : '',
                                  ]}
                                >
                                  {item.label}
                                </Typography>
                                {!!item.helpMessage && (
                                  <HelpTooltip tooltipText={item.helpMessage} />
                                )}
                              </Box>
                            </Box>
                          }
                        />
                        {item.name === 'delivery_hand' && (
                          <Controller
                            {...register(
                              `options.[${fieldArrayIndex}].handAddressId.is_auto_order`
                            )}
                            control={control}
                            defaultValue={false}
                            render={({ field: { onChange, value } }) => (
                              <FormGroup>
                                <FormControlLabel
                                  disabled={!watch(`options.[${fieldArrayIndex}].handAddressId`)}
                                  sx={
                                    !watch(`options.[${fieldArrayIndex}].handAddressId`) && {
                                      opacity: 0.7,
                                    }
                                  }
                                  control={
                                    <Checkbox
                                      key={value}
                                      onChange={(e) => {
                                        onChange(e);

                                        setExpanded(expanded);
                                      }}
                                      checked={value}
                                      icon={
                                        <CheckBoxOutlineBlankIcon style={{ color: '#143471' }} />
                                      }
                                      checkedIcon={
                                        <CheckBoxOutlinedIcon style={{ color: '#143471' }} />
                                      }
                                    />
                                  }
                                  label={
                                    <div className={styles.autoOrderCheckbox}>
                                      <Typography className={styles.autoOrderText}>
                                        Auto order option
                                      </Typography>
                                      <HelpTooltip tooltipText="You can offer your customers the convenience of setting up recurring orders for products they purchase frequently." />
                                    </div>
                                  }
                                />
                              </FormGroup>
                            )}
                          />
                        )}
                      </Box>
                      {delivery[item.name] &&
                        item.name === 'delivery_pickup' &&
                        pickupAddresses.length > 0 &&
                        pickupAddresses.map((pickup, index) => {
                          return (
                            <Accordion
                              sx={CustomizedAccordion}
                              key={pickup.key}
                              className={styles.accordionContainer}
                              expanded={expanded === index}
                              onChange={() => openCloseEpanded(index)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`$Pickup name (${pickup.name})`}
                                id={`pickup${pickup.locationId}`}
                              >
                                <Box className={styles.platformPickup}>
                                  <Box className={styles.pickupTitle}>
                                    <Controller
                                      {...register(
                                        `options.[${fieldArrayIndex}].pickupIds.[${index}].id`
                                      )}
                                      control={control}
                                      render={({ field: { onChange, value } }) => (
                                        <Checkbox
                                          key={value}
                                          onChange={(e) => {
                                            e.target.checked
                                              ? onChange(pickup.id)
                                              : onChange(e.target.checked);

                                            setExpanded(expanded);
                                          }}
                                          checked={value}
                                          checkedIcon={
                                            <CheckBoxOutlinedIcon style={{ color: '#212121' }} />
                                          }
                                        />
                                      )}
                                    />
                                    <Image
                                      src="/images/market-place/pickup.svg"
                                      width={19.5}
                                      className={styles.pickupIcon}
                                    />
                                    <Typography className={styles.title}>
                                      {pickup.name} {pickup.district && `(${pickup.district})`}
                                    </Typography>
                                    <Box
                                      className={styles.titleAutoOrder}
                                      sx={
                                        expanded === index
                                          ? { display: 'none' }
                                          : { display: 'block' }
                                      }
                                    >
                                      <Controller
                                        {...register(
                                          `options.[${fieldArrayIndex}].pickupIds.[${index}].is_auto_order`
                                        )}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                          <FormGroup>
                                            <FormControlLabel
                                              disabled={
                                                !watch(
                                                  `options.[${fieldArrayIndex}].pickupIds.[${index}].id`
                                                )
                                              }
                                              sx={
                                                !watch(
                                                  `options.[${fieldArrayIndex}].pickupIds.[${index}].id`
                                                ) && {
                                                  opacity: 0.7,
                                                }
                                              }
                                              control={
                                                <Checkbox
                                                  key={value}
                                                  onChange={(e) => {
                                                    onChange(e);
                                                    setExpanded(expanded);
                                                  }}
                                                  checked={value}
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon
                                                      style={{ color: '#143471' }}
                                                    />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxOutlinedIcon
                                                      style={{ color: '#143471' }}
                                                    />
                                                  }
                                                />
                                              }
                                              label={
                                                <div className={styles.autoOrderCheckbox}>
                                                  <Typography className={styles.autoOrderText}>
                                                    Auto order option
                                                  </Typography>
                                                  <HelpTooltip tooltipText="You can offer your customers the convenience of setting up recurring orders for products they purchase frequently." />
                                                </div>
                                              }
                                            />
                                          </FormGroup>
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                  <Hidden mdDown>
                                    <Box
                                      className={styles.pickupActions}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      {/*<RemoveRedEyeOutlinedIcon />*/}
                                      <Image
                                        src="/images/delete-icon-black.svg"
                                        height={18}
                                        width={16}
                                        onClick={() =>
                                          setDeletePickupId({ index: index, id: pickup.id })
                                        }
                                      />
                                      <EditIcon
                                        onClick={() => {
                                          setEditPickupData(pickup);
                                          setIsEditModal(true);
                                          setEditableFieldIndex(index);
                                          openNewPickupModal();
                                        }}
                                      />
                                    </Box>
                                  </Hidden>
                                  <Hidden mdUp>
                                    <Box className={styles.optionsIcon} onClick={handleClick}>
                                      <MoreVertIcon />
                                    </Box>
                                    <StyledMenu>
                                      <MenuItem
                                        className={styles.menuItemWrapper}
                                        onClick={(e) => {
                                          deletePickupMobile(e, index);
                                        }}
                                      >
                                        <Image
                                          src="/images/delete-icon-black.svg"
                                          height={18}
                                          width={16}
                                        />
                                        <Typography>Delete</Typography>
                                      </MenuItem>
                                      <MenuItem
                                        className={styles.menuItemWrapper}
                                        onClick={(e) => {
                                          editPickupMobile(e, pickup, index);
                                        }}
                                      >
                                        <EditIcon />
                                        <Typography>Edit</Typography>
                                      </MenuItem>
                                    </StyledMenu>
                                  </Hidden>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails sx={{ padding: '0px !important' }}>
                                <Box className={styles.pickupInfo}>
                                  <Box className={styles.infoItem}>
                                    {pickup?.addressLocation?.address && (
                                      <Box className={styles.info}>
                                        <Image
                                          src="/images/market-place/location.svg"
                                          width={19.5}
                                        />
                                        <Typography className={styles.title}>
                                          {pickup.addressLocation.address}
                                        </Typography>
                                      </Box>
                                    )}
                                    {pickup.schedules.map(
                                      (schedule) =>
                                        (schedule.weekDays.length > 0 ||
                                          schedule.from ||
                                          schedule.to) && (
                                          <Box className={styles.info} key={schedule.key}>
                                            <Image
                                              src="/images/market-place/clock-icon.svg"
                                              width={19.5}
                                            />
                                            <Typography className={styles.title}>
                                              {!!schedule.weekDays.length &&
                                                findOrderedDays(schedule.weekDays)}
                                              {!!schedule.from &&
                                                !!schedule.to &&
                                                `${moment(dayjs.utc(schedule.from).tz(dayjs.tz.guess()).$d).format('HH:mm')}
                                                 - 
                                                ${moment(dayjs.utc(schedule.to).tz(dayjs.tz.guess()).$d).format('HH:mm')}`}
                                            </Typography>
                                          </Box>
                                        )
                                    )}
                                    {(pickup.contact_person || pickup.tel || pickup.email) && (
                                      <Box className={styles.info}>
                                        <Image
                                          src="/images/market-place/person-icon.svg"
                                          width={19.5}
                                        />
                                        <Box className={styles.contact}>
                                          {pickup.contact_person && (
                                            <Typography>{pickup.contact_person}</Typography>
                                          )}
                                          {pickup.tel && <Typography>{pickup.tel}</Typography>}
                                          {pickup.email && <Typography>{pickup.email}</Typography>}
                                        </Box>
                                      </Box>
                                    )}
                                    {pickup.shippingCost?.value && (
                                      <Box className={styles.info}>
                                        <Image
                                          src="/images/market-place/many-icon.svg"
                                          width={19.5}
                                        />
                                        <Typography className={styles.title}>
                                          {pickup.shippingCost?.value}&nbsp;
                                          {
                                            currenciesList.find(
                                              (currency) => currency.id === selectedCurrency
                                            )?.symbol
                                          }
                                        </Typography>
                                      </Box>
                                    )}
                                    {pickup.delivery_pickup_price && (
                                      <Box className={styles.info}>
                                        <Image
                                          src="/images/market-place/many-icon.svg"
                                          width={19.5}
                                        />
                                        <Typography className={styles.title}>
                                          {pickup.delivery_pickup_price}&nbsp;
                                          {
                                            currenciesList.find(
                                              (currency) => currency.id === selectedCurrency
                                            )?.symbol
                                          }
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  {showName && (
                                    <Typography className={styles.description}>
                                      {pickup.info}
                                    </Typography>
                                  )}
                                  <Box>
                                    <Box
                                      className={styles.bodyAutoOrder}
                                      sx={
                                        expanded !== index
                                          ? { display: 'none' }
                                          : { display: 'block' }
                                      }
                                    >
                                      <Controller
                                        {...register(
                                          `options.[${fieldArrayIndex}].pickupIds.[${index}].is_auto_order`
                                        )}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                          <FormGroup>
                                            <FormControlLabel
                                              disabled={
                                                !watch(
                                                  `options.[${fieldArrayIndex}].pickupIds.[${index}].id`
                                                )
                                              }
                                              sx={
                                                !watch(
                                                  `options.[${fieldArrayIndex}].pickupIds.[${index}].id`
                                                ) && {
                                                  opacity: 0.7,
                                                }
                                              }
                                              control={
                                                <Checkbox
                                                  key={value}
                                                  onChange={onChange}
                                                  checked={value}
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon
                                                      style={{ color: '#143471' }}
                                                    />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxOutlinedIcon
                                                      style={{ color: '#143471' }}
                                                    />
                                                  }
                                                />
                                              }
                                              label={
                                                <div className={styles.autoOrderCheckbox}>
                                                  <Typography className={styles.autoOrderText}>
                                                    Auto order option
                                                  </Typography>
                                                  <HelpTooltip tooltipText="You can offer your customers the convenience of setting up recurring orders for products they purchase frequently." />
                                                </div>
                                              }
                                            />
                                          </FormGroup>
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                    </>
                  )}
                />
              </>
            ))}
            {((optionError?.options &&
              optionError.options.length &&
              optionError.options[0].pickupIds &&
              !optionError.options[0].pickupIds[0].id.ref) ||
              (errors?.options &&
                errors.options.length &&
                errors.options[fieldArrayIndex] &&
                errors.options[fieldArrayIndex][deliveryMethods[0].name])) && (
              <Typography className={styles.errorText}>
                At least one Delivery method must be selected.
              </Typography>
            )}
          </div>
        </div>
      </div>
      <LocationModal
        title="Choose your location"
        isModelOpen={isLocationModal}
        handleClose={() => setIsLocationModal('')}
        handleApply={handleApplyLocation}
      />
      <DeleteItemModal
        title="Do you want to delete this item?"
        open={deletePickupId}
        handleClose={() => setDeletePickupId(null)}
        deleteItem={removePickupAddress}
      />
      <FormProvider {...pickupMethods}>
        <AddNewPickupModal
          editData={editPickupData}
          currenciesList={currenciesList}
          open={isNewPickupModalOpen}
          pickupAppend={createOrUpdatePickup}
          pickupEdit={pickupEdit}
          onClose={() => setIsNewPickupModalOpen(false)}
          methods={pickupMethods}
          isEditModal={isEditModal}
          setIsEditModal={setIsEditModal}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
      </FormProvider>
    </>
  );
};
