import { MenuItem } from '@mui/material';
import Image from '../../../../components/Image';
import clsx from 'clsx';
import styles from './CompanyInfoMenuItem.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CompanyInfoMenuItem = ({ icon, text, onClick, isDelete, IconComponent }) => {
  const url = window.location.href;

  return (
    <MenuItem className={styles.menuItemWrapper} onClick={onClick}>
      {text === 'Send' ? (
        <CopyToClipboard text={url}>
          <button type="button" className={styles.shareBtn}>
            {IconComponent && <IconComponent style={{ width: 18, height: 18, marginRight: 8 }} />}
            {icon && (
              <div className={styles.iconWrapper}>
                <Image src={icon} width={20} height={20} />
              </div>
            )}
            <div className={styles.text}>{text}</div>
          </button>
        </CopyToClipboard>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {IconComponent && (
            <IconComponent
              className={isDelete && styles.delete}
              style={{ width: 18, height: 18, marginRight: 8 }}
            />
          )}
          {icon && (
            <div className={styles.iconWrapper}>
              <Image src={icon} width={20} height={20} />
            </div>
          )}
          <div className={clsx(styles.text, isDelete ? styles.deleteText : '')}>{text}</div>
        </div>
      )}
    </MenuItem>
  );
};

export default CompanyInfoMenuItem;
