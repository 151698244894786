import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-lib/core';

const httpLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      user: process.env.REACT_APP_SSO_GRAPHQL_ENDPOINT,
      marketPlace: process.env.REACT_APP_MARKET_GRAPHQL_ENDPOINT,
    },
    httpSuffix: '',
    createHttpLink: () => createHttpLink(),
  }),
]);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations.toString()}, Path: ${path}`
      );
    });
  if (networkError) {
    if (networkError.response?.status === 401) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.remove('access_token', { domain: domainValue });
      Cookies.remove('userId', { domain: domainValue });
      Cookies.remove('calculatorId', { domain: domainValue });
      Cookies.remove('companyId', { domain: domainValue });
      Cookies.remove('marketId', { domain: domainValue });
      localStorage.removeItem('currentCompanyData');

      window.location.reload();
    }
    openNotification({
      type: 'error',
      message: 'Server Error',
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const token = Cookies.get('access_token', { domain: domainValue });
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link: from([errorLink, authLink.concat(httpLink)]),
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  cache: new InMemoryCache(),
  connectToDevTools: false,
});

export default client;
