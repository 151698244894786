import { Layout } from '../../layout';
import { MapHead } from './mapHead';

export const MapPage = () => {
  return (
    <div
      style={{
        width: '1020px',
      }}
    >
      <MapHead />
    </div>
  );
};
