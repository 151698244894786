import React, { useState } from 'react';
import styles from './ReviewsTab.module.scss';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Image from '../../../../components/Image';

const TABS = [
  {
    label: 'All reviews',
  },
  {
    label: 'Positive',
  },
  {
    label: 'Negative',
  },
];

const REVIEWS = [
  {
    star: '5',
    procent: '80%',
  },
  {
    star: '4',
    procent: '10%',
  },
  {
    star: '3',
    procent: '8%',
  },
  {
    star: '2',
    procent: '2%',
  },
  {
    star: '1',
    procent: '0%',
  },
];

const ReviewsTab = () => {
  const [selectedTab, setSelectedTab] = useState('All reviews');

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChangeTabSelect = (event) => {
    handleChangeTab(event, event.target.value);
  };
  return (
    <Box className={styles.customerReviewContainer}>
      <Box className={styles.tabsContainer}>
        <Hidden mdDown>
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
            value={selectedTab}
            onChange={handleChangeTab}
            className={styles.tabs}
            sx={{ '.MuiTabs-flexContainer': { justifyContent: 'flex-start' } }}
          >
            {TABS.map((item) => (
              <Tab
                key={item.label}
                value={item.label}
                label={`${item.label}`}
                className={[styles.tabItem, selectedTab === item.label ? styles.activeItem : null]}
              />
            ))}
          </Tabs>
        </Hidden>
        <Hidden mdUp>
          <Select
            onChange={handleChangeTabSelect}
            value={selectedTab}
            size="medium"
            className={styles.topSelect}
            sx={{
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '.MuiSelect-select': { padding: 0 },
            }}
          >
            {TABS.map((item) => (
              <MenuItem value={item.label} key={item.label}>
                <span className={styles.topMenuItem}>{item.label}</span>
              </MenuItem>
            ))}
          </Select>
        </Hidden>
        <Box className={styles.reviewContainer}>
          <Box>
            <Box className={styles.review}>
              <Box className={styles.stars}>
                <Image src="/images/market-place/review-star.svg" width={24} />
                <Image src="/images/market-place/review-star.svg" width={24} />
                <Image src="/images/market-place/review-star.svg" width={24} />
                <Image src="/images/market-place/review-star.svg" width={24} />
                <Image src="/images/market-place/review-star-empty.svg" width={24} />
              </Box>
              <Typography className={styles.reviewInfo}>4.5 out of 5</Typography>
            </Box>
            <Typography className={styles.reviewCount}>115 reviews</Typography>
            <Box className={styles.reviewProcentContainer}>
              {REVIEWS.map((item) => (
                <Box key={item.star} className={styles.reviewProcent}>
                  <Typography className={styles.reviewText}>{item.star} star</Typography>
                  <Box className={styles.procentBlock}>
                    <Box className={styles.procent} style={{ width: item.procent }} />
                  </Box>
                  <Typography className={styles.reviewText}>{item.procent}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <ButtonBase type="button" className={styles.buttonOutline}>
            <Image src="/images/market-place/add.svg" width={16} />
            <Typography className={styles.buttonOutlineText}>Add review</Typography>
          </ButtonBase>
        </Box>
        <Box className={styles.commentsContainer}>
          <Box className={styles.commentBlock}>
            <Box className={styles.commentContent}>
              <Box className={styles.commentInfo}>
                <Box className={styles.commentCustomer}>
                  <Avatar
                    src="/images/templates/user-avatar-background.png"
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography className={styles.customerName}>Name</Typography>
                </Box>
                <Typography className={styles.commentDate}>12.09.2022</Typography>
                <Box className={styles.purchaseStatusBlock}>
                  <Image src="/images/market-place/purchase-confirmed.svg" width={13.33} />
                  <Typography className={styles.purchaseStatus}>Purchase confirmed</Typography>
                </Box>
              </Box>
              <Box className={styles.comment}>
                <Box className={styles.review}>
                  <Box className={styles.stars}>
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star-empty.svg" width={20} />
                  </Box>
                  <Typography className={styles.productName}>Face creem 60 ml</Typography>
                </Box>
                <Box className={styles.commentImages}>
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                  <Image
                    className={styles.image}
                    src="/images/templates/product-image-example.png"
                  />
                </Box>
                <Typography className={styles.description}>
                  I bought spirulina because only a deaf person did not hear about its benefits. And
                  masks for the face and hair and for general health. Super.
                </Typography>
              </Box>
            </Box>
            <Box className={styles.commentAnswers}>
              <Box className={styles.commentAnswer}>
                <Box className={styles.companyInfo}>
                  <Avatar
                    src="/images/templates/avatar-example.png"
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography className={styles.companyName}>Unilever</Typography>
                </Box>
                <Typography className={styles.answerText}>Thank you for your comment.</Typography>
              </Box>
            </Box>
            <Box className={styles.commentLike}>
              <Box className={styles.like}>
                <Image src="/images/market-place/like.svg" width={15.62} />
                <Typography className={styles.count}>(2)</Typography>
              </Box>
              <Box className={styles.dislike}>
                <Image src="/images/market-place/dislike.svg" width={15.62} />
                <Typography className={styles.count}>(20)</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.commentBlock}>
            <Box className={styles.commentContent}>
              <Box className={styles.commentInfo}>
                <Box className={styles.commentCustomer}>
                  <Avatar
                    src="/images/templates/user-avatar-background.png"
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography className={styles.customerName}>Name</Typography>
                </Box>
                <Typography className={styles.commentDate}>12.09.2022</Typography>
                <Box className={styles.purchaseStatusBlock}>
                  <Image src="/images/market-place/purchase-confirmed.svg" width={13.33} />
                  <Typography className={styles.purchaseStatus}>Purchase confirmed</Typography>
                </Box>
              </Box>
              <Box className={styles.comment}>
                <Box className={styles.review}>
                  <Box className={styles.stars}>
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                    <Image src="/images/market-place/review-star.svg" width={20} />
                  </Box>
                  <Typography className={styles.productName}>Face creem 60 ml</Typography>
                </Box>
                <Typography className={styles.description}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor
                </Typography>
              </Box>
            </Box>
            <Box className={styles.commentLike}>
              <Box className={styles.like}>
                <Image src="/images/market-place/like.svg" width={15.62} />
                <Typography className={styles.count}>(2)</Typography>
              </Box>
              <Box className={styles.dislike}>
                <Image src="/images/market-place/dislike.svg" width={15.62} />
                <Typography className={styles.count}>(20)</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewsTab;
