import React, { useState, useContext } from 'react';
import userContext from '../../context/User/userContext';
import { SignInUpModalWrapper } from '../Modals/SignInUpModal/SignInUpModal';
import { MobileNavbar } from '../../modules/tt-mobile-navmenu';
// import 'tt-mobile-navmenu/dist/index.css';
import { InviteModal } from '../../modules/tt-modals';
import { GET_ROLE, SEND_INVITE } from '../../api';
import { useNavigate } from 'react-router-dom';

export const MobileNavbarWrapper = () => {
  const { user } = useContext(userContext);
  const [isInviteModal, setInviteModal] = useState(false);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);
  const navigate = useNavigate();

  const onCloseInviteModal = () => setInviteModal(false);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };

  return (
    <>
      <MobileNavbar
        user={user}
        setAnchorLoginEl={setAnchorLoginEl}
        createAnInvitation={() => setInviteModal(true)}
        openCalculator={() => navigate('/Calculators')}
        openCalendar={() => {
          console.log('Calendar');
        }}
        openBookmark={() => {
          console.log('Bookmark');
        }}
        redirectDomain={process.env.REACT_APP_TT || ''}
        homeRedirectUrl="/"
        digitalAssetsTTUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
        qaTTUrl={process.env.REACT_APP_QA}
      />
      <SignInUpModalWrapper anchorEl={anchorLoginEl} open={open} closeModal={handleLoginClose} />
      {user?.id && (
        <InviteModal
          visible={isInviteModal}
          title="Create an invitation"
          onClose={onCloseInviteModal}
          roleQuery={GET_ROLE}
          sendInviteQuery={SEND_INVITE}
        />
      )}
    </>
  );
};
