import AppState from './App/AppState';
import AppContext from './App/appContext';
import userContext from './User/userContext';
import MainContextProvider from './MainContextProvider';

export const allContexts = {
  app: AppContext,
  user: userContext,
};

export { AppState, AppContext, MainContextProvider };
