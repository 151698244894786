import React, { useState } from 'react';
import Image from '../../../components/Image';
import styles from './CompanyInfoSdgsItem.module.scss';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { Box, Typography } from '@mui/material';

const CompanyInfoSdgsItem = ({ data, onClick = () => {} }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Box className={styles.itemWrapper} onClick={() => onClick()}>
      {data.map(
        (item, index) =>
          (showMore || index < 4) && (
            <Tooltip
              key={item.name}
              id={item.name}
              title={
                <Typography className={styles.tooltipText}>
                  {item.name.replace('_', ' ')}
                </Typography>
              }
              placement="top"
              arrow
            >
              <Box className={styles.itemContainer}>
                <Image
                  src={`/images/company/${item.name}.svg`}
                  height="100%"
                  width="100%"
                  className={styles.sdgImage}
                />
              </Box>
            </Tooltip>
          )
      )}
      {data.length - 4 > 0 && !showMore ? (
        <button type="button" onClick={() => setShowMore(true)} className={styles.moreData}>
          +{data.length - 4}
        </button>
      ) : data.length - 4 > 0 && showMore ? (
        <button type="button" onClick={() => setShowMore(false)} className={styles.moreData}>
          <RemoveIcon />
        </button>
      ) : null}
    </Box>
  );
};

export default CompanyInfoSdgsItem;
