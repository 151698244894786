import React, { useState, useEffect } from 'react';
import styles from './AppAvatar.module.scss';

export const AvatarBadge = ({ role, size }) => {
  const [currentColor, setCurrentColor] = useState('');
  const [transformedRole, setTransformedRole] = useState('');

  const short = () => {
    if (role === 'expert') {
      setCurrentColor('#D15F1F');
      return 'exp';
    }
    if (role === 'investor') {
      setCurrentColor('#3C7097');
      return 'inv';
    }
    if (role === 'business') {
      setCurrentColor('#2D8342');
      return 'bsn';
    }
    if (role === 'individual') {
      setCurrentColor('#D8913C');
      return 'ind';
    }
    return '';
  };

  useEffect(() => {
    const convertedRole = short();
    setTransformedRole(convertedRole);
  }, [role]);

  return (
    <div
      style={{
        right: `${
          size === 'sm' ? '10.5px' : size === 'md' ? '13.5px' : size === 'xxs' ? '2px' : '5.5px'
        }`,
        color: `${currentColor}`,
        fontSize: size === 'xxs' ? 8 : 10,
        lineHeight: size === 'xxs' ? '9px' : '12px',
        padding: size === 'xxs' ? 2 : '3px 4px',
      }}
      className={styles.avatarBadge}
    >
      <span>{transformedRole}</span>
    </div>
  );
};
