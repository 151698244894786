import styles from './BillsAndInvoicesTab.module.scss';
import React from 'react';
import Image from '../../../../../../components/Image';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const rows = [
  { id: 1, name: 'Frozen yoghurt', date: '01.02.2023', size: '10 KB' },
  { id: 2, name: 'Ice cream sandwich', date: '01.02.2023', size: '10 KB' },
  { id: 3, name: 'Eclair', date: '01.02.2023', size: '10 KB' },
  { id: 4, name: 'Cupcake', date: '01.02.2023', size: '10 KB' },
  { id: 5, name: 'Gingerbread', date: '01.02.2023', size: '10 KB' },
];

const BillsAndInvoicesTab = () => {
  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: 32, boxShadow: 'none' }}
      sx={{ width: '100%' }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 'max-content' }}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableTitle}>#</TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Item Name
            </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Date
            </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Size
            </TableCell>
            <TableCell className={styles.tableTitle} align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={styles.tableTitle} component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell className={styles.tableTitle} align="left">
                {row.name}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.date}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.size}
              </TableCell>
              <TableCell align="right">
                <div className={styles.tableCellActions}>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/download.svg'} width={13.33} height={13.33} />
                  </button>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/delete.svg'} width={13.33} height={15} />
                  </button>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/share.svg'} width={16.25} height={13.71} />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BillsAndInvoicesTab;
