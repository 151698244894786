import React from 'react';

import styles from './businessPage.module.scss';
import { useFormContext } from 'react-hook-form';
import { HelpTooltip } from 'tt-ui-kit';
import { AccountLogo } from '../../../../account/accountLogo';
import { Account } from '../../../../account';

export const BusinessForm = ({
  setFile,
  companyInfo = null,
  file,
  showGreen = false,
  openForDonation = false,
}) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
    getValues,
    setError,
  } = useFormContext();

  return (
    <>
      <div className={styles.bodyBisnessAccount}>
        <div className={styles.titleBlock}>
          <h2>General information</h2>
          <HelpTooltip
            tooltipText="This is public information"
            iconClassName={styles.helpIconStyle}
          />
        </div>
        <AccountLogo
          setFile={setFile}
          file={file}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          getValues={getValues}
          companyInfo={companyInfo}
        />
        <Account
          control={control}
          setError={setError}
          errors={errors}
          register={register}
          setValue={setValue}
          getValues={getValues}
          companyInfo={companyInfo}
          showGreen={showGreen}
          openForDonation={openForDonation}
        />
      </div>
    </>
  );
};
