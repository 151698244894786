import React, { useEffect, useState, useRef } from 'react';
import { Box, InputBase, Typography, IconButton, ClickAwayListener } from '@mui/material';
import styles from './Filter.module.scss';
import Image from '../../../../components/Image';
import { FilterModal } from './FilterModal';
import { MapIcon, TableViewIcon, GridViewIcon, ArrowAscIcon, CloseIcon } from 'tt-ui-kit';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { GET_MANY_PRODUCT_FILTERS, GET_SERVICE_FILTERS } from '../../../../api';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Loader } from 'tt-ui-kit';

const Filter = ({
  changeFilter,
  setOpenFilterModal,
  openFilterModal,
  setFilterData,
  user,
  activeMode,
  setActiveMode,
  activeTab,
  isCredit = false,
  pageType,
  searchListProducts,
  setSearchListProducts,
  openProduct,
  onUpdate,
  setCurrentPage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getValues, setValue, control, register, reset, watch } = useForm({
    defaultValues: {
      partial_search: '',
      ordersBy: '',
      price_min: '',
      price_max: '',
      country_id: [],
      category_id: [],
      group_id: [],
      sort: '',
      country: {},
      category: {},
      group: {},
      creditGoals: [],
    },
  });
  const methods = useForm();
  const navigate = useNavigate();

  const [getManyProductFilters] = useLazyQuery(GET_MANY_PRODUCT_FILTERS);
  const [getServiceFilters] = useLazyQuery(GET_SERVICE_FILTERS);

  const [countriesList, setCountriesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [productsGroup, setProductsGroup] = useState([]);
  const [goals, setGoals] = useState([]);
  const [filterBadge, setFilterBadge] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchComponentRef = useRef(null);
  const searchComponentRef2 = useRef(null);

  useEffect(() => {
    let badge = false;
    const partial_search = searchParams.get('partial_search');
    const ordersBy = searchParams.get('ordersBy');
    const price_min = searchParams.get('price_min');
    const price_max = searchParams.get('price_max');
    const country_id = searchParams.getAll('country_id');
    const category_id = searchParams.getAll('category_id');
    const group_id = searchParams.getAll('group_id');

    setValue('partial_search', partial_search);
    setValue('price_min', price_min);
    setValue('price_max', price_max);

    if (ordersBy) {
      setValue('ordersBy', [
        {
          column: ordersBy,
          direction: 'DESC',
        },
      ]);
      setValue('sort', ordersBy);
      badge = true;
    }
    if (price_min && !ordersBy) {
      setValue('ordersBy', [
        {
          column: 'price',
          direction: 'ASC',
        },
      ]);
      setValue('sort', 'price');
      badge = true;
    }
    if (country_id && country_id.length) {
      setValue('country_id', country_id);
      let country = {};
      country_id.forEach((item) => {
        country[item] = true;
      });
      setValue('country', country);
      badge = true;
    }
    if (category_id && category_id.length) {
      setValue('category_id', category_id);
      let category = {};
      category_id.forEach((item) => {
        category[item] = true;
      });
      setValue('category', category);
      badge = true;
    }
    if (category_id && category_id.length) {
      setValue('group_id', group_id);
      let group = {};
      group_id.forEach((item) => {
        group[item] = true;
      });
      setValue('group', group);
      badge = true;
    }
    setFilterBadge(badge);

    showFilterData();
  }, [user]);

  useEffect(() => {
    (async () => {
      await getManyFilters();
    })();
  }, [activeTab]);

  useEffect(() => {
    if (searchListProducts) {
      setLoading(false);
    }
  }, [searchListProducts]);

  const cleanFilter = () => {
    setValue('country', undefined);
    reset();
    setGoals([]);
    setFilterBadge(false);

    if (isCredit) {
      setSearchParams({ activeTab: 'carbon' });
    } else {
      setSearchParams({});
    }
  };

  const getManyFilters = async () => {
    let res = {};
    if (activeTab === 1) {
      res = await getServiceFilters();
    } else {
      res = await getManyProductFilters();
    }

    if (res.data && res.data.getServiceFilters) {
      const filtersList = res.data.getServiceFilters;
      if (filtersList.categories && filtersList.categories.length) {
        setCategoriesList(filtersList.categories);
      }

      if (filtersList.countries && filtersList.countries.length) {
        setCountriesList(filtersList.countries);
      }
      setProductsGroup([]);
    }

    if (res.data && res.data.getManyProductFilters) {
      const filtersList = res.data.getManyProductFilters;
      if (filtersList.categories && filtersList.categories.length) {
        setCategoriesList(filtersList.categories);
      }

      if (filtersList.countries && filtersList.countries.length) {
        setCountriesList(filtersList.countries);
      }

      if (filtersList.groups && filtersList.groups.length) {
        setProductsGroup(filtersList.groups);
      }
    }
  };

  const showFilterData = async (withClose = false, page) => {
    setLoading(true);
    let badge = false;
    const formData = getValues();
    let data = {};
    if (formData.price_min && !formData.ordersBy) {
      data.ordersBy = [
        {
          column: 'price',
          direction: 'ASC',
        },
      ];
      badge = true;
    }
    if (formData.partial_search) {
      data.partial_search = formData.partial_search;
    }
    if (formData.ordersBy) {
      data.ordersBy = formData.ordersBy;
      badge = true;
    }
    if (formData.price_min) {
      data.price_min = +formData.price_min;
      badge = true;
    }
    if (formData.price_max) {
      data.price_max = +formData.price_max;
      badge = true;
    }
    if (formData.country_id && formData.country_id.length) {
      if (activeTab === 2) {
        data.region_id = formData.country_id;
      } else {
        data.country_id = formData.country_id;
      }
      badge = true;
    }
    if (formData.category_id && formData.category_id.length) {
      data.category_id = formData.category_id;
      badge = true;
    }
    if (formData.group_id && formData.group_id.length) {
      data.group_id = formData.group_id;
      badge = true;
    }
    if (goals.length > 0) {
      data.creditGoals = goals;
      badge = true;
    }
    setFilterBadge(badge);
    setFilterData(data);
    page && setCurrentPage(1);
    changeFilter(data, withClose, page);
  };

  const onEnter = (e, page) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      localStorage.setItem('currentPage', JSON.stringify(1));
      setValue('partial_search', e.target.value);
      const formData = getValues();
      let badge = false;
      let data = {};
      if (formData.partial_search) {
        data.partial_search = formData.partial_search;
      }
      setFilterBadge(badge);
      setFilterData(data);
      page && setCurrentPage(1);
      changeFilter(data, true, page, true);

      e.target.value.length > 2 && showFilterData();
      e.target.value === '' && showFilterData();
      setOverlayVisible(false);
    }
  };

  const handleClickAway = (event) => {
    if (
      openFilterModal &&
      ((searchComponentRef.current && !searchComponentRef.current.contains(event.target)) ||
        (searchComponentRef2.current && !searchComponentRef2.current.contains(event.target)))
    ) {
      setOverlayVisible(false);
      setValue('partial_search', '');
      showFilterData(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Box className={styles.filterBar} sx={{ flexGrow: 1 }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box className={styles.searchComponent}>
                {isOverlayVisible && <Box className={styles.overlay}></Box>}
                <Box
                  className={`${styles.searchFilter} ${isOverlayVisible ? styles.highlightedInput : ''}`}
                  ref={searchComponentRef}
                >
                  <Image src="/images/market-place/search-icon.svg" width={19.54} height={19.54} />
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    onKeyDown={(e) => onEnter(e, 1)}
                    value={getValues('partial_search')}
                    {...register('partial_search')}
                    onChange={(e) => {
                      e.target.value.length > 0
                        ? setOverlayVisible(true)
                        : setOverlayVisible(false);
                      localStorage.setItem('currentPage', JSON.stringify(1));
                      setValue('partial_search', e.target.value);
                      e.target.value.length > 1
                        ? showFilterData(false, 1)
                        : setSearchListProducts([]);
                    }}
                  />
                  {isOverlayVisible && (
                    <CloseIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOverlayVisible(false);
                        setValue('partial_search', '');
                        showFilterData();
                      }}
                    />
                  )}
                </Box>
                {isOverlayVisible &&
                  (loading ? (
                    <Box className={styles.loading}>
                      <Loader />
                    </Box>
                  ) : searchListProducts?.length > 0 ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box className={styles.dropdownSearch} ref={searchComponentRef}>
                        {searchListProducts.slice(0, 9).map((itemSearch) => (
                          <Box
                            key={itemSearch.id}
                            className={styles.searchRow}
                            onClick={() => openProduct(itemSearch.id, itemSearch.user_id)}
                          >
                            <Box className={styles.searchItem}>
                              <p className={styles.searchTitle}>{itemSearch.name}</p>
                              <p className={styles.searchSubtitle}>{itemSearch.category?.name}</p>
                            </Box>
                            <ArrowAscIcon className={styles.arrowAsIcon} />
                          </Box>
                        ))}
                      </Box>
                    </ClickAwayListener>
                  ) : (
                    <Box className={styles.dropdownSearchEmpty}>
                      <p>Nothing found? Try a different query.</p>
                    </Box>
                  ))}
              </Box>
            </ClickAwayListener>
            <Box className={styles.filter} onClick={() => setOpenFilterModal(true)}>
              <Image src="/images/market-place/filter-icon.svg" width={19.1} height={18.1} />
              {filterBadge && <div className={styles.filerLive} />}
            </Box>
            {activeTab !== 2 && (
              <>
                <Box className={styles.shoppingCart} onClick={() => navigate('/dashboard/cart')}>
                  <Image src="/images/market-place/shopping-cart.svg" width={24} height={24} />
                </Box>
              </>
            )}
            <Box className={styles.filterActions}>
              <Box className={styles.buttonsGroup}>
                <IconButton style={{ padding: 0 }} onClick={() => setActiveMode('gridMode')}>
                  <GridViewIcon
                    className={activeMode === 'gridMode' ? styles.activeIcon : styles.filterIcons}
                  />
                </IconButton>
                <IconButton style={{ padding: 0 }} onClick={() => setActiveMode('listMode')}>
                  <TableViewIcon
                    className={activeMode === 'listMode' ? styles.activeIcon : styles.filterIcons}
                  />
                </IconButton>
                {pageType !== 'marketplace' && activeTab !== 2 && (
                  <IconButton style={{ padding: 0 }} onClick={() => setActiveMode('map')}>
                    <MapIcon
                      className={activeMode === 'map' ? styles.activeIcon : styles.filterIcons}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>

      <FilterModal
        open={openFilterModal}
        activeTab={activeTab}
        watch={watch}
        control={control}
        getValues={getValues}
        setValue={setValue}
        show={showFilterData}
        cleanFilter={() => cleanFilter()}
        handleClose={() => setOpenFilterModal(false)}
        countriesList={countriesList}
        categoriesList={categoriesList}
        productsGroup={productsGroup}
        goals={goals}
        setGoals={setGoals}
        isCredit={isCredit}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default Filter;
