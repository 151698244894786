import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../../../../components/Image';
import styles from './SelfAssessmentTab.module.scss';
import { Button } from '@mui/material';
const CustomizedAccordion = {
  '& .MuiButtonBase-root': {
    padding: 0,
    minHeight: '0 !important',

    '& .MuiAccordionSummary-content': {
      margin: 0,

      '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#212121',
      },
    },
  },
};
const SelfAssessmentTab = () => {
  const [expanded, setExpanded] = useState(0);
  const openCloseExpanded = (index) => {
    if (index === expanded) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };
  return (
    <>
      {true ? (
        <>
          <Box className={styles.containerFull}>
            <Box>
              <Accordion
                className={styles.accordionContainer}
                sx={CustomizedAccordion}
                expanded={expanded === 0}
                onChange={() => openCloseExpanded(0)}
              >
                <AccordionSummary
                  className={styles.accordionSummary}
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box className={expanded ? styles.titleOpened : styles.titleExpanded}>
                    <Image src="/images/tt-logo.svg" width={40} height={40} />
                    <p>TT ESG Local</p>
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <Box className={styles.content}>
                    <Box className={styles.contentItem}>
                      <Box className={styles.reportingPeriodBlock}>
                        <p className={styles.text}>Reporting period:</p>
                        <p className={styles.date}>2018-2019</p>
                      </Box>
                      <Button className={styles.submitBtn}>submit a request</Button>
                    </Box>
                    <Box className={styles.contentItem}>
                      <Box className={styles.reportingPeriodBlock}>
                        <p className={styles.text}>Reporting period:</p>
                        <p className={styles.date}>2018-2019</p>
                      </Box>
                      <Button className={styles.submitBtn}>submit a request</Button>
                    </Box>
                    <Box className={styles.contentItem}>
                      <Box className={styles.reportingPeriodBlock}>
                        <p className={styles.text}>Reporting period:</p>
                        <p className={styles.date}>2018-2019</p>
                      </Box>
                      <Button className={styles.submitBtn}>submit a request</Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box className={styles.containerFull}>
            <Box>
              <Accordion
                className={styles.accordionContainer}
                sx={CustomizedAccordion}
                expanded={expanded === 1}
                onChange={() => openCloseExpanded(1)}
              >
                <AccordionSummary
                  className={styles.accordionSummary}
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box className={expanded ? styles.titleOpened : styles.titleExpanded}>
                    <Image src="/images/green-earth-icon.svg" width={40} height={40} />
                    <p>TT ESG Global</p>
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <Box className={styles.content}>
                    <Box className={styles.contentItem}>
                      <Box className={styles.reportingPeriodBlock}>
                        <p className={styles.text}>Reporting period:</p>
                        <p className={styles.date}>2018-2019</p>
                      </Box>
                      <Button className={styles.submitBtn}>submit a request</Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </>
      ) : (
        <Box className={styles.containerEmpty}>
          <p className={styles.message}>
            The business profile is yet to complete its self-assessment process
          </p>
          <p className={styles.subMessage}>
            Currently, there are no results available on this page.
          </p>
        </Box>
      )}
    </>
  );
};

export default SelfAssessmentTab;
