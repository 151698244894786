/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './bigCalendar.module.scss';

const locales = 'en-US';
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: 'Big Meeting',
    allDay: true,
    start: new Date(2021, 6, 0),
    end: new Date(2021, 6, 0),
  },
  {
    title: 'Vacation',
    start: new Date(2021, 6, 7),
    end: new Date(2021, 6, 10),
  },
  {
    title: 'Conference',
    start: new Date(2021, 6, 20),
    end: new Date(2021, 6, 23),
  },
];

export const BigCalendar = (props) => {
  const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '' });
  const [allEvents, setAllEvents] = useState(events);

  const [view, setView] = useState('day');
  const [visOption, setVisOption] = useState(false);

  const { onClose } = props;
  /* const [date, setDate] = useState(new Date(2015, 3, 12));
  const [width, setWidth] = useState(500); */

  /* function handleAddEvent() {
    setAllEvents([...allEvents, newEvent]);
  } */

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <button type="button" onClick={() => onClose?.()}>
            Close
          </button>
          MyCalendar
        </div>
        <div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className={styles.image}
              src="/images/Options.png"
              width={6}
              height={18}
              onClick={() => setVisOption((value) => !value)}
            />
            <List
              className={styles.dropdown}
              hidden={!visOption}
              component="nav"
              aria-label="mailbox folders"
            >
              <ListItem
                style={{ borderBottom: '1px solid grey' }}
                button
                onClick={() => {
                  setView('day');
                  setVisOption((value) => !value);
                }}
              >
                <ListItemText primary="Day" />
              </ListItem>
              <ListItem
                style={{ borderBottom: '1px solid grey' }}
                button
                onClick={() => {
                  setView('week');
                  setVisOption((value) => !value);
                }}
              >
                <ListItemText primary="Week" />
              </ListItem>
              <ListItem
                /* style={{ borderBottom: '1px solid grey' }} */
                button
                onClick={() => {
                  setView('month');
                  setVisOption((value) => !value);
                }}
              >
                <ListItemText primary="Month" />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        view={view}
        toolbar={false} // Отключение отображения стандартных кнопок
        style={{ height: 400, margin: '50px' }}
      />
    </div>
  );
};

export default BigCalendar;

/* <div>
        <input
          type="text"
          placeholder="Add Title"
          style={{ width: '20%', marginRight: '10px' }}
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
        />
        <DatePicker
          placeholderText="Start Date"
          style={{ marginRight: '10px' }}
          selected={newEvent.start}
          onChange={(start) => setNewEvent({ ...newEvent, start })}
        />
        <DatePicker
          placeholderText="End Date"
          selected={newEvent.end}
          onChange={(end) => setNewEvent({ ...newEvent, end })}
        />
        <button stlye={{ marginTop: '10px' }} onClick={handleAddEvent}>
          Add Event
        </button>
      </div> */
