import { Button } from '@mui/material';
import { CommunityPage } from '../../../communityPage';
import { useEffect, useState } from 'react';
import styles from './platformPage.module.scss';
import { useFormContext } from 'react-hook-form';

const GOALS = [
  'no_poverty',
  'zero_hunger',
  'good_health',
  'quality_education',
  'gender_quality',
  'clear_water',
  'clean_energy',
  'economic_growth',
  'industry',
  'reduce_inequality',
  'sustainable_community',
  'responsible_consumption',
  'climate_action',
  'life_below_water',
  'life_on_land',
  'peace',
  'partnership',
];

export const PlatformForm = ({ save, isErrorFill, setIsErrorFill, setSubStepOpen, goals = [] }) => {
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (goals && goals.length) {
      const goalsList = GOALS.map((item) => {
        let selectedItem = goals.find((goal) => goal.name === item);
        if (selectedItem) {
          selectedItem = { ...selectedItem };
          delete selectedItem.__typename;
          return { ...selectedItem, checked: true };
        }
        return item;
      });
      setValue('goals', goalsList);
    }
  }, [goals]);

  useEffect(() => {
    if (open) {
      setSubStepOpen(true);
    }
  }, [open]);

  let setOpenPage = (value) => {
    setIsErrorFill(false);
    setOpen(value);
    setSubStepOpen(value);
  };
  return (
    <>
      {!open ? (
        <div className={styles.platformDiv}>
          <div className={styles.platformText}>
            On our platform, we welcome businesses that are addressing the UN goals of achieving a
            sustainable future for all.
          </div>
          <div className={styles.row}>
            <div className={styles.blockRow}>
              <img
                style={{
                  height: '70px',
                  width: '70px',
                }}
                src="/images/circle.svg"
              />
              <div className={styles.circleText}>
                Complete a questionnaire that will show what SDGs goals the company is addressing.
              </div>
            </div>
            <div>
              <Button
                variant="outlined"
                size="medium"
                className={styles.platformGoals}
                onClick={() => setOpen(true)}
              >
                Fill out the questionnaire
              </Button>
              {/*{isErrorFill ? <div style={{ color: '#D32F2F' }}>Fill in the form*</div> : null}*/}
            </div>
          </div>
        </div>
      ) : (
        <CommunityPage setOpen={setOpenPage} save={() => save()} saveButton={true} />
      )}
    </>
  );
};
