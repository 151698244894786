import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import styles from './communityPage.module.scss';
import { useFormContext, Controller } from 'react-hook-form';

export const CommunityBody = () => {
  const GOALS = [
    'no_poverty',
    'zero_hunger',
    'good_health',
    'quality_education',
    'gender_quality',
    'clear_water',
    'clean_energy',
    'economic_growth',
    'industry',
    'reduce_inequality',
    'sustainable_community',
    'responsible_consumption',
    'climate_action',
    'life_below_water',
    'life_on_land',
    'peace',
    'partnership',
  ];

  const [selectedGoals, setSelectedGoals] = React.useState([]);
  const [openTextareaDialog, setOpenTextareaDialog] = React.useState(false);
  const [text, setText] = React.useState('');
  const [selectedGoal, setSelectedGoal] = React.useState(null);
  const [textValidationError, setTextValidationError] = React.useState(null);

  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const goalsList = getValues('goals');
    if (goalsList && goalsList.length) {
      let goals = [];
      goalsList.map((item) => {
        if (item.checked) {
          goals.push(item.name);
        }
      });
      setSelectedGoals(goals);
    }
  }, []);

  const saveText = () => {
    let value = text.trim();
    if (value === '') {
      setTextValidationError('Please fill out the this field. This is mandatory.');
      return false;
    }
    if (value.length > 150) {
      setTextValidationError('This field cannot be longer than 150 characters.');
      return false;
    }
    setTextValidationError(null);
    setValue(`goals[${selectedGoal}].description`, value);
    setSelectedGoal(null);
    setOpenTextareaDialog(false);
  };

  return (
    <div className={styles.communityContent}>
      {GOALS.map((goal, index) => (
        <div key={index} className={styles.communityBlock}>
          <img src={`/images/community/${goal}.png`}></img>
          <div className={styles.sdgItemInfo}>
            <input type="hidden" {...register(`goals[${index}].name`)} value={goal} />
            <Controller
              control={control}
              name={`goals[${index}].checked`}
              render={({ field: { onChange, value } }) => (
                <FormGroup>
                  <FormControlLabel
                    className={styles.communityCheckbox}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => {
                          if (selectedGoals.includes(goal)) {
                            setSelectedGoals(selectedGoals.filter((item) => item !== goal));
                          } else {
                            setSelectedGoals([...selectedGoals, goal]);
                          }
                          onChange(e);
                        }}
                        sx={{
                          fontFamily: 'Montserrat',
                          color: '#C4C4C4',
                          paddingBottom: 0,
                          paddingTop: 0,
                          '&.Mui-checked': {
                            color: 'primary',
                          },
                        }}
                      />
                    }
                    label="We address this issue"
                  />
                </FormGroup>
              )}
            />
            {selectedGoals.includes(goal) && (
              <textarea
                onClick={(e) => {
                  setText(e.target.value);
                  setOpenTextareaDialog(true);
                  setSelectedGoal(index);
                }}
                {...register(`goals[${index}].description`)}
                className={styles.communityTextarea}
                placeholder="Please add up to 150 characters"
              />
            )}
          </div>
        </div>
      ))}
      <Dialog
        open={openTextareaDialog}
        onClose={() => setOpenTextareaDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">How does your company address this issue?</DialogTitle>
        <DialogContent>
          <textarea
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              setTextValidationError(null);
            }}
            className={styles.communityDialogTextarea}
            placeholder="Please add up to 150 characters"
          />
          <p className={styles.errorText}>{textValidationError}</p>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '5px 20px 15px 20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{ color: '#18397A', borderColor: '#18397A', fontWeight: 700 }}
            variant="outlined"
            onClick={() => setOpenTextareaDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#18397A', fontWeight: 700 }}
            onClick={saveText}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
