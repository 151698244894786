import styles from './investAccount.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const InvestAccount = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        maxWidth: '900px',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '180px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div className={styles.esg}>ESG</div>
          <div style={{ marginRight: '30px', color: '#1F4A9D' }}>
            <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
          </div>
        </div>
        <div className={styles.leftColumn}>Business</div>
        <div className={styles.leftColumn}>Marketplace</div>
        <div className={styles.leftColumn}>Tokenomy</div>
        <div className={styles.leftColumn}>Events</div>
        <div className={styles.leftColumn}>Financials</div>
        <div className={styles.leftColumn}>Content</div>
      </div>
      <div
        style={{
          maxWidth: '720px',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={styles.rightColumnFirst}>
          Once your business account is created, you can find the spacial ESG page in your menu.
        </div>
        <div className={styles.rightColumn}>
          It will drustically increase your business&apos;s visibility before ESG investors.
        </div>
        <div className={styles.rightColumn}>
          TransparenTerra provides you a digital toolkits for your ESG score and carbon footprint
          self-assessment.
        </div>
        <div className={styles.rightColumn}>
          Please visit this page and follow the instructions.
        </div>
      </div>
    </div>
  );
};
