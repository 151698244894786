import React, { useState } from 'react';
import Image from '../../../components/Image';
import { styled } from '@mui/material/styles';
import { Menu, IconButton } from '@mui/material';
import { DELETE_COMPANY } from '../../../api';
import { ShareModal } from '../../../modules/tt-modals';
import clsx from 'clsx';
import CompanyInfoMenuItem from './CompanyInfoMenuItem';
import styles from './CompanyInfoMenu.module.scss';
import { useNavigate } from 'react-router';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import DeleteItemModal from '../../../components/deleteItemModal';
import { useMutation } from '@apollo/client';
import PartialLoader from '../../../components/layout/PartialLoader';
import { EditIcon, ShareIcon, SendIcon, LitterBinIcon } from 'tt-ui-kit';
import DeleteCompanyModal from '../../../components/Modals/DeleteCompanyModal';

const CompanyInfoMenu = ({
  idButton,
  idMenu,
  companyId = null,
  type = null,
  companyName,
  ...restProps
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openShareModal = () => {
    setShowShareModal(true);
    handleClose();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
    handleClose();
  };

  const onCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopySuccess(true);
      handleClose();
    });
  };

  const StyledMenu = styled((props) => (
    <Menu
      id="company-info-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));

  return (
    <>
      <PartialLoader loading={loading} isLightContainer />
      <IconButton
        id="menu-button"
        className={clsx(styles.menuBtn, restProps?.btnClassName)}
        aria-controls={open ? 'company-info-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        type="button"
        onClick={handleClick}
      >
        <Image src="/images/company/menu-dots.svg" width={4} height={16} />
      </IconButton>
      <StyledMenu>
        <CompanyInfoMenuItem
          IconComponent={ShareIcon}
          text="Share"
          onClick={() => openShareModal()}
        />
        <CompanyInfoMenuItem IconComponent={SendIcon} text="Send" onClick={() => onCopy()} />
        <CompanyInfoMenuItem
          text="Edit"
          IconComponent={EditIcon}
          onClick={() =>
            navigate(
              `/dashboard/companyProfile/${
                type === 'company' ? 'representative' : 'externalAssessment'
              }?companyId=${companyId}`
            )
          }
        />
        <CompanyInfoMenuItem
          IconComponent={LitterBinIcon}
          isDelete
          text="Delete"
          onClick={() => openDeleteModal()}
        />
      </StyledMenu>
      <ShareModal open={showShareModal} closeModal={() => setShowShareModal(false)} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={copySuccess}
        autoHideDuration={2000}
        onClose={() => setCopySuccess(false)}
      >
        <Alert
          onClose={() => setCopySuccess(false)}
          severity="success"
          sx={{ width: '100%', backgroundColor: '#118c12', color: '#ffffff' }}
        >
          The link was successfully copied.
        </Alert>
      </Snackbar>

      <DeleteCompanyModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        id={companyId}
        companyName={companyName || ''}
      />
      {/*<DeleteItemModal*/}
      {/*  title="Do you want to delete this company?"*/}
      {/*  open={showDeleteModal}*/}
      {/*  handleClose={() => setShowDeleteModal(null)}*/}
      {/*  deleteItem={() => companyDelete()}*/}
      {/*/>*/}
    </>
  );
};

export default CompanyInfoMenu;
