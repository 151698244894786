import Box from '@mui/material/Box';
import React from 'react';
import Image from '../../../../../components/Image';
import { CommentsIcon, DropdownMenu, LeftIcon, LikeIcon, MoreVIcon, ShareTTIcon } from 'tt-ui-kit';
import { ButtonBase, Select, MenuItem, Avatar } from '@mui/material';
import styles from './AllTab.module.scss';

const AllTab = ({ user }) => {
  return (
    <>
      <Box className={styles.container}>
        {[1, 2, 3].map((item, index) => (
          <Box key={index} className={styles.postItem}>
            <Box className={styles.postHeader}>
              <Box className={styles.avatarInfo}>
                <Image src="/images/green-earth-icon.svg" />
                <Box className={styles.nameBlock}>
                  <p className={styles.name}>{"McDonald's"}</p>
                  <p className={styles.activity}>3 d. ago</p>
                </Box>
              </Box>
              <ButtonBase type="button" className={styles.optionsButton}>
                <Image src="/images/company/menu-dots.svg" width={4} height={16} />
              </ButtonBase>
            </Box>
            <Box className={styles.postContent}>
              Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur
              duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat
              incididunt sint deserunt ut voluptate aute id deserunt nisi.
            </Box>
            <Box className={styles.postInfo}>
              <Box className={styles.emojiBlock}>
                <Box>Emoji</Box>
                <Box className={styles.reactedCount}>123K</Box>
                <Box className={styles.reactedText}>reacted</Box>
              </Box>
              <Box className={styles.commentsCountBlock}>
                <Box>Comments: 942</Box>
                <Box>Shares: 100</Box>
              </Box>
            </Box>
            <Box className={styles.postActions}>
              <Box className={styles.actionItem}>
                <LikeIcon style={{ width: 24, height: 24 }} />
                <Box className={styles.actionName}>Like</Box>
              </Box>
              <Box className={styles.actionItem}>
                <CommentsIcon style={{ width: 24, height: 24 }} />
                <Box className={styles.actionName}>Comment</Box>
              </Box>
              <Box className={styles.actionItem}>
                <ShareTTIcon style={{ width: 24, height: 24 }} />
                <Box className={styles.actionName}>Share</Box>
              </Box>
              <div className={styles.inputOptions}>
                <Select
                  placeholder="Select user"
                  variant="standard"
                  className={styles.select}
                  value={user.id}
                  label="Select user"
                  size="medium"
                  defaultValue={1}
                  MenuProps={{
                    classes: {
                      paper: styles.selectBody,
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                >
                  <MenuItem value={user.id} className={styles.menuItem}>
                    <div className={styles.selectItem}>
                      <Avatar src={user?.avatar} className={styles.avatar}>
                        {user?.first_name
                          ? `${user.first_name} ${user.last_name}`
                              .split(' ')
                              .map((i) => i[0])
                              .join('')
                          : ''}
                      </Avatar>
                      <span className={styles.menuItem__title}>
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                  </MenuItem>
                </Select>
              </div>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
export default AllTab;
