import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from '@mui/material/Tooltip';
import styles from './stepper.module.scss';

const stepLabelSX = {
  '.MuiStepIcon-root': {
    width: '13px',
    height: '13px',
    fill: 'white',
    backgroundColor: ' #FFFFFF',
    border: '2px solid #8C9DBD',
    borderRadius: '16px',
  },

  '.MuiStepIcon-text': {
    display: 'none',
  },
  '.Mui-active': {
    background: '#1F4A9D',
    border: '2px solid #1F4A9D',
    padding: '0px',
    borderRadius: '16px',
    color: '#1F4A9D',
    width: '13px',
    height: '13px',

    '.MuiStepConnector-horizontal': {
      borderTopWidth: '3px',
      borderColor: '#EFEFEF',
      borderRadius: '16px',
      widht: '100%',
    },

    '.MuiStepIcon-root': {
      display: 'none',
    },
  },
  '.Mui-completed': {
    background: '#1F4A9D',
    border: '2px solid #1F4A9D',
    borderRadius: '16px',
    padding: '0px',
    color: '#1F4A9D',
    width: '13px',
    height: '13px',

    '.MuiStepIcon-root': {
      display: 'none',
    },
  },
};

const stepperSX = {
  maxWidth: '700px',
  margin: '0px auto',
  padding: '0px 20px',

  '.MuiStep-root': {
    padding: '0px',
  },

  '.MuiStepLabel-iconContainer': {
    paddingRight: '0px',
  },

  '.Mui-active': {
    '.MuiStepConnector-line': {
      borderTopWidth: '3px',
      margin: '0px 3px',
      borderColor: '#1F4A9D',
    },
  },

  '.Mui-completed': {
    '.MuiStepConnector-line': {
      borderTopWidth: '3px',
      margin: '0px 3px',
      borderColor: '#1F4A9D',
    },
  },

  '.MuiStepConnector-root': {
    '.MuiStepConnector-line': {
      borderTopWidth: '3px',
      margin: '0px 3px',
    },
  },
};

let tooltipProps = {
  arrow: {
    sx: {
      color: '#999999',
    },
  },
  tooltip: {
    sx: {
      padding: '12px 20px',
      border: 'none',
      borderRadius: '12px',
      backgroundColor: '#999999',
    },
  },
};

const FormStepper = ({ activeStep, steps }) => {
  return (
    <Box
      sx={{
        width: '100%',
        background: 'white',
        padding: '10px 0px',
        borderRadius: '12px',
      }}
    >
      <Stepper activeStep={activeStep} orientation="horizontal" sx={stepperSX}>
        {steps.map((item, index) => (
          <Step key={index}>
            <Tooltip
              slotProps={tooltipProps}
              title={
                <Box>
                  <Typography className={styles.tooltipTitle}>Step {index + 1}</Typography>
                  <Typography className={styles.tooltipDescription}>{item.name}</Typography>
                </Box>
              }
              arrow
              placement="top"
            >
              <StepLabel sx={stepLabelSX} icon={''} />
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default FormStepper;
