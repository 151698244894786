import React, { useState } from 'react';
import { Invite } from '../../modules/tt-invite-widget';
import { InviteModal } from '../../modules/tt-modals';
// import 'tt-invite-widget/dist/index.css';1
import { GET_ROLE, SEND_INVITE } from '../../api';

export const InviteWrapper = () => {
  const [isModal, setModal] = useState(false);
  const onClose = () => setModal(false);

  return (
    <>
      <Invite setInvitionModalVisible={setModal} />
      <InviteModal
        visible={isModal}
        title="Create an invitation"
        onClose={onClose}
        roleQuery={GET_ROLE}
        sendInviteQuery={SEND_INVITE}
      />
    </>
  );
};
