import React from 'react';
import Box from '@mui/material/Box';
import { Modal } from 'tt-ui-kit';
import styles from '../registrationType.module.scss';
import Typography from '@mui/material/Typography';

const HelpModal = ({ open, handleClose }) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={() => handleClose()}
        onClick={(e) => e.stopPropagation()}
        title=""
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className={styles.modalContent}>
          <Typography>
            To become visible before the impact investors your company should pass through 2-step
            procedure:
          </Typography>
          <Typography>1. KYB (Know your Business).</Typography>
          <Typography>
            Once your company is verified, you will get access to our ESG and Carbon footprint
            calculators.
          </Typography>
          <Typography>2. ESG and CO2 footprint self-assessment.</Typography>
          <Typography>
            Your scores will be deployed in the blockchain and become public. Your initial score
            doesn’t matter. Your transparency and intetion to develop your company’s sustainability
            matter. It will drastically increase your business’s visibility before ESG investors.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default HelpModal;
