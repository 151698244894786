import React from 'react';
import { KycModal } from '../../../modules/tt-modals';

const KycModalWrapper = ({ isModelOpen, setIsOpen, handleClose, yotiUrl }) => (
  <KycModal
    isModelOpen={isModelOpen}
    setIsOpen={setIsOpen}
    handleClose={handleClose}
    yotiUrl={yotiUrl}
  />
);

export default KycModalWrapper;
