import React from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './uploadFile.module.scss';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddIcon from '@mui/icons-material/Add';

export const DropZone = ({ onDropped }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDropped(files),
  });

  return (
    <div className={styles.dropzoneArea}>
      <div className={styles.dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={styles.dropzoneContainer}>
          <div className={styles.dropzoneTextRow}>
            <NoteAddIcon />
            <p>Drag files here</p>
          </div>
          <p className={styles.dropzoneSubtitle}>You can add file with the maximum size 700 MB</p>
        </div>
      </div>
      <div className={styles.dropzoneUpload}>
        <label className={styles.dropzoneRow}>
          <input {...getInputProps()} />
          <AddIcon color="primary" />
          <span>Choose file</span>
        </label>
        <div className={styles.dropzoneRow}>
          <img src="/images/Dropbox.png" />
          <p>Upload from dropbox</p>
        </div>
        <div className={styles.dropzoneRow}>
          <img src="/images/GoogleDrive.png" />
          <p>upload from google drive</p>
        </div>
        <div className={styles.dropzoneRow}>
          <img src="/images/Icloud.png" />
          <p>upload from icloud</p>
        </div>
      </div>
    </div>
  );
};
