/* eslint-disable */
import { Avatar } from '@mui/material';
import React, { useEffect, useState, useContext, useRef } from 'react';
import userContext from '../../context/User/userContext';
import { useNavigate } from 'react-router';
import styles from './AppAvatar.module.scss';
import { AvaOptionModal } from './Modals/avatapOptionModal';
import { AvatarBadge } from './AvatarBadgeDashboard';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import KycModalWrapper from '../Modals/KycModal/KycModal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SIGN_VALIDATOR_AGREEMENT, GET_VALIDATOR_AGREEMENT, GET_IDV_INIT } from '../../api';
// import AgreementModal from './Modals/agreementModal';
import ModalDialog from '../ModalDialog';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrow-down.svg';
import { KycModal } from '../../modules/tt-modals';

export const AppAvatar = ({
  children,
  size = 'md',
  userName,
  role,
  nav,
  logOut,
  openOptions,
  arrowButton,
  selectProfile,
  selectSettings,
  selectHelp,
  selectCompany,
  selectAddCompany,
  selectHashtegs,
  selectLogOut,
  ...props
}) => {
  const router = useNavigate();
  const sizes = {
    xxs: '24px',
    xs: '40px',
    xl: '44px',
    sm: '56px',
    md: '63px',
    lg: '128px',
  };
  const fontSizes = {
    xxs: '12px',
    xs: '12px',
    xl: '12px',
    sm: '12px',
    md: '16px',
    lg: '42px',
  };

  // const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [isValidatorAgree, setIsValidatorAgree] = useState(false);
  const [validatorText, setValidatorText] = useState(null);

  const handleModalOpen = (setIsOpen) => setIsOpen(true);
  const handleModalClose = (setIsOpen) => setIsOpen(false);

  const [openCollapse, setOpenCollapse] = useState(false);
  const [visOption, setVisOption] = useState(false);
  const [isModal, setModal] = useState(false);
  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [isKycModelOpen, setIsKycModalOpen] = useState(false);
  const [yotiUrl, setYotiUrl] = useState(null);
  const [isIndividual, setIsIndividual] = useState(true);

  const dropdownRef = useRef();

  const { user } = useContext(userContext);

  const location = window.location.href;
  const [idvInit] = useMutation(GET_IDV_INIT, {
    variables: {
      input: {
        user_id: user?.id,
        feedback_url: location,
      },
    },
  });

  const [validatorAgreement] = useLazyQuery(GET_VALIDATOR_AGREEMENT, {});
  const [signValidatorAgreement] = useLazyQuery(SIGN_VALIDATOR_AGREEMENT, {});

  const onChangeOpen = () => {
    setModal((value) => !value);
    setVisOption((value) => !value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisOption(false);
    }
  };

  const selectPassKYC = () => {
    onChangeOpen();
    setIsKycModalOpen(true);
  };

  const selectValidatorAgreement = async () => {
    // TODO fix
    // onChangeOpen();
    // validatorAgreement()
    //   .then((res) => {
    //     setValidatorText(res.data.agreementValidator.body);
    //     setIsAgreementModalOpen(true);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const signAgreement = async () => {
    signValidatorAgreement()
      .then((res) => {
        // TODO update user
        console.log('tada');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user.roles && user.roles.length) {
      const roleExpert = user.roles?.find((elem) => elem.name === 'expert');
      const roleBusiness = user.roles?.find((elem) => elem.name === 'business');
      const roleInvestor = user.roles?.find((elem) => elem.name === 'investor');
      if (roleExpert || roleBusiness || roleInvestor) {
        setIsIndividual(false);
      } else {
        const roleHolder = user.roles?.find((elem) => elem.name === 'holder');
        if (roleHolder) {
          setIsIndividual(false);
        } else {
          const roleIndividual = user.roles?.find((elem) => elem.name === 'individual');

          if (roleIndividual) {
            setIsIndividual(true);
          } else {
            setIsIndividual(false);
          }
        }
      }
    }
  }, [user]);

  const text = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#212121',
  };

  let onSelecetBusiness = (e) => {
    e.stopPropagation();
    setOpenCollapse(!openCollapse);
  };

  const initials = userName
    ? userName
        .split(' ')
        .map((i) => i[0])
        .join('')
    : '';

  const onSelectProfile = () => {
    window.location.replace(`${process.env.REACT_APP_TT}/dashboard/profile/${user?.id}`);
  };

  return (
    <div>
      <div
        className={styles.avatarOverlay}
        // style={{
        //   width: sizes[size],
        //   height: sizes[size],
        // }}
        ref={dropdownRef}
        onClick={() => onChangeOpen()}
      >
        {nav === 2 && (
          <div className={styles.avatarRow}>
            <Avatar {...props} src={user?.avatar} className={styles.avatar}>
              <>
                {children}
                {initials}
              </>
            </Avatar>
          </div>
        )}
        {nav === 1 && (
          <Avatar
            {...props}
            sx={{
              width: `${sizes[size]} !important`,
              height: `${sizes[size]} !important`,
              fontSize: `${fontSizes[size]} !important`,
              backgroundColor: 'primary.black-10',
              color: 'primary.black-80',
              fontWeight: '500',
            }}
            className={styles.avatar}
          >
            <>
              {children}
              {initials}
            </>
          </Avatar>
        )}
        {nav === 0 && (
          <Avatar
            {...props}
            sx={{
              width: sizes[size],
              height: sizes[size],
              fontSize: fontSizes[size],
              bgcolor: 'primary.black-10',
              color: 'primary.black-80',
              fontWeight: '500',
            }}
            className={styles.avatarMobile}
          >
            <>
              {children}
              {initials}
            </>
          </Avatar>
        )}
        {arrowButton === 1 && (
          <div
            className={styles.downArrowDiv}
            style={{ backgroundColor: `${visOption ? '#143471' : 'white'}` }}
          >
            {!visOption ? (
              <ArrowDown
                className={styles.arrow}
                /*
                  width={12}
                  height={12}
                */
                // layout="fixed"
                // src="/images/templates/arrow-slice.png"
              />
            ) : (
              <ArrowUp
                className={styles.arrowActive}
                fill={'white'}
                /*
                  width={12}
                  height={12}
                */
                // layout="fixed"
                // src="/images/templates/arrow-sliceWhite.png"
              />
            )}
          </div>
        )}
        {role && (size === 'md' || size === 'sm' || size === 'xxs') && (
          <AvatarBadge size={size} role={role} />
        )}
        {openOptions === 1 && (
          <div
            hidden={!visOption}
            onClick={() => setVisOption((value) => !value)}
            className={styles.dropdownBox}
          >
            <ul
              className={styles.dropdown}
              hidden={!visOption}
              onClick={() => setVisOption(false)}
              component="nav"
              aria-label="mailbox folders"
            >
              <li className={styles.dropdownItem} onClick={onSelectProfile}>
                Profile
              </li>
              <li className={styles.dropdownItem} onClick={selectSettings}>
                Settings and security
              </li>
              <li className={styles.dropdownItem} onClick={selectHelp}>
                Help
              </li>
              {/* {user.role_name !== 'individual' && (
                <div className={styles.dropdownCollapse} onClick={(e) => onSelecetBusiness(e)}>
                  <li className={styles.dropdownItemCollapse}>Business account</li>
                  {openCollapse ? (
                    <div className={styles.dropdownArrowCollapse}>
                      <ExpandLess />
                    </div>
                  ) : (
                    <div className={styles.dropdownArrowCollapse}>
                      <ExpandMore />
                    </div>
                  )}
                </div>
              )} */}
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <div style={{ paddingLeft: '10px' }}>
                  <div className={styles.dropdownItemCompany}>
                    <img src="/images/templates/DropDownElipse.png" alt="" />
                    <li>Companyname</li>
                  </div>
                  <div className={styles.dropdownItemCompany}>
                    <img src="/images/templates/dropdownElipsetwo.png" alt="" />
                    <li>Company 2</li>
                  </div>
                </div>
              </Collapse>
              {!isIndividual &&
                user?.kyc !== 'success' &&
                user?.kyc !== 'pending' &&
                user?.kyc !== 'created' && (
                  <li className={styles.dropdownItem} onClick={selectPassKYC}>
                    Pass KYC
                  </li>
                )}
              {user?.roles_list &&
                JSON.parse(user.roles_list).includes('validator') &&
                user.agreement?.validator_agreement !== 1 && (
                  <li className={styles.dropdownItem} onClick={selectValidatorAgreement}>
                    Validator agreement
                  </li>
                )}
              <li className={styles.dropdownItem} onClick={logOut}>
                Log out
              </li>
            </ul>
          </div>
        )}
        {openOptions === 1 && (
          <div className={styles.settingModal}>
            <AvaOptionModal isIndividual={isIndividual} visible={isModal} onClose={onChangeOpen} />
          </div>
        )}
      </div>

      <KycModal isOpen={isKycModelOpen} setIsOpen={setIsKycModalOpen} userId={user.id} />
      {/* <AgreementModal
        isModelOpen={isAgreementModalOpen}
        setIsOpen={setIsAgreementModalOpen}
        handleClose={handleModalClose}
        setIsAgree={signAgreement}
        isAgree={isValidatorAgree}
        text={validatorText}
        checkboxText="I accept the Validator agreement"
        loading={isPolicyLoading}
      /> */}
    </div>
  );
};
