import { Box, CircularProgress, Grid } from '@mui/material';
import React, { useState } from 'react';
import styles from './GridMode.module.scss';
import GridListItem from './GridListItem';

const GridMode = ({ users, onScroll, loading }) => (
  <Grid
    onScroll={onScroll}
    className={styles.gridContent}
    alignItems="stretch"
    container
    spacing={2}
    columns={{ xl: 15, md: 12, sm: 12, xs: 12 }}
  >
    {users && users.map((user, index) => <GridListItem user={user} key={index} />)}
    {loading && (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 2 }}>
        <CircularProgress
          sx={{
            width: '40px !important',
            height: '40px !important',
          }}
        />
      </Box>
    )}
  </Grid>
);

export default GridMode;
