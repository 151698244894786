import { Box, Hidden, MenuItem, Select, Tab, Tabs } from '@mui/material';
import styles from './JobsTab.module.scss';
import React, { useState } from 'react';
import AllPostingTab from './Tabs/AllPostingTab';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const JobsTab = ({ createPost, activeTab, setActiveTab }) => {
  const [pageCount, setPageCount] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <Box className={styles.container}>
      <Box className={styles.containerHeader}>
        <div className={styles.title}>Jobs</div>
        <button type="button" className={styles.createJob} onClick={() => createPost(true)}>
          Create a job post
        </button>
      </Box>
      {activeTab === 0 ? <AllPostingTab setIsCreatePost={createPost} /> : <Box />}
      <div className={styles.paginationBlock}>
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          count={pageCount}
          className={styles.pagination}
          renderItem={(props) => (
            <PaginationItem
              className={[
                styles.paginationItem,
                currentPage === props.page ? styles.activePaginationItem : '',
              ]}
              {...props}
              disableRipple
            />
          )}
          onChange={(event, value) => {
            setCurrentPage(value);
          }}
        />
        <div className={styles.paginationText}>
          Page {currentPage} of {pageCount}
        </div>
      </div>
    </Box>
  );
};
export default JobsTab;
