import React, { useContext, useEffect, useState } from 'react';
import styles from './externalAssessmentPage.module.scss';
import FormStepper from '../../stepper/stepper';
import { SocialForm } from '../commonBusinessProfileStepsPage/forms/socialForm';
import { AboutCompanyForm } from './forms/aboutCompanyForm';
import { PlatformForm } from '../commonBusinessProfileStepsPage/forms/platformForm';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_ADDRESS_LOCATION,
  CREATE_COMPANY,
  EDIT_ADDRESS_LOCATION,
  EDIT_COMPANY,
  EDIT_COMPANY_CONTACT_INFOS,
  EDIT_COMPANY_GOALS,
  EDIT_COMPANY_SOCIAL_LINKS,
  EDIT_WITH_DELETE_COMPANY_LOGOS,
  GET_COMPANY_EXTENDED_BY_ID,
} from '../../../api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment/moment';
import { BusinessForm } from '../commonBusinessProfileStepsPage/forms/businessForm';
import userContext from '../../../context/User/userContext';

const FORM_STEPS = [
  {
    name: 'General Information',
  },
  {
    name: 'Contact information',
  },
  {
    name: 'About company',
  },
  {
    name: 'SDGs',
  },
];

const ExternalAssessmentPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const [contactsData, setContactsData] = useState([]);
  const [socialsData, setSocialsData] = useState([]);
  const [goalsList, setGoalsList] = useState([]);
  const [searchParams] = useSearchParams();
  const [subStepOpen, setSubStepOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [isErrorFill, setIsErrorFill] = useState(false);
  const methods = useForm();
  const [createCompany] = useMutation(CREATE_COMPANY);
  const [companyId, setCompanyId] = useState(null);
  const [searchParamsCompanyId, setSearchParamsCompanyId] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [createAddressLocation] = useMutation(CREATE_ADDRESS_LOCATION);
  const [editCompanyContactInfos] = useMutation(EDIT_COMPANY_CONTACT_INFOS);
  const [editCompanySocialLinks] = useMutation(EDIT_COMPANY_SOCIAL_LINKS);
  const [editWithDeleteCompanyLogos] = useMutation(EDIT_WITH_DELETE_COMPANY_LOGOS);
  const [companyAddressLocation, setCompanyAddressLocation] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [file, setFile] = useState(null);
  const [editAddressLocation] = useMutation(EDIT_ADDRESS_LOCATION);
  const [editCompany] = useMutation(EDIT_COMPANY);
  const [categories, setCategories] = useState([]);
  const [editCompanyGoals] = useMutation(EDIT_COMPANY_GOALS);
  const [getCompanyById] = useLazyQuery(GET_COMPANY_EXTENDED_BY_ID);
  const lastStepNumber = FORM_STEPS.length - 1;

  useEffect(() => {
    if (user.role_name === 'individual' || user.role_name === 'digital') {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    (async () => {
      const searchParamsId = searchParams.get('companyId');
      if (searchParamsId) {
        setSearchParamsCompanyId(searchParamsId);
        setCompanyId(searchParamsId);
        const response = await getCompanyById({ variables: { id: searchParamsId } });
        if (response.data && response.data.showCompanyById) {
          const companyInfoData = response.data.showCompanyById;
          if (companyInfoData.user_id === user?.id) {
            setCompanyInfo(companyInfoData);
            setCompanyData(companyInfoData);
            if (companyInfoData.companyGoals) {
              setGoalsList(companyInfoData.companyGoals);
            }
          } else {
            navigate(`/dashboard/company/${companyInfoData.id}`);
          }
        }
        if (response.data.showCompanyById.companyContactInfos) {
          setContactsData(response.data.showCompanyById.companyContactInfos);
        }
        if (response.data.showCompanyById.companySocialLinks) {
          setSocialsData(response.data.showCompanyById.companySocialLinks);
        }
      }
    })();
  }, [user, searchParams]);

  const handleSaveSocialForm = async (data) => {
    let accounts = data.accounts.map((account) => {
      const itemData = {
        email: account.email,
        tel: account.tel,
        department_name: account.department_name,
      };
      if (account.id) {
        itemData.id = account.id;
      }
      return itemData;
    });

    let socialLinks = Object.keys(data.social)
      .map((key) => ({
        ...data.social[key],
      }))
      .filter((item) => item.link !== '');

    const contactInfos = await editCompanyContactInfos({
      variables: {
        input: {
          company_id: companyId,
          companyInfos: accounts,
        },
      },
    });
    if (contactInfos.data && contactInfos.data.editCompanyContactInfos) {
      setContactsData(contactInfos.data.editCompanyContactInfos);
    } else if (contactInfos.errors) {
      setOpenErrorAlert(true);
    }

    const companySocialLinks = await editCompanySocialLinks({
      variables: {
        input: {
          company_id: companyId,
          socialLinks: socialLinks,
        },
      },
    });
    if (companySocialLinks.data && companySocialLinks.data.editCompanySocialLinks) {
      setSocialsData(companySocialLinks.data.editCompanySocialLinks);
    } else if (companySocialLinks.errors) {
      setOpenErrorAlert(true);
    }

    if (companySocialLinks.data && contactInfos.data) {
      setActiveStep((step) => step + 1);
    }
  };

  const createOrUpdateCompany = async (companyInput) => {
    if (companyData) {
      let editCompanyData = await editCompany({
        variables: {
          input: { ...companyInput, id: companyData.id },
        },
      });
      return editCompanyData.data.editCompany;
    } else {
      let createCompanyData = await createCompany({
        variables: {
          input: companyInput,
        },
      });
      return createCompanyData.data.createCompany;
    }
  };

  const createOrUpdateAddressLocation = async (addressLocationInput) => {
    if (companyAddressLocation) {
      let editAddressLocationData = await editAddressLocation({
        variables: {
          input: { ...addressLocationInput, id: companyAddressLocation.id },
        },
      });
      return editAddressLocationData.data.editAddressLocation;
    } else {
      let createAddressLocationData = await createAddressLocation({
        variables: {
          input: addressLocationInput,
        },
      });
      return createAddressLocationData.data.createAddressLocation;
    }
  };

  const handleSaveBusinessForm = async (data) => {
    try {
      if (data.location && data.location.data) {
        data.location.data.postcode = data.postcode;
      }
      let addressLocationData = await createOrUpdateAddressLocation(data.location);
      if (addressLocationData) {
        setCompanyAddressLocation(addressLocationData);
        let companyInput = {
          tax_vat: data.tax_vat,
          established_date: moment(data.established_date).format('YYYY-MM-DD HH:mm:ss'),
          company_name: data.companyName,
          email: data.email,
          for_profit: data.for_profit === '1',
          industry_group_id: data.industry_group,
          sub_industry_id: data.subindustry,
          is_governmental: data.governmentalOrganization,
          address_location_id: addressLocationData.id,
          address1: data.firstAddress,
          address2: data.secondAddress,
          legal_type_id: data.legalType,
          website: data.website,
          step_number: activeStep,
          type: 'external_assessment',
        };
        let company = await createOrUpdateCompany(companyInput);
        if (company) {
          setCompanyData(company);
          if (file) {
            await editWithDeleteCompanyLogos({
              variables: {
                input: {
                  company_id: company.id,
                  files: [
                    {
                      original_name: file.originalName,
                      uuid_name: file.uuIdName,
                      extension: file.extension,
                      size: file.size,
                      visibility: 'public',
                      type: 'company_logo_main',
                    },
                  ],
                },
              },
            });
          }
        }

        if (company) {
          setCompanyId(company.id);
          setActiveStep((step) => step + 1);
        }
      }
    } catch (e) {
      console.error(e);
      setOpenErrorAlert(true);
    }
  };

  const handleSaveAboutForm = async (data) => {
    if (data.aboutCompany) {
      await editCompany({
        variables: {
          input: {
            id: companyId,
            description: data.aboutCompany,
          },
        },
      });
    }
    setActiveStep((step) => step + 1);
  };

  const handleSavePlatformForm = async (data) => {
    try {
      if (data.goals && data.goals.length > 0) {
        setIsErrorFill(false);
        let goals = data.goals
          .filter((goal) => goal.checked)
          .map((goal) => ({
            name: goal.name,
            description: goal.description,
          }));
        await editCompanyGoals({
          variables: {
            input: {
              company_id: companyId,
              goals: goals,
            },
          },
        });
      } else {
        setIsErrorFill(true);
      }
    } catch (e) {
      console.error(e);
      setOpenErrorAlert(true);
    }
    //todo:check
    setActiveStep((step) => step + 1);
  };

  const onSubmit = (data) => {
    switch (activeStep) {
      case 0:
        return handleSaveBusinessForm(data);
      case 1:
        return handleSaveSocialForm(data);
      case 2:
        return handleSaveAboutForm(data);
      case 3:
        return handleSavePlatformForm(data);
      default:
        return false;
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <BusinessForm setFile={setFile} file={file} companyInfo={companyInfo} />;
      case 1:
        return (
          <SocialForm
            contactsData={contactsData}
            setContactsData={setContactsData}
            socialsData={socialsData}
            setSocialsData={setSocialsData}
          />
        );
      case 2:
        return <AboutCompanyForm categories={categories} setCategories={setCategories} />;
      case 3:
        return (
          <PlatformForm
            save={() => {
              methods.handleSubmit(onSubmit);
              setSubStepOpen(false);
            }}
            goals={goalsList}
            setSubStepOpen={setSubStepOpen}
            isErrorFill={isErrorFill}
            setIsErrorFill={setIsErrorFill}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {!searchParamsCompanyId && (
            <>
              <div className={styles.createText}>
                <h3 className={styles.title}> Create Your Company Account</h3>
                <h4 className={styles.subTitle}>For external assessment</h4>
              </div>
            </>
          )}
          <div style={{ marginBottom: 20 }}>
            <FormStepper activeStep={activeStep} steps={FORM_STEPS} />
          </div>
          <div className={styles.form}>{renderStepContent(activeStep)}</div>
          {!subStepOpen && !searchParamsCompanyId && (
            <div className={styles.buttons}>
              <button
                className={styles.buttonCancel}
                type="button"
                onClick={() => {
                  if (activeStep === 0) {
                    navigate(`/dashboard/companyProfile`);
                  } else {
                    setActiveStep((step) => step - 1);
                  }
                }}
              >
                BACK
              </button>
              {activeStep !== lastStepNumber ? (
                <button className={styles.buttonNext} type="submit">
                  {'NEXT'}
                </button>
              ) : (
                <button
                  className={styles.buttonNext}
                  onClick={() => {
                    setOpenSuccessAlert(true);
                    navigate('/dashboard/companiesList', { replace: true });
                  }}
                  type="submit"
                >
                  {'CREATE COMPANY FOR EXTERNAL ACCOUNT'}
                </button>
              )}
            </div>
          )}
          {!subStepOpen && searchParamsCompanyId && (
            <div className={styles.buttons}>
              <div>
                <button
                  className={styles.buttonExit}
                  style={{ marginRight: '10px' }}
                  type="button"
                  onClick={() => {
                    navigate(`/dashboard/company/${searchParamsCompanyId}/edit`);
                  }}
                >
                  EXIT
                </button>
                {activeStep > 0 ? (
                  <button
                    className={styles.buttonCancel}
                    type="button"
                    onClick={() => {
                      setActiveStep((step) => step - 1);
                    }}
                  >
                    BACK
                  </button>
                ) : null}
              </div>
              <div>
                {activeStep !== lastStepNumber ? (
                  <>
                    <button
                      className={styles.buttonNext}
                      type="submit"
                      style={{ marginRight: '10px' }}
                    >
                      {'SAVE'}
                    </button>
                    <button
                      className={styles.buttonNext}
                      type="button"
                      onClick={() => {
                        setActiveStep((step) => step + 1);
                      }}
                    >
                      {'NEXT STEP'}
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </form>
      </FormProvider>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          Something went wrong, try again
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSuccessAlert}
        onClose={() => setOpenSuccessAlert(false)}
      >
        <Alert
          onClose={() => setOpenSuccessAlert(false)}
          severity="success"
          sx={{ width: '100%', backgroundColor: '#118c12', color: '#ffffff' }}
        >
          Company Saved Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExternalAssessmentPage;
