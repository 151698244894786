import React from 'react';
import userContext from '../../../../context/User/userContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppAvatar } from '../../AppAvatarDashboard';
import styles from './AvaOptionModal.module.scss';
import { useMutation } from '@apollo/client';
import { LOGOUT_USER } from '../../../../api';
import { logoutUserHandler } from '../../../../utils/appUtils';

export const AvaOptionModal = (props) => {
  const { visible, onClose, isIndividual } = props;
  const { user } = useContext(userContext);
  const [logoutUser] = useMutation(LOGOUT_USER);

  const otherProps = [
    { href: '/Calculators', name: 'ESG calculator' },
    { href: '/dashboard/marketplace', name: 'Marketplace', parent: true },
    {
      href: '/dashboard/companiesList',
      name: 'Company Profile',
      isNotIndividual: true,
    },
    { href: '/digitalAssets', name: 'Digital Assets' },
    { href: '/events', name: 'Events' },
    { href: '/financials', name: 'Financials' },
    { href: '/content', name: 'Content' },
    { href: '/pricing', name: 'Pricing' },
  ];

  const ProfileProps = [{ href: `/userProfile/${user?.id}`, name: 'Profile' }];

  const nestedMenu = [
    { href: '/dashboard/marketplace', name: 'Products & Services', nested: true },
    { href: '/dashboard/feed', name: 'Hire experts', nested: true },
    { href: '/dashboard/feed', name: 'Carbon offset projects', nested: true },
    { href: '/dashboard/feed', name: 'Exchange', nested: true },
  ];

  const logOut = () => {
    logoutUserHandler(logoutUser);
  };

  const handleClick = (event, name) => {
    event.preventDefault();
  };

  const Close = () => {
    onClose();
  };

  if (visible === false) return null;

  if (visible === true)
    return (
      <div className={styles.modal}>
        <div className={styles.modal_dialog} id={1} onClick={(event) => event.stopPropagation()}>
          <div>
            <div className={styles.user}>
              <div className={styles.userInfo}>
                <div className={styles.name}>{`${user.first_name} ${user.last_name}`}</div>
                {user.invited_by_name && (
                  <div className={styles.invite}>invited by {user.invited_by_name}</div>
                )}
                {user.ceo_of && (
                  <div className={styles.ceoRow}>
                    <img src="/images/CEO.png" alt="Ceo" />
                    <div className={styles.ceo}>{user.ceo_of}</div>
                  </div>
                )}
              </div>
              <div className={styles.avatarRow}>
                <AppAvatar
                  nav={0}
                  userName={`${user.first_name} ${user.last_name}`}
                  role={user.role_name}
                  src={user.avatar ? user.avatar : '/images/navbar/Avatar.png'}
                />
              </div>
            </div>
            <div className={styles.linksRow}>
              <div className={styles.ProfileLinks}>
                {ProfileProps.map((link, index) => (
                  <React.Fragment key={index}>
                    {link.name !== 'Business account' ? (
                      <div className={styles.linksRow}>
                        <Link
                          color="inherit"
                          underline="none"
                          to={link.href}
                          className={styles.links}
                        >
                          {link.name}
                        </Link>
                      </div>
                    ) : (
                      <div className={styles.LinkRowBusiness}>
                        <Link
                          color="inherit"
                          underline="none"
                          to={link.href}
                          className={styles.links}
                        >
                          {link.name}
                        </Link>
                        <img className={styles.businessImg} src="/Business.png" alt="business" />
                      </div>
                    )}
                  </React.Fragment>
                ))}
                <div className={styles.links} style={{ color: '#D32F2F' }} onClick={logOut}>
                  Log out
                </div>
              </div>
              {otherProps.map((link) => (
                <div key={`${link.name}`}>
                  {link?.isNotIndividual && isIndividual ? null : link?.parent ? (
                    <div className={styles.parent}>
                      <Link
                        color="inherit"
                        underline="none"
                        onClick={handleClick}
                        to={link.href}
                        className={styles.links}
                      >
                        {link.name}
                      </Link>
                      {nestedMenu.map((item) => (
                        <Link
                          key={item.name}
                          color="inherit"
                          underline="none"
                          onClick={() => Close()}
                          to={link.href}
                          className={styles.nestedLink}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.linksRow}>
                      <Link
                        color="inherit"
                        underline="none"
                        to={link.href}
                        className={styles.links}
                        onClick={() => Close()}
                      >
                        {link.name}
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
};
