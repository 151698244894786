import React, { useEffect, useState } from 'react';
import { IconButton, Modal } from '@mui/material';
import styles from './LocationModal.module.scss';
import { Map } from '../../modules/tt-map';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';

const DEFAULT_CENTER = { lat: 38.907132, lng: -77.036546 };
const DEFAULT_ADDRESS = 'WASHINGTON - Scott Oval Park, 1600 N St NW, Washington, DC 20036, USA';
const DEFAULT_LOCATION = {
  country: 'United States',
  country_code: 'US',
  locality: 'Washington',
  postcode: '20036',
};

const LocationModal = ({
  title,
  isModelOpen,
  handleClose,
  handleApply,
  coordinatesValue = DEFAULT_CENTER,
  isEditable = true,
  showSearch = true,
  zoom = 8,
}) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const [locationData, setLocationData] = useState({});
  const [coordinates, setCoordinates] = useState({});
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (!locationData && !address) {
      setAddress(DEFAULT_ADDRESS);
      setLocationData(DEFAULT_LOCATION);
    }
  }, []);

  useEffect(() => {
    setCoordinates(coordinatesValue);
  }, [coordinatesValue]);

  const onCancelClick = () => {
    handleClose();
    setCoordinates(coordinatesValue);
    setAddress('');
    setLocationData({});
  };

  const onApplyClick = () => {
    handleApply({ address: address, locationData: locationData, coordinates: coordinates });
    handleClose();
  };

  return (
    <Modal
      BackdropProps={{
        style: {
          backgroundColor: 'white',
          backdropFilter: 'blur(14px)',
          background: 'rgba(250, 250, 250, 0.8)',
        },
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isModelOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className={styles.mapModal}>
        <Box className={styles.modalTitle}>
          {title}
          <IconButton aria-label="close" onClick={handleClose} className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.mapWrapper}>
          <Map
            coordinates={coordinates}
            address={address}
            getCoordinates={setCoordinates}
            getAddress={setAddress}
            getAddressData={setLocationData}
            apiKey={mapApiKey}
            isEditable={isEditable}
            showSearch={showSearch}
            zoom={zoom}
          />
        </Box>
        {isEditable ? (
          <Box className={styles.buttonsWrapper}>
            <ButtonBase className={styles.firstBtn} type="button" onClick={onCancelClick}>
              Cancel
            </ButtonBase>
            <ButtonBase
              className={styles.secondBtn}
              type="submit"
              disabled={!address}
              onClick={onApplyClick}
            >
              Apply
            </ButtonBase>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

export default LocationModal;
