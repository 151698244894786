import styles from './ContactsTab.module.scss';
import React from 'react';
import Image from '../../../../../../components/Image';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActionMenu from './ActionMenu';

const rows = [
  {
    phone: '+38 (066) 666 66 66 ',
    contractor: 'Amelia ',
    date: '01.02.2023',
    email: 'amelia@gmail.com',
    size: '1.5 MB',
  },
  {
    phone: '+38 (066) 666 66 66 ',
    contractor: 'Angelina',
    date: '01.02.2023',
    email: 'amelia@gmail.com',
    size: '1.5 MB',
  },
  {
    phone: '+38 (066) 666 66 66 ',
    contractor: 'Amelia ',
    date: '01.02.2023',
    email: 'amelia@gmail.com',
    size: '1.5 MB',
  },
  {
    phone: '+38 (066) 666 66 66 ',
    contractor: 'Angelina',
    date: '01.02.2023',
    email: 'amelia@gmail.com',
    size: '1.5 MB',
  },
  {
    phone: '+38 (066) 666 66 66 ',
    contractor: 'Amelia ',
    date: '01.02.2023',
    email: 'amelia@gmail.com',
    size: '1.5 MB',
  },
  {
    phone: '+38 (066) 666 66 66 ',
    contractor: 'Angelina',
    date: '01.02.2023',
    email: 'amelia@gmail.com',
    size: '1.5 MB',
  },
];

const ContactsTab = () => {
  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: 32, boxShadow: 'none' }}
      sx={{ width: '100%' }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 'max-content' }}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableTitle}>Contractor</TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Date
            </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Email address
            </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Phone number
            </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Size
            </TableCell>
            <TableCell className={styles.tableTitle} align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={styles.tableTitle} component="th" scope="row">
                {row.contractor}
              </TableCell>
              <TableCell className={styles.tableCellGray} align="left">
                {row.date}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.email}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.phone}
              </TableCell>
              <TableCell className={styles.tableCell} align="left">
                {row.size}
              </TableCell>
              <TableCell align="right">
                <div className={styles.tableCellActions}>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/edit-gray.svg'} width={11.39} height={11.39} />
                  </button>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/saved.svg'} width={11.67} height={15} />
                  </button>
                  <ActionMenu />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ContactsTab;
