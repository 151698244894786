import { AccountCircle } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import styles from './profilAva.module.scss';
import { Link } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    success: {
      //       light: "rgb(67, 219, 71)",
      main: '#14d21a',
      //       dark: "rgb(14, 147, 18)",
      //       contrastText: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      //       light: "#ff7961",
      main: '#7e57c2',
      //       dark: "#ba000d",
      //       contrastText: "#000",
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Montserrat',
    },
    // fontFamily: `"Roboto", "Helvetica", "Montserrat", "Arial", sans-serif`,
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
  },
});

export const ProfilAva = (props) => {
  const img = props.img ? (
    <img {...props.img} title="" alt="" height="63.33" />
  ) : (
    <AccountCircle sx={{ fontSize: 63.33 }}></AccountCircle>
  );
  const button = (
    <button style={{ color: props.textColor ?? undefined }} className={styles.btn}>
      {props.text}
    </button>
  );

  return (
    <div className={styles.navbarLogoLeft}>
      <Link to="/">
        <>
          {!props.disableBadge ? (
            <Badge badgeContent={''} overlap="circular" color="success">
              {img}
              {button}
            </Badge>
          ) : (
            <>
              {img}
              {button}
            </>
          )}
        </>
      </Link>
    </div>
  );
};
