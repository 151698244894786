import React from 'react';
import Box from '@mui/material/Box';

const PresentationsTab = () => {
  return (
    <>
      <Box />
    </>
  );
};

export default PresentationsTab;
