import React from 'react';
import UserState from 'context/User/UserState';
import NotificationState from 'context/Notification/NotificationState';
import { AppState } from 'context';

const MainContextProvider = ({ children }) => (
  <AppState>
    <UserState>
      <NotificationState>{children}</NotificationState>
    </UserState>
  </AppState>
);

export default MainContextProvider;
