import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, TrashIcon, Typography } from 'tt-ui-kit';
import Box from '@mui/material/Box';
import styles from './RequestCustomizationModal.module.scss';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField/TextField';
import NumberMaskInput from '../../../NumberMaskInput';
import Image from '../../../Image';
import ConfirmCustomizationModal from '../ConfirmCustomizationModal';
import { CREATE_CUSTOMIZATION_REQUEST } from '../../../../api';
import { useMutation } from '@apollo/client';
import userContext from '../../../../context/User/userContext';
import { openNotification } from 'tt-ui-lib/core';

const PERIOD_DATE = [
  {
    id: 1,
    name: 'Days',
  },
  {
    id: 2,
    name: 'Weeks',
  },
  {
    id: 3,
    name: 'Months',
  },
  {
    id: 4,
    name: 'Years',
  },
];

const RequestCustomizationModal = ({
  selectedCurrency,
  openCustomizationModal,
  customizationModalActions,
  methods,
  setService,
  service,
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [createCustomization] = useMutation(CREATE_CUSTOMIZATION_REQUEST);
  const { user } = useContext(userContext);
  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'milestones',
    keyName: 'key',
  });

  const formValues = watch();

  useEffect(() => {
    if (Object.keys(initialValues).length > 0) {
      let isFieldChanged;
      Object.keys(formValues).map((formKey, index) => {
        if (!isFieldChanged) {
          const foundInitial = initialValues[formKey];
          if (foundInitial) {
            if (formKey === 'milestones') {
              const formMilestones = formValues[formKey];
              const initialMilestones = initialValues[formKey];
              if (formMilestones.length !== initialMilestones.length) {
                isFieldChanged = true;
              } else {
                isFieldChanged = !formMilestones.every((obj1) => {
                  return initialMilestones.some((obj2) => {
                    return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
                  });
                });
              }
            } else {
              isFieldChanged = formValues[formKey] !== initialValues[formKey];
            }
          }
        }
      });
      setConfirmDisable(!isFieldChanged);
    }
  }, [formValues]);

  useEffect(() => {
    if (service.id) {
      let basicPriceValue = service.basic_price || service.hourly_rate;
      if (service.performance_term_period && service.performance_term_period_type) {
        setValue('performance_term_period', service.performance_term_period);
        setValue('performance_term_period_type', service.performance_term_period_type);
      }
      if (basicPriceValue) {
        setValue('basic_price', basicPriceValue);
      }
      if (service.milestones?.length > 0) {
        service.milestones.forEach((milestone) =>
          append({
            id: milestone.id,
            period: milestone.period,
            price: milestone.price,
            period_type: milestone.period_type,
            description: milestone.description,
          })
        );
      }

      setInitialValues({
        performance_term_period: service.performance_term_period,
        performance_term_period_type: service.performance_term_period_type,
        basic_price: basicPriceValue,
        milestones: service.milestones?.length
          ? service.milestones.map((milestone) => {
              return {
                id: milestone.id,
                period: milestone.period,
                price: milestone.price,
                period_type: milestone.period_type,
                description: milestone.description,
              };
            })
          : [],
      });
    }
  }, [service]);

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    const inputData = {
      service_id: service.id,
      customer_id: user.id,
      customer_period_type: data.performance_term_period_type,
      customer_period: data.performance_term_period,
      customer_price: data.basic_price,
      previous_period_type: service.performance_term_period_type,
      previous_period: service.performance_term_period,
      previous_price: service.basic_price ?? service.hourly_rate,
      comment: data.comment,
      milestones: data.milestones.map((item, index) => {
        const { description, ...rest } = item;
        return {
          ...rest,
          previous_period_type:
            service.milestones && service.milestones[index]
              ? service.milestones[index].period_type
              : null,
          previous_period:
            service.milestones && service.milestones[index]
              ? service.milestones[index].period
              : null,
          previous_price:
            service.milestones && service.milestones[index]
              ? service.milestones[index].price
              : null,
        };
      }),
    };
    const response = await createCustomization({ variables: { input: inputData } });
    if (response.data && response.data && response.data.createCustomization) {
      customizationModalActions('close');
      setService((current) => ({
        ...current,
        customization: response.data.createCustomization,
      }));
      setOpenConfirmModal(false);
      openNotification({
        type: 'success',
        message: 'Customization request send successfully.',
      });
    }
  };

  useEffect(() => {
    return () => {
      reset({
        milestones: [],
      });
    };
  }, [reset]);

  const addMilestone = () => {
    append({ period: '', price: undefined, period_type: 'Days' });
  };

  const handleValidation = async () => {
    const result = await trigger();
    if (result) {
      setOpenConfirmModal(true);
    }
  };

  const handleInputChange = (field, value) => {
    if (value) {
      clearErrors(field);
    }
  };

  return (
    <Modal
      open={openCustomizationModal}
      onClose={() => customizationModalActions('close')}
      onClick={(e) => e.stopPropagation()}
      title={'Customization Request'}
      className={styles.requestCustomizationModal}
    >
      <form style={{ width: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography className={styles.description}>
          You can suggest modifications to the terms of the services/project offered by{' '}
          {service.company.company_name}. Please choose one or more terms you wish to modify.
        </Typography>
        <Box className={styles.formContent}>
          <Box className={styles.formData}>
            <Box className={styles.forDataTitle}>
              <Typography className={styles.startDate}>Project start date&nbsp;</Typography>
              <Typography className={styles.dateOfPayment}>- date of payment</Typography>
            </Box>
          </Box>
          <Box className={styles.perfomanceOfServiceBlock}>
            <Typography className={styles.perfomanceOfService}>
              Term for the perfomance of service
            </Typography>
            <TextField
              {...register('performance_term_period', {
                onChange: (e) => handleInputChange('performance_term_period', e.target.value),
                value: null,
                required: 'Please fill out the rest fields. This is mandatory.',
                valueAsNumber: true,
              })}
              className={styles.inputSelect}
              error={!!errors?.performance_term_period}
              helperText={errors?.performance_term_period?.message}
              label="Period"
              data-type="unit_value"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Controller
                      defaultValue={PERIOD_DATE[0].name}
                      name={'performance_term_period_type'}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Select
                            defaultValue={false}
                            onChange={onChange}
                            value={value}
                            style={{ display: 'flex' }}
                            sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                border: '0 !important',
                              },
                            }}
                            renderValue={(value) =>
                              value ? (
                                <Box style={{ display: 'flex' }}>
                                  <Typography className={styles.periodValue}>{value}</Typography>
                                </Box>
                              ) : (
                                <Typography className={styles.periodValue}>Days</Typography>
                              )
                            }
                          >
                            {PERIOD_DATE.map((item, index) => (
                              <MenuItem key={index} value={item.name}>
                                <Typography className={styles.dateItems}>{item.name}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <NumberMaskInput
            {...register('basic_price', {
              onChange: (e) => handleInputChange('basic_price', e.target.value),
              required: 'Please fill out the rest fields. This is mandatory.',
              pattern: {
                value: /^(?:[1-9]\d{0,5}(?:\.\d{1,2})?|0\.\d{1,2}|999999\.99)$/,
                message: 'Please enter a number between 0.1 and 999999.99.',
              },
            })}
            error={!!errors?.basic_price}
            helperText={errors?.basic_price?.message}
            onChange={(e) => {
              handleInputChange('basic_price', e.target.value);
              setValue('basic_price', +e.target.value !== 0 ? +e.target.value : null);
            }}
            value={watch('basic_price')}
            className={styles.inputSelect}
            label="Basic Price"
            data-type="basic_price"
            InputProps={{
              inputProps: {
                min: 0,
                step: 'any',
                onWheel: (e) => {
                  e.target.blur();
                },
              },
              endAdornment: (
                <InputAdornment position="start">
                  <Box className={styles.inputUnitText}>{selectedCurrency.code}</Box>
                </InputAdornment>
              ),
            }}
          />
          {fields &&
            fields.length > 0 &&
            fields.map((item, index) => (
              <Box className={styles.milestoneFields} key={item.key}>
                <Typography className={styles.milestoneLabel}>Milestone {index + 1}</Typography>
                <TextField
                  {...register(`milestones[${index}].period`, {
                    onChange: (e) =>
                      handleInputChange(`milestones[${index}].period`, e.target.value),
                    required: 'Please fill out the rest fields. This is mandatory.',
                    value: null,
                    valueAsNumber: true,
                  })}
                  error={!!errors.milestones && !!errors.milestones[index]?.period}
                  helperText={errors.milestones && errors.milestones[index]?.period?.message}
                  className={styles.inputSelect}
                  label="Period"
                  data-type="unit_value"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Controller
                          defaultValue={PERIOD_DATE[0].name}
                          name={`milestones[${index}].period_type`}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Select
                                defaultValue={false}
                                onChange={onChange}
                                value={value}
                                style={{ display: 'flex' }}
                                sx={{
                                  '.MuiOutlinedInput-notchedOutline': {
                                    border: '0 !important',
                                  },
                                }}
                                renderValue={(value) =>
                                  value ? (
                                    <Box style={{ display: 'flex' }}>
                                      <Typography className={styles.periodValue}>
                                        {value}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <Typography className={styles.periodValue}>Days</Typography>
                                  )
                                }
                              >
                                {PERIOD_DATE.map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    <Typography className={styles.dateItems}>
                                      {item.name}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <NumberMaskInput
                  {...register(`milestones[${index}].price`, {
                    required: 'Please fill out the rest fields. This is mandatory.',
                    pattern: {
                      value: /^(?:[1-9]\d{0,5}(?:\.\d{1,2})?|0\.\d{1,2}|999999\.99)$/,
                      message: 'Please enter a number between 0.1 and 999999.99.',
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(`milestones[${index}].price`, e.target.value);
                    setValue(
                      `milestones[${index}].price`,
                      +e.target.value !== 0 ? +e.target.value : null
                    );
                  }}
                  key={item.key}
                  value={watch(`milestones[${index}].price`)}
                  className={styles.inputSelect}
                  error={!!errors?.milestones && !!errors?.milestones[index]?.price}
                  helperText={errors.milestones && errors.milestones[index]?.price?.message}
                  label="Price"
                  data-type="price"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 'any',
                      onWheel: (e) => {
                        e.target.blur();
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box className={styles.inputUnitText}>{selectedCurrency.code}</Box>
                      </InputAdornment>
                    ),
                  }}
                />
                {!item.id && (
                  <Button type="link" className={styles.removeBtn} onClick={() => remove(index)}>
                    <TrashIcon style={{ width: 24, height: 24 }} />
                  </Button>
                )}
              </Box>
            ))}
          <Button type={'link'} className={styles.addInfoBtn} onClick={addMilestone}>
            <Image src="/images/plus-blue.svg" width={16} height={24} />
            <Typography className={styles.addInfoText}>Add Milestone</Typography>
          </Button>
          <TextField
            {...register('comment', {
              value: '',
              pattern: {
                value: /^[\s\S]{1,3000}$/,
                message: "You can't write more than 3000 characters",
              },
            })}
            multiline
            inputProps={{ style: { resize: 'vertical' } }}
            className={styles.textarea}
            FormHelperTextProps={{
              style: {},
            }}
            error={!!errors.comment}
            helperText={errors.comment?.message}
            sx={{ marginBottom: '12px', marginTop: '8px' }}
            label="Comment"
          />
        </Box>
        <Box className={styles.formButtons}>
          <Button
            className={styles.formButton}
            onClick={() => customizationModalActions('close')}
            type="mainButton"
          >
            CHANCEL
          </Button>
          <Button
            onClick={handleValidation}
            className={styles.formButton}
            type="primary"
            disabled={confirmDisable}
          >
            CONFIRM
          </Button>
        </Box>
        {openConfirmModal && (
          <ConfirmCustomizationModal
            openConfirmModal={openConfirmModal}
            setOpenConfirmModal={setOpenConfirmModal}
            onSubmit={() => {
              methods.handleSubmit(onSubmit)();
            }}
            serviceData={service}
            customerData={getValues()}
            submitLoading={submitLoading}
          />
        )}
      </form>
    </Modal>
  );
};

export default RequestCustomizationModal;
