import React from 'react';
import styles from './communityPage.module.scss';
import Box from '@mui/material/Box';
import { CommunityBody } from './communityBody';

export const CommunityPage = ({
  setOpen,
  save,
  saveButton = false,
  saveButtonText = 'SAVE AND PROCEED',
}) => {
  return (
    <>
      <Box className={styles.communityDiv}>
        <Box className={styles.communityTitle}>
          You are responsible for the accuracy and validity of the data provided.
        </Box>
        <Box className={styles.communityText}>
          This questionnaire should help your partners, buyers, and future ESG investors evaluate
          your contribution to the SDGs.
        </Box>
        <Box className={styles.communityText}>
          Tell us about your business objectives. With this information, the community will be able
          to collaborate with you to achieve TransparenTerra&apos;s global goals.
        </Box>
        <CommunityBody />
      </Box>
      <Box className={styles.buttons}>
        <button className={styles.buttonCancel} onClick={() => setOpen(false)}>
          CANCEL
        </button>
        {saveButton && (
          <button
            className={styles.buttonNextCommunity}
            onClick={() => {
              save();
              setOpen(false);
            }}
          >
            {saveButtonText}
          </button>
        )}
      </Box>
    </>
  );
};
