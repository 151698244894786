import { DocumentsUploadAccount } from './documentsUploadAccount';
import styles from './documentsUploadPage.module.scss';
import { HelpTooltip } from 'tt-ui-kit';
import React from 'react';

export const DocumentsUploadForm = ({ setCategories, serviceData, categories }) => {
  return (
    <>
      <div className={styles.documentsBody}>
        <div className={styles.documentsText}>
          <div className={styles.title}>About company</div>
          <HelpTooltip tooltipText="This is public information" />
        </div>
        <DocumentsUploadAccount
          setCategories={setCategories}
          categories={categories}
          serviceData={serviceData}
        />
      </div>
    </>
  );
};
