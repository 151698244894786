import React, { useEffect, useState } from 'react';
import CarbonItemPage from '../../components/pages/CarbonItemPage';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { SHOW_CARBON_CREDIT } from '../../api';
import Cookies from 'js-cookie';

export const CarbonItemPreview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [carbon, setCarbon] = useState({});
  const [showCarbonCredit] = useLazyQuery(SHOW_CARBON_CREDIT);
  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });
  const marketId = Cookies.get('marketId', { domain: domainValue });

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (userId) {
        const res = await showCarbonCredit({
          variables: { id: id },
          fetchPolicy: 'network-only',
        });
        if (
          res.data &&
          res.data.showCarbonCreditById &&
          (res.data.showCarbonCreditById.user_id === userId ||
            res.data.showCarbonCreditById.user_id === marketId)
        ) {
          setCarbon(res.data.showCarbonCreditById);
        } else {
          navigate(`/dashboard/credit/${id}`);
        }
        setLoading(false);
      } else {
        navigate(`/dashboard/credit/${id}`);
      }
    })();
  }, []);

  return (
    <CarbonItemPage
      carbon={carbon}
      isSeller={true}
      loading={loading}
      setLoading={(e) => setLoading(e)}
    />
  );
};
