import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import styles from './investPage.module.scss';
import { InvestAccount } from './investAccount';
import { useFormContext, Controller } from 'react-hook-form';
import userContext from '../../../../../context/User/userContext';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

export const InvestForm = ({ companyData }) => {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);
  const { user } = useContext(userContext);

  return (
    <>
      <div className={styles.investDiv}>
        <div className={styles.investText}>Open for investments.</div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div className={styles.investColumnLeft}>
            <FormGroup>
              <Controller
                name={'openForInvestment'}
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <>
                    <FormControlLabel
                      style={{
                        display: 'flex',
                        color: '#C4C4C4;',
                      }}
                      disabled={
                        (!companyData || (companyData && !companyData.kyb_completed)) &&
                        (!user || (user && !user.calculator_id))
                      }
                      control={
                        <Checkbox
                          checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#212121' }} />}
                          onChange={onChange}
                          checked={value}
                          onClick={() => setOpen(!open)}
                          sx={{
                            fontFamily: 'Montserrat',
                            color: '#C4C4C4',
                            '&.Mui-checked': {
                              color: 'primary',
                            },
                          }}
                        />
                      }
                      label={'Open for investment'}
                    />
                  </>
                )}
              />
            </FormGroup>
          </div>
        </div>
        {open && <InvestAccount />}
      </div>
    </>
  );
};
