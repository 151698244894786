import { gql } from '@apollo/client';

export const TOKEN = gql`
  fragment TokenFields on AuthPayload {
    access_token
    refresh_token
    expires_in
    token_type
  }
`;
