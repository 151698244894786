import React, { useState } from 'react';
import styles from './DeleteCompanyModal.module.scss';
import { Button, LoaderOn, Modal } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import { DELETE_COMPANY } from '../../../api';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const DeleteCompanyModal = ({ open, setOpen, id, companyName }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const [deleteCompany] = useMutation(DELETE_COMPANY);

  const closeModal = () => {
    setOpen(false);
  };

  const confirmDelete = () => {
    (async () => {
      setIsLoading(true);

      const res = await deleteCompany({
        variables: { id: id },
      });

      if (res?.data?.deleteCompany) {
        const fromActiveTabSearchParams = searchParams.get('fromActiveTab');
        const fromActiveChildTabSearchParams = searchParams.get('fromActiveChildTab');
        let link = '/dashboard/companiesList';
        if (fromActiveTabSearchParams && fromActiveChildTabSearchParams) {
          link = `${link}?activeTab=${fromActiveTabSearchParams}&activeChildTab=${fromActiveChildTabSearchParams}`;
        }
        openNotification({
          type: 'success',
          message: 'Company was successfully deleted!',
        });
        navigate(link);
        window.location.reload();
      } else {
        openNotification({
          type: 'error',
          message: 'Error while deleting company, try again later',
        });
      }

      setIsLoading(false);
    })();
  };

  return (
    <Modal
      title={`Do you really want to delete ${companyName} profile? `}
      open={open}
      onClose={closeModal}
      className={styles.body}
      closeOnlyByControls
    >
      {isLoading && <LoaderOn />}
      <div className={styles.main}>
        <div className={styles.text}>
          We are sorry to see you go! <br />
          All {companyName} data will be removed from TransparenTerra. If you sure you want to
          delete {companyName} profile, please confirm
          {/*enter the confirmation code sent to your email address*/}
        </div>
        {/*<div className={styles.inputWrapper}>*/}
        {/*  <PartInput code={confirmCode} setCode={setConfirmCode} />*/}
        {/*  <div>Refresh code</div>*/}
        {/*</div>*/}
      </div>

      <div className={styles.buttons}>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          type="primary"
          // disabled={!confirmCode}
          onClick={confirmDelete}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteCompanyModal;
