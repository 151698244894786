import { Box, Typography } from '@mui/material';
import styles from './ProposalsTab.module.scss';
import React, { useEffect, useState } from 'react';
import Image from '../../../../components/Image';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import { ProductCard } from '../../../../components/pages/ProductsPage/ProductCard';
import ButtonBase from '@mui/material/ButtonBase';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  GET_CARBON_PRODUCTS,
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_ALL,
  GET_CARBON_PRODUCTS_ALL,
} from '../../../../api';
import { SignInUpModalWrapper } from '../../../../components/Modals/SignInUpModal/SignInUpModal';

const ProposalsTab = ({ companyId, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getShopProducts] = useLazyQuery(GET_SHOP_PRODUCTS);
  const [getShopProductsAll] = useLazyQuery(GET_SHOP_PRODUCTS_ALL);
  const [getShopCredits] = useLazyQuery(GET_CARBON_PRODUCTS);
  const [getShopCreditsAll] = useLazyQuery(GET_CARBON_PRODUCTS_ALL);
  const [products, setProducts] = useState([]);
  const [credits, setCredits] = useState([]);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };
  const handleLoginClick = (event) => {
    event.preventDefault();
    setAnchorLoginEl(event.currentTarget);

    event.stopPropagation();
  };
  const getProductsList = async () => {
    if (user.roles_list && JSON.parse(user.roles_list).includes('admin')) {
      const res = await getShopProductsAll({
        variables: {
          input: {
            company_id: companyId,
          },
          first: 10,
          page: 1,
        },
      });
      if (
        res.data &&
        res.data.getAllShopProductsByWithPagination &&
        res.data.getAllShopProductsByWithPagination.data.length
      ) {
        setProducts(res.data.getAllShopProductsByWithPagination.data);
      }
    } else {
      const res = await getShopProducts({
        variables: {
          input: {
            company_id: companyId,
          },
          first: 10,
          page: 1,
        },
      });
      if (
        res.data &&
        res.data.getShopProductsByWithPagination &&
        res.data.getShopProductsByWithPagination.data.length
      ) {
        setProducts(res.data.getShopProductsByWithPagination.data);
      }
    }
  };

  const getCreditsList = async () => {
    if (user.roles_list && JSON.parse(user.roles_list).includes('admin')) {
      const res = await getShopCreditsAll({
        variables: {
          input: {
            company_id: companyId,
          },
          first: 10,
          page: 1,
        },
      });
      if (
        res.data &&
        res.data.getAllCarbonCreditsByWithPagination &&
        res.data.getAllCarbonCreditsByWithPagination.data.length
      ) {
        setCredits(res.data.getAllCarbonCreditsByWithPagination.data);
      }
    } else {
      const res = await getShopCredits({
        variables: {
          input: {
            company_id: companyId,
          },
          first: 10,
          page: 1,
        },
      });
      if (
        res.data &&
        res.data.getCarbonCreditsByWithPagination &&
        res.data.getCarbonCreditsByWithPagination.data.length
      ) {
        setCredits(res.data.getCarbonCreditsByWithPagination.data);
      }
    }
  };

  useEffect(() => {
    const scrollBlock = document.getElementById('scroll-block-product');
    checkScrollable(scrollBlock, 'scroll-block-product-right-button');
  }, [products]);

  useEffect(() => {
    const scrollBlock = document.getElementById('scroll-block-credit');
    checkScrollable(scrollBlock, 'scroll-block-credit-right-button');
  }, [credits]);

  const checkScrollable = (block, buttonId) => {
    if (block) {
      const isScrollable = block.scrollWidth > block.clientWidth;
      const scrollRight = document.getElementById(buttonId);
      if (isScrollable) {
        scrollRight.style.display = 'block';
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getProductsList();
      await getCreditsList();
    })();
  }, []);

  const scrollToLeft = (elementId) => {
    const scrollBlock = document.getElementById(elementId);
    if (scrollBlock) {
      const scrollHorizontal = scrollBlock.scrollLeft - 440;
      scrollBlock.scroll({
        left: scrollHorizontal,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = (elementId) => {
    const scrollBlock = document.getElementById(elementId);
    if (scrollBlock) {
      const scrollHorizontal = scrollBlock.scrollLeft + 440;
      scrollBlock.scroll({
        left: scrollHorizontal,
        behavior: 'smooth',
      });
    }
  };

  const viewAll = (type) => {
    navigate(`/dashboard/company/${id}/store?activeTab=${type}`);
  };

  const handleScroll = (elementId) => {
    if (products) {
      const block = document.getElementById(`scroll-block-${elementId}`);
      const scrollLeft = block.scrollLeft;
      const scrollWidth = block.scrollWidth;
      const offsetWidth = block.offsetWidth;
      const scrollLeftButton = document.getElementById(`scroll-block-${elementId}-left-button`);
      const scrollRightButton = document.getElementById(`scroll-block-${elementId}-right-button`);

      if (scrollLeft <= 0) {
        scrollLeftButton.style.display = 'none';
      } else {
        scrollLeftButton.style.display = 'block';
      }
      if (offsetWidth + scrollLeft >= scrollWidth) {
        scrollRightButton.style.display = 'none';
      } else {
        scrollRightButton.style.display = 'block';
      }
    }
  };

  const openProduct = (productId) => {
    navigate(`/dashboard/product/${productId}?from=proposals`);
  };

  return (
    <Box>
      {products && products.length > 0 && (
        <Box className={styles.container}>
          <Box className={styles.title}>
            Products
            <Box className={styles.viewAllBlock} onClick={() => viewAll('products')}>
              <Typography class={styles.viewAllText}>View all</Typography>
              <Image src={'/images/company/arrowRight.svg'} width={7.85} height={13.33} />
            </Box>
          </Box>
          <Box className={styles.scrollContainer}>
            <ButtonBase
              id="scroll-block-product-left-button"
              onClick={() => scrollToLeft('scroll-block-product')}
              className={styles.leftSlide}
            >
              <ChevronLeftIcon />
            </ButtonBase>
            <ScrollContainer
              id="scroll-block-product"
              onScroll={() => handleScroll('product')}
              mouseScroll={{ buttons: [1] }}
            >
              <Box className={styles.productContainer}>
                {products.map((item, index) => (
                  <Box key={index} className={styles.productItem}>
                    <ProductCard
                      key={item.id}
                      existData={item}
                      pressCard={() => openProduct(item.id)}
                      isSeller={false}
                      user={user}
                      handleLoginClick={handleLoginClick}
                      middlePressCard={() => window.open(`/dashboard/product/${item.id}`, '_blank')}
                    />
                  </Box>
                ))}
              </Box>
            </ScrollContainer>
            <ButtonBase
              id="scroll-block-product-right-button"
              className={styles.rightSlide}
              onClick={() => scrollToRight('scroll-block-product')}
            >
              <ChevronRightIcon />
            </ButtonBase>
          </Box>
        </Box>
      )}
      {credits && credits.length > 0 && (
        <Box className={styles.container}>
          <Box className={styles.title}>
            Carbon credits
            <Box className={styles.viewAllBlock} onClick={() => viewAll('carbon')}>
              <Typography>View all</Typography>
              <Image src={'/images/company/arrowRight.svg'} width={7.85} height={13.33} />
            </Box>
          </Box>
          <Box className={styles.scrollContainer}>
            <ButtonBase
              id="scroll-block-credit-left-button"
              onClick={() => scrollToLeft('scroll-block-credit')}
              className={styles.leftSlide}
            >
              <ChevronLeftIcon />
            </ButtonBase>
            <ScrollContainer
              id="scroll-block-credit"
              onScroll={() => handleScroll('credit')}
              mouseScroll={{ buttons: [1] }}
            >
              <Box className={styles.productContainer}>
                {credits.map((item, index) => (
                  <Box key={index} className={styles.productItem}>
                    <ProductCard
                      key={item.id}
                      existData={item}
                      pressCard={() => openProduct(item.id)}
                      isSeller={false}
                      user={user}
                    />
                  </Box>
                ))}
              </Box>
            </ScrollContainer>
            <ButtonBase
              id="scroll-block-credit-right-button"
              className={styles.rightSlide}
              onClick={() => scrollToRight('scroll-block-credit')}
            >
              <ChevronRightIcon />
            </ButtonBase>
          </Box>
        </Box>
      )}
      <SignInUpModalWrapper open={open} closeModal={handleLoginClose} />
    </Box>
  );
};
export default ProposalsTab;
