import React, { useEffect, useState } from 'react';
import { Button, HelpTooltip, LoaderOn, Modal, TextInput } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from './KybModal.module.scss';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  COMPANY_KYB_START_WITH_PAYMENT,
  GET_COMPANY_KYB_CHECK_PAYMENT_INFO,
  GET_SHORT_COMPANY_BY_ID,
} from '../../../api';
import { redirectToLink } from '../../../utils/appUtils';

const KybModal = ({ open, setOpen, mode = 'own', companyName = 'your company', companyId }) => {
  const kybCompanyUrl = `${process.env.REACT_APP_MARKET}/dashboard/company/${companyId}/edit/kyb`;

  const [kybOption, setKybOption] = useState('kyb_spider_check');
  const [isLoading, setIsLoading] = useState(false);
  const [kybPrice, setKybPrice] = useState('');
  const [kybPrices, setKybPrices] = useState([]);
  const [kybOptionsDisabled, setKybOptionsDisabled] = useState(true);

  const [startKybWithPayment] = useMutation(COMPANY_KYB_START_WITH_PAYMENT);
  const [getPaymentKybInfo] = useLazyQuery(GET_COMPANY_KYB_CHECK_PAYMENT_INFO, {
    fetchPolicy: 'network-only',
  });
  const [fetchCompanyData] = useLazyQuery(GET_SHORT_COMPANY_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const closeModal = () => {
    setOpen(false);
  };

  const getKybPriceInfo = () => {
    (async () => {
      setKybPrice('');
      setKybPrices([]);
      setIsLoading(true);

      const kybPayload = await getPaymentKybInfo({
        variables: {
          input: {
            isActive: true,
            lookup_key: [
              'kyb_spider_check',
              'kyb_spider_registry_check',
              'kyb_spider_edd_check',
              'kyb_spider_esg_check',
            ],
            payment_system: 'stripe',
          },
        },
      });

      const companyDataReq = await fetchCompanyData({
        variables: {
          id: companyId,
        },
      });

      if (companyDataReq?.data?.showCompanyById?.kyb_completed) {
        // TODO setKybOptionsDisabled(!companyDataReq?.data?.showCompanyById?.kyb_completed);
        setKybOptionsDisabled(true);
      } else if (kybPayload?.errors?.length > 0) {
        openNotification({
          type: 'error',
          message: kybPayload.errors[0].message || 'Error while fetching KYB data, try again later',
        });
        setIsLoading(false);
        return;
      }

      if (kybPayload?.data?.getPaymentSystemPrices) {
        setKybPrices(kybPayload.data?.getPaymentSystemPrices || []);
      } else if (kybPayload?.errors?.length > 0) {
        openNotification({
          type: 'error',
          message: kybPayload.errors[0].message || 'Error while fetching KYB data, try again later',
        });
      } else {
        openNotification({
          type: 'error',
          message: 'Error while fetching KYB data, try again later',
        });
      }

      setIsLoading(false);
    })();
  };

  const passKyb = () => {
    (async () => {
      setIsLoading(true);

      const kybStartPayload = await startKybWithPayment({
        variables: {
          input: {
            success_url: `${kybCompanyUrl}?checkingCreated=1`,
            cancel_url: `${kybCompanyUrl}?checkingCreated=0`,
            lookup_key: kybOption,
            company_id: companyId,
            payment_system: 'stripe',
          },
        },
      });

      if (kybStartPayload.errors) {
        openNotification({
          type: 'error',
          message: kybStartPayload.errors[0].message,
        });
      }
      if (kybStartPayload.data?.payAndStartKYBCheck?.url) {
        redirectToLink(kybStartPayload.data?.payAndStartKYBCheck?.url);
      }

      setIsLoading(false);
    })();
  };

  useEffect(() => {
    if (kybPrices.length > 0 && kybOption) {
      const selectedPrice = kybPrices.find((item) => item.lookup_key === kybOption);
      setKybPrice(`${selectedPrice?.price || ''} ${selectedPrice?.currency?.symbol || ''}`);
    }
  }, [kybPrices, kybOption]);

  useEffect(() => {
    if (open && kybOption) {
      getKybPriceInfo();
    }
  }, [open, kybOption]);

  return (
    <Modal
      title={`Pass KYB${mode === 'own' ? ` for ${companyName}` : ''}`}
      open={open}
      onClose={closeModal}
      className={styles.body}
      closeOnlyByControls
    >
      {isLoading && <LoaderOn />}

      {mode === 'external' && (
        <div className={styles.form}>
          <TextInput placeholder="Company name" />
          <div className={styles.row}>
            <TextInput placeholder="Country" />
            <TextInput placeholder="Tax number" />
          </div>
          <TextInput placeholder="Email" />
        </div>
      )}

      <div className={styles.radioContainer}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={kybOption}
          onChange={(e, val) => setKybOption(val)}
        >
          <div className={styles.blockRadio}>
            <FormControlLabel value="kyb_spider_check" control={<Radio />} label="KYC Check" />
            <HelpTooltip
              tooltipText={
                'KYC stands for "Know Your Client" and is an important tool for the financial sector, particularly with regard to compliance (sanctions lists, PEP status, CRIME, blacklists or watchlists)'
              }
            />
          </div>
          <div className={styles.blockRadio}>
            <FormControlLabel
              value="kyb_spider_registry_check"
              control={<Radio disabled={kybOptionsDisabled} />}
              label="Excerpt of the Registry of Commerce"
            />
            <HelpTooltip
              tooltipText={
                kybOptionsDisabled
                  ? ''
                  : 'Mention or relationship with natural persons, legal entities or countries on sanctions lists of SECO, EU, OFAC, as well as other compliance-relevant lists; Mention or relationship with politically exposed persons on international and Swiss PEP lists; Mention or relationship with persons on CRIME lists Reference to other compliance-relevant risks such as sector risk, country risk or CTRL-ORG.'
              }
              iconStyle={{ opacity: 0.5 }}
            />
          </div>
          <div className={styles.blockRadio}>
            <FormControlLabel
              value="kyb_spider_edd_check"
              control={<Radio disabled={kybOptionsDisabled} />}
              label="Enhanced Due Diligence (EDD) Assistant"
            />
            <HelpTooltip
              tooltipText={
                kybOptionsDisabled
                  ? ''
                  : 'The Deep Search function in the EDD Assistant is an autonomously acting, intelligent search application. Prior to the search, the objectives are entered and defined. In the case of Deep Search, these targets are risk criteria (e.g. organized crime or corruption), information sources (e.g. web or media database) and languages.'
              }
              iconStyle={{ opacity: 0.5 }}
            />
          </div>
          <div className={styles.blockRadio}>
            <FormControlLabel
              value="kyb_spider_esg_check"
              control={<Radio disabled={kybOptionsDisabled} />}
              label="ESG Due Diligence Assistant"
            />
            <HelpTooltip
              tooltipText={
                kybOptionsDisabled
                  ? ''
                  : 'The ESG Due Diligence Wizard is a tool for quickly and efficiently check whether your customers and business partners have relevant information and articles on ESG topics or risks.'
              }
              iconStyle={{ opacity: 0.5 }}
            />
          </div>
        </RadioGroup>
      </div>

      <div className={styles.buttons}>
        <Button onClick={closeModal}>Cancel</Button>
        <Button type="primary" disabled={!kybPrice} onClick={passKyb}>
          Pay {kybPrice} and pass KYB
        </Button>
      </div>
    </Modal>
  );
};

export default KybModal;
