import React from 'react';
import { useParams } from 'react-router-dom';

const CreateProjectCalc = () => {
  const { id } = useParams();
  window.location.replace(`${process.env.REACT_APP_ESG_CALC}/startCalculator/project/${id}`);
  return null;
};

const Community = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/community`);
  return null;
};

const GlobalHome = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/feed`);
  return null;
};

const Pricing = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/pricing`);
  return null;
};

const Messaging = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/messaging`);
  return null;
};

const SignIn = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/sign_in`);
  return null;
};

const Calculators = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/score_and_reporting`);
  return null;
};
const Marketplace = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/marketplace`);
  return null;
};
const DigitalAssets = () => {
  window.location.replace(`${process.env.REACT_APP_DIGITAL_ASSETS}/dashboard/digital_assets`);
  return null;
};
const Events = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/events`);
  return null;
};

const Financials = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/financials`);
  return null;
};

const Content = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/content`);
  return null;
};

const ChangeRole = () => {
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/change-role`);
  return null;
};

const UserProfile = () => {
  const { id } = useParams();
  window.location.replace(`${process.env.REACT_APP_TT}/dashboard/profile/${id}`);
  return null;
};

export {
  Community,
  GlobalHome,
  Pricing,
  Messaging,
  SignIn,
  Calculators,
  Marketplace,
  DigitalAssets,
  Events,
  Financials,
  Content,
  UserProfile,
  CreateProjectCalc,
  ChangeRole,
};
