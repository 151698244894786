import React, { useState, useRef, useEffect } from 'react';
import styles from './accountLogo.module.scss';
import axios from 'axios';
import { v1 as uuidv1 } from 'uuid';
import SelectComponent from './Select';
import { useLazyQuery } from '@apollo/client';
import {
  GET_LEGAL_TYPES,
  GET_INDUSTRY_GROUPS,
  GET_SUB_INDUSTRIES,
  GET_URL_FOR_PUBLIC_UPLOAD,
} from '../../../api';
import { Controller } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { AvatarCropModal } from '../../../modules/tt-modals';

export const AccountLogo = ({
  errors,
  register,
  control,
  getValues,
  setValue,
  setFile,
  companyInfo,
  file = null,
}) => {
  const [logo, setLogo] = useState(null);
  const hiddenFileInput = useRef(null);
  const [industryGroups, setIndustryGroups] = useState([]);
  const [subIndustries, setSubIndustries] = useState([]);
  const [legalType, setLegalType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedSubIndustry, setSelectedSubIndustry] = useState('');
  const [selectedLegalType, setSelectedLegalType] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [isCropImageModal, setIsCropImageModal] = useState(false);
  const [getLegalTypes] = useLazyQuery(GET_LEGAL_TYPES);
  const [getIndustryGroups] = useLazyQuery(GET_INDUSTRY_GROUPS);
  const [getSubIndustries] = useLazyQuery(GET_SUB_INDUSTRIES, {
    variables: {},
  });

  useEffect(() => {
    const logoPath = getValues('logo');
    if (logoPath) {
      setLogo({
        preview: logoPath?.preview || logoPath,
      });
    } else if (file) {
      setLogo({
        preview: file?.preview || file.split('?')[0],
      });
    }
  }, [file]);

  const [fileToS3, setFileToS3] = useState(null);

  const [getUrlForPublicUpload] = useLazyQuery(GET_URL_FOR_PUBLIC_UPLOAD, {
    variables: { input: { fileToS3 } },
  });

  const uploadFile = async (fileValue) => {
    try {
      const formatFile = fileValue?.name.substr(-4, 4);
      let filesName = '';
      if (formatFile === '.') {
        filesName = `${uuidv1()}.${fileValue?.name}${formatFile}`;
      } else {
        filesName = `${uuidv1()}.${fileValue?.name}`;
      }
      const fileData = {
        originalName: fileValue.name,
        uuIdName: filesName,
        extension: formatFile[0] === '.' ? formatFile.slice(1) : formatFile,
        size: fileValue.size,
        type: fileValue.type,
      };
      setFile(fileData);
      const type = 'company_logo_main';
      const toUpload = { type: type, name: filesName };
      setFileToS3(toUpload);
      const { data } = await getUrlForPublicUpload({
        variables: { input: toUpload },
      });
      setFileToS3(null);
      const urlToUpload = data?.getUrlForPublicUpload?.url;

      const putData = await axios.put(urlToUpload, fileValue, {
        headers: {
          'Content-type': fileData.type,
          'Access-Control-Allow-Origin': '*',
        },
      });
    } catch (error) {
      console.log('error !!!', error);
    }
  };

  const getLegalTypeList = async () => {
    const res = await getLegalTypes();
    if (res.data && res.data.showCompanyLegalTypes) {
      setLegalType(res.data.showCompanyLegalTypes);
    }
  };

  const getIndustryGroupsList = async () => {
    const res = await getIndustryGroups();
    if (res.data && res.data.showIndustryGroups) {
      setIndustryGroups(res.data.showIndustryGroups);
    }
  };

  const getSubIndustriesList = async (params = '') => {
    const res = await getSubIndustries({
      variables: { input: { industrial_groups_id: params } },
    });
    if (res.data && res.data.showSubIndustries) {
      setSubIndustries(res.data.showSubIndustries);
    }
  };

  useEffect(() => {
    (async () => {
      await getLegalTypeList();
      await getIndustryGroupsList();
      await getSubIndustriesList();
    })();
  }, []);

  useEffect(() => {
    const industry = getValues('industry_group');
    if (industry) {
      setSelectedIndustry(industry);
      getSubIndustriesList(industry);
    }
  }, [selectedIndustry]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUpdateAvatar = async (file) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    setLogo(file);
    setValue('logo', file);
    await uploadFile(file);
  };
  const handleChange = async (e) => {
    const file = e.target.files[0];
    const filesize = (file.size / 1024 / 1024).toFixed(2);

    if (filesize <= 2) {
      setLogoFile(file);
      setIsCropImageModal(true);
    } else {
      openNotification({
        message: 'Allowed files with 2MB or below.',
        type: 'info',
      });
    }
  };
  useEffect(() => {
    if (companyInfo && companyInfo.company_name) {
      setValue('companyName', companyInfo.company_name);
    }
    if (companyInfo && companyInfo.hasOwnProperty('established_date')) {
      setValue('established_date', companyInfo.established_date);
    }
    if (companyInfo && companyInfo.hasOwnProperty('tax_vat')) {
      setValue('tax_vat', companyInfo.tax_vat);
    }
    if (companyInfo && companyInfo.hasOwnProperty('email')) {
      setValue('email', companyInfo.email);
    }
    if (companyInfo && companyInfo.legal_type_id) {
      setValue('legalType', companyInfo.legal_type_id);
      setSelectedLegalType(companyInfo.legal_type_id);
    }
    if (companyInfo && companyInfo.industry_group_id) {
      setValue('industry_group', companyInfo.industry_group_id);
      setSelectedIndustry(companyInfo.industry_group_id);
    }
    if (companyInfo && companyInfo.sub_industry_id) {
      setValue('subindustry', companyInfo.sub_industry_id);
      setSelectedIndustry(companyInfo.industry_sub_industry_idgroup_id);
    }
  }, [companyInfo]);

  return (
    <Box className={styles.container}>
      <Box className={styles.accountLogoContainer}>
        <div className={styles.accountLogoRow}>
          <input
            type="file"
            style={{ display: 'none' }}
            {...register('logo')}
            ref={hiddenFileInput}
            onChange={handleChange}
          />

          {!logo ? (
            <div className={styles.accountLogoOverlay} onClick={handleClick}>
              <img src="/images/accLogo.svg" alt="accountLogo" />
            </div>
          ) : (
            <div className={styles.accountLogo} onClick={handleClick}>
              <img className={styles.accountLogoImg} src={logo?.preview} alt="logo" />
              <div className={styles.accountLogoHover}>
                <img src="/images/accLogo.svg" alt="accountLogo" />
              </div>
            </div>
          )}
        </div>
        <div className={styles.accountInputsRow}>
          <div className={styles.accountColumn}>
            <div className={styles.accountLogoInputContainer} style={{ marginTop: 0 }}>
              <TextField
                {...register('companyName', {
                  required: 'Please fill out the rest fields. This is mandatory.',
                  pattern: {
                    value: /^.{1,255}$/gm,
                    message: "You can't write more than 40 characters.",
                  },
                })}
                label="Company Name*"
                className={styles.input}
                error={!!errors.companyName}
                helperText={errors.companyName?.message}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: errors?.companyName && 'red',
                  },
                }}
                type="text"
              />
            </div>
            <div className={`${styles.accountLogoInputContainer} ${styles.accountLogoInputMini}`}>
              <SelectComponent
                key={legalType.length}
                values={legalType}
                valueField="id"
                labelField="name"
                name="legalType"
                placeholder="Company legal type*"
                register={register}
                getValues={getValues}
                errors={errors}
                changeValue={(value) => setSelectedLegalType(value)}
              />
            </div>
          </div>
          <div className={styles.accountColumn} style={{ flexWrap: 'wrap' }}>
            <div className={styles.accountLogoInputContainer}>
              <SelectComponent
                key={JSON.stringify(industryGroups)}
                values={industryGroups}
                valueField="id"
                labelField="name"
                name="industry_group"
                placeholder="Industry group*"
                register={register}
                getValues={getValues}
                errors={errors}
                changeValue={(value) => setSelectedIndustry(value)}
              />
            </div>
            <div className={styles.accountLogoInputContainer}>
              <SelectComponent
                key={subIndustries.length}
                values={subIndustries}
                valueField="id"
                labelField="name"
                name="subindustry"
                placeholder="Prime subindustry*"
                register={register}
                getValues={getValues}
                errors={errors}
                changeValue={(value) => setSelectedSubIndustry(value)}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box className={`${styles.accountColumn} ${styles.accountColumnButtomBlock}`}>
        <Box className={styles.accountLogoInputContainer}>
          <Controller
            style={{ width: '100%' }}
            {...register('established_date', {
              required: true,
              max: {
                value: new Date(),
                message: 'The entered date cannot be in the future.',
              },
            })}
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Establish date*"
                  inputFormat="MM/dd/yyyy"
                  minDate={1970}
                  disableFuture
                  value={value ? new Date(value) : null}
                  onChange={onChange}
                  className={styles.input}
                  slots={{
                    openPickerIcon: () => (
                      <CalendarIcon
                        style={{
                          width: 26,
                          height: 26,
                          cursor: 'pointer',
                        }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            )}
          />
          {errors?.established_date && (
            <span className={styles.errorsText}>
              {errors?.established_date?.message ||
                'Please fill out the rest fields. This is mandatory.'}
            </span>
          )}
        </Box>
        <Box className={styles.accountLogoInputContainer}>
          <TextField
            {...register('tax_vat', {
              required: true,
            })}
            className={styles.input}
            type="text"
            label="TAX/VAT number*"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: errors?.tax_vat && 'red',
              },
            }}
          />
          {errors?.tax_vat && (
            <span className={styles.errorsText}>
              Please fill out the rest fields. This is mandatory.
            </span>
          )}
        </Box>
        <Box className={styles.accountLogoInputContainer}>
          <TextField
            {...register('email', {
              required: true,
            })}
            className={styles.input}
            type="email"
            label="Email*"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: errors?.email && 'red',
              },
            }}
          />
          {errors?.email && (
            <span className={styles.errorsText}>
              Please fill out the rest fields. This is mandatory.
            </span>
          )}
        </Box>
      </Box>
      {isCropImageModal && (
        <AvatarCropModal
          open={isCropImageModal}
          setOpen={setIsCropImageModal}
          avatarFile={logoFile}
          setAvatar={handleUpdateAvatar}
        />
      )}
    </Box>
  );
};
