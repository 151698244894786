import React, { useEffect, useState } from 'react';
import styles from './DiscountView.module.scss';
import {
  LeftIcon,
  AddIcon,
  RightIcon,
  ArrowReloadIcon,
  CopyIcon,
  PowerIcon,
} from 'tt-ui-lib/icons';
import {
  Button,
  Select,
  Input,
  Loader,
  Modal,
  HelpTooltip,
  openNotification,
} from 'tt-ui-lib/core';
import { useNavigate, useParams } from 'react-router';
import { useSelectedContext } from '../../../../context/contextSelector';
import {
  useDigitalAssets,
  useDigitalAssetsBridge,
  useDigitalAssetsWallet,
} from '../../../../modules/tt-digital-assets-provider';
import { KycModal } from '../../../../modules/tt-modals';
import { useLazyQuery } from '@apollo/client';
import { SHOW_CARBON_CREDIT } from '../../../../api';
import { useSearchParams } from 'react-router-dom';
import DepositModal from './DepositModal';

const getDiscountValue = (fcemCount, price) => {
  if (!price || typeof price !== 'number') return '-';

  let finalPrice = 0;

  switch (fcemCount) {
    case 20:
      finalPrice = price - price * 0.05;
      break;
    case 40:
      finalPrice = price - price * 0.1;
      break;
    case 60:
      finalPrice = price - price * 0.15;
      break;
    case 80:
      finalPrice = price - price * 0.2;
      break;
    case 100:
      finalPrice = price - price * 0.25;
      break;
    case 120:
      finalPrice = price - price * 0.3;
      break;
    case 140:
      finalPrice = price - price * 0.35;
      break;
    case 160:
      finalPrice = price - price * 0.4;
      break;
    case 180:
      finalPrice = price - price * 0.45;
      break;
    case 200:
      finalPrice = price - price * 0.5;
      break;

    default:
      finalPrice = price - price * 0.5;
      break;
  }

  return finalPrice.toFixed(2) || '-';
};

const CarbonCartDiscount = () => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { user } = useSelectedContext((context) => context.user);
  const { connect, account, chainSettings, chainId, status, switchChain, copyToClipboard } =
    useDigitalAssets();
  const { registerWalletStateIcon, getBalance, balance, getRegisterWalletState } =
    useDigitalAssetsWallet();
  const { bridge, wrapBalance, getWrapBalance } = useDigitalAssetsBridge();

  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [showDisconnectPrompt, setShowDisconnectPrompt] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(20);
  const orderQuantity = params.get('count') || '1';
  const [carbonData, setCarbonData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [depositModal, setDepositModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);

  const [getCarbonRequest] = useLazyQuery(SHOW_CARBON_CREDIT, {
    variables: { id: id },
    fetchPolicy: 'network-only',
  });

  const discountOptions = [
    {
      label: '5%',
      value: 20,
    },
    {
      label: '10%',
      value: 40,
    },
    {
      label: '15%',
      value: 60,
    },
    {
      label: '20%',
      value: 80,
    },
    {
      label: '25%',
      value: 100,
    },
    {
      label: '30%',
      value: 120,
    },
    {
      label: '35%',
      value: 140,
    },
    {
      label: '40%',
      value: 160,
    },
    {
      label: '45%',
      value: 180,
    },
    {
      label: '50%',
      value: 200,
    },
  ];

  const handleClose = () => {
    setDepositModal(false);
  };

  const walletConnect = () => {
    if (user?.kyc === 'success') {
      connect();
    } else {
      setShowNeedKYCModal(true);
    }
  };

  const getItemData = () => {
    setDataLoading(true);

    getCarbonRequest()
      .then((response) => {
        setCarbonData(response?.data?.showCarbonCreditById || null);
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while getting carbon credit data, try again later',
        });
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const depositCoins = () => {
    if (selectedDiscount > parseFloat(parseFloat(wrapBalance || balance).toFixed(5))) {
      openNotification({
        type: 'info',
        message: `You don't have enough FCEM for this operation, check your balance and try again`,
      });
      return;
    }

    setDepositModal(true);
  };

  const selectDiscount = (value) => {
    const item = discountOptions.find((item) => item.value === value);

    if (item.label === '50%' && parseInt(orderQuantity) && parseInt(orderQuantity) >= 3) {
      setSelectedDiscount(parseInt(orderQuantity) * 200);
      return;
    }

    setSelectedDiscount(value);
  };

  useEffect(() => {
    (async () => {
      if (chainId !== chainSettings?.chainId && bridge) {
        return getWrapBalance();
      }
      return getBalance();
    })();
  }, [chainId, bridge]);

  useEffect(() => {
    if (account) {
      getRegisterWalletState(account);
    }
  }, [account]);

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <div className={styles.container}>
      {account && (
        <div className={styles.block}>
          <div className={styles.accountWrapper}>
            <div className={styles.accountBox}>
              <>
                {status === 'unavailable' ? ( // MetaMask не доступен - предлагаем его установить
                  <>
                    <div style={{ marginTop: '20px' }}>
                      <p>
                        To proceed, please install the MetaMask plugin in your browser and connect
                        your wallet to TransparenTerra.
                      </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                      <Button type="default" onClick={() => window.open('https://metamask.io')}>
                        Install MetaMask Wallet
                      </Button>
                    </div>
                  </>
                ) : status === 'initializing' ? ( // Устанавливаем соединение с MetaMask
                  <>
                    <p>Synchronisation with MetaMask ongoing...</p>
                  </>
                ) : status === 'connecting' ? ( // Соединение с MetaMask'ом
                  <>
                    <p>Connecting to MetaMask...</p>
                  </>
                ) : status === 'connected' ? ( // MetaMask подключен
                  <>
                    {chainId !== chainSettings.chainId ? (
                      <>
                        <p>
                          To interact with the FCE blockchain, change the network in your MetaMask
                          accordantly.
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '10px',
                          }}
                        >
                          <Button
                            type="primary"
                            icon={
                              <ArrowReloadIcon
                                className="icon-white-fill"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                            onClick={switchChain}
                          >
                            Change network
                          </Button>
                        </div>
                      </>
                    ) : bridge ? (
                      <div className={styles.accountBoxWallet}>
                        {chainSettings?.chainId === chainId &&
                        !(
                          (wrapBalance === '' ||
                            Number.isNaN(wrapBalance) ||
                            wrapBalance === 'NaN') &&
                          (balance === '' || Number.isNaN(balance) || balance === 'NaN')
                        ) ? (
                          <span style={{ width: 18, height: 18, position: 'relative', top: '2px' }}>
                            {registerWalletStateIcon}
                          </span>
                        ) : (
                          ''
                        )}
                        <span className={styles.bold} style={{ marginLeft: '5px' }}>
                          {bridge?.symbol || 'FCEnet'}:
                        </span>
                        <span style={{ marginLeft: '5px' }}>
                          {`${account.substring(0, 6)}*****${account.substring(
                            account.length - 4
                          )}`}
                        </span>

                        <CopyIcon
                          style={{
                            position: 'relative',
                            top: '2px',
                            width: 20,
                            height: 20,
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                          onClick={async () => copyToClipboard(account)}
                        />

                        <PowerIcon
                          style={{
                            position: 'relative',
                            top: '2px',
                            width: 20,
                            height: 20,
                            cursor: 'pointer',
                            marginLeft: '13px',
                          }}
                          onClick={async () => setShowDisconnectPrompt(true)}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                ) : (
                  <div></div>
                )}
              </>
            </div>

            {bridge && chainId === chainSettings.chainId && (
              <div className={styles.balance}>
                Balance:&nbsp;
                <span style={{ margin: '0 5px' }}>
                  {(wrapBalance === '' || Number.isNaN(wrapBalance) || wrapBalance === 'NaN') &&
                  (balance === '' || Number.isNaN(balance) || balance === 'NaN') ? (
                    <Loader />
                  ) : (
                    <span style={{ fontSize: 20, marginTop: '-2px' }}>
                      {parseFloat(parseFloat(wrapBalance || balance).toFixed(5))}
                    </span>
                  )}
                </span>
                &nbsp;{bridge?.symbol || 'FCEM'}
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.block}>
        <div className={styles.header}>
          <Button type="icon" onClick={() => navigate(`/dashboard/credit/${id}`)} rounded>
            <LeftIcon style={{ width: 24, height: 24 }} />
          </Button>
          <h2>Get a discount</h2>
        </div>
        {dataLoading ? (
          <div
            style={{ display: 'flex', height: 250, alignItems: 'center', justifyContent: 'center' }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.text}>
              To save up to half the cost, pay 50% by card and deposit the remaining 50% into the
              FCEM with CaFFee smart contract. Upon confirming the offset event, the FCEM funds
              deposited will be released automatically to the address linked with the profile that
              claims the offset event. For more details:
              <span onClick={() => setHelpModal(true)}>
                <HelpTooltip tooltipText="" iconWidth={18} iconHeight={18} />
              </span>
            </div>

            <div className={styles.price}>
              Full price: {parseFloat(carbonData?.price || 0) * parseInt(orderQuantity || 0) || '-'}
              {carbonData?.currency?.symbol || ''} <span>/ {orderQuantity || '-'}t</span>
            </div>

            <div className={styles.stepBlock}>
              <div>Select discount</div>
              <RightIcon style={{ width: 20, height: 20 }} />
              <div>Deposit FCEM</div>
              <RightIcon style={{ width: 20, height: 20 }} />
              <div>Discount payment</div>
            </div>

            <div className={styles.stepBlockResponsive}>
              <div>
                <RightIcon style={{ width: 20, height: 20 }} />
                <div>Select discount</div>
              </div>
              <div>
                <RightIcon style={{ width: 20, height: 20 }} />
                <div>Deposit FCEM</div>
              </div>
              <div>
                <RightIcon style={{ width: 20, height: 20 }} />
                <div>Discount payment</div>
              </div>
            </div>

            <div className={account ? styles.calcBlockAccounted : styles.calcBlock}>
              <Select
                options={discountOptions}
                label="Select discount"
                value={selectedDiscount}
                onChange={(value) => {
                  selectDiscount(value);
                }}
                labelRender={(row) => {
                  return row?.label || '50%';
                }}
              />
              <Input
                label="FCEM deposit"
                value={selectedDiscount}
                className={styles.disabledInput}
                disabled
              />
              <Input
                label="Discount price"
                value={`${getDiscountValue(
                  selectedDiscount,
                  parseFloat(carbonData?.price || 0).toFixed(1) * parseInt(orderQuantity || 0) || ''
                )}${carbonData?.currency?.symbol || ''}`}
                className={styles.disabledInput}
                disabled
              />
              {account && (
                <Button
                  type="primary"
                  disabled={
                    status !== 'connected' ||
                    chainId !== chainSettings.chainId ||
                    !bridge ||
                    (!wrapBalance && !balance)
                  }
                  onClick={() => depositCoins()}
                >
                  Deposit FCEM
                </Button>
              )}
            </div>

            {!account && (
              <div className={styles.connectBlock}>
                <div>
                  To gain access to purchasing with a deposit in FCEM, it is essential to connect a
                  wallet. In order to proceed, kindly connect your MetaMask wallet or create a new
                  one.
                </div>
                <Button
                  type="primary"
                  onClick={() => {
                    walletConnect();
                  }}
                >
                  <AddIcon style={{ width: 22, height: 22 }} className="icon-white-fill" />
                  Connect wallet
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <DepositModal
        open={depositModal}
        handleClose={handleClose}
        id={id}
        quantity={orderQuantity}
        unit={carbonData?.unit?.name}
        wallet={account}
        deposit={selectedDiscount}
        discountPrice={
          discountOptions.find((v) => v.value === selectedDiscount)?.name?.split('%')[0] || 0
        }
      />

      <KycModal isOpen={showNeedKYCModal} setIsOpen={setShowNeedKYCModal} userId={user?.id} />

      <Modal // Disconnect modal
        open={showDisconnectPrompt}
        onClose={() => setShowDisconnectPrompt(false)}
        fullWidth
        maxWidth="md"
        title="How to disconnect the Wallet?"
      >
        <div className={styles.modalText}>
          To deny TransparenTerra access to your wallet, open MetaMask and click the three dots at
          the top of the interface. Then select Connected Sites in the menu that opens and specify
          the resources for which you want to block the wallet.
        </div>
      </Modal>

      <Modal // Help modal
        open={helpModal}
        onClose={() => setHelpModal(false)}
        fullWidth
        maxWidth="md"
        title="FCEM Deposit"
      >
        <div className={styles.modalText}>
          For every 4 FCEM you deposit, you will get a 1% discount for purchasing 1 ton of carbon
          credit. This formula applies regardless of the original price per 1 ton of carbon credit,
          meaning that 50% off the original price of 10$ or 20$ per 1 ton will require the same
          amount of 200 FCEM coins. If you are buying 3 tons and want to get 50% off the whole
          purchase, you must deposit 600 FCEM. If you deposit less than 200 FCEM, your discount will
          be less than 50%. Based on this logic the exact discount will be calculated in accordance
          with this formula: D=M/4T, where D is the discount in %, M is the amount of FCEM coins you
          are ready to deposit, and T is the number of tons of carbon credit you are buying.
        </div>
      </Modal>
    </div>
  );
};

export default CarbonCartDiscount;
