import React from 'react';
import Image from '../../../components/Image';
import styles from './CompanyInfoItem.module.scss';

const CONFIRMED_FALSE_ICON = '/images/templates/confirmed-false.png';
const CONFIRMED_TRUE_ICON = '/images/templates/confirmed-true.png';

const CompanyInfoItem = ({
  isLink = false,
  iconSrc,
  iconWidth,
  iconHeight,
  text,
  isConfirmed,
  isGovernmental = true,
}) => (
  <div className={styles.itemWrapper}>
    <div className={styles.iconWrapper} style={isLink ? { paddingTop: '10px' } : null}>
      <Image src={iconSrc} width={iconWidth} height={iconHeight} />
    </div>
    {isLink ? (
      <a className={styles.text} href={text} target="_blank" rel="noreferrer">
        {text}
      </a>
    ) : (
      <div className={styles.text}>{isGovernmental ? text : `${text} (NGO)`}</div>
    )}
    {text === 'Email address' && (
      <div className={styles.iconWrapper}>
        <Image
          src={isConfirmed ? CONFIRMED_TRUE_ICON : CONFIRMED_FALSE_ICON}
          width={17.5}
          height={17.5}
        />
      </div>
    )}
  </div>
);

export default CompanyInfoItem;
