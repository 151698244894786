import { Grid, Card, IconButton, ButtonBase } from '@mui/material';
import React, { useState } from 'react';
import Image from '../../Image';
import { AppAvatar } from '../../avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import styles from '../GridMode.module.scss';

const GridListItem = ({ user }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const router = useNavigate();

  return (
    <Grid item key={user.id} xs={6} sm={4} md={3} xl={3}>
      <Card className={styles.card}>
        <div className={styles.cardImageBlock}>
          <div
            className={styles.cardImage}
            onClick={() => router(`/dashboard/profile/${user?.id}`)}
          >
            <AppAvatar
              userName={`${user.first_name} ${user.last_name}`}
              role={user.roles[0]?.name}
              src={user.avatar}
              nav={0}
              size="lg"
            />
          </div>

          <div
            className={styles.gridCardName}
            onClick={() => router(`/dashboard/profile/${user?.id}`)}
          >{`${user.first_name} ${user.last_name}`}</div>
        </div>
        <div className={styles.gridCardDescription}>
          {user.invited_by_name ? `Invited by ${user.invited_by_name}` : ''}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {user.profession && (
            <>
              <Image src="/images/company/bag.svg" style={{ marginRight: 5 }} />
              <div className={styles.cardMoreInfo}>{user.profession}</div>
            </>
          )}
        </div>
        <div className={styles.cardActions}>
          <IconButton>
            <Image src="/images/company/camera.svg" />
          </IconButton>
          <IconButton onClick={() => router(`/messaging?profile=${user.id}`)}>
            <Image src="/images/company/message.svg" />
          </IconButton>
          <IconButton onClick={() => setOpenOptions(user.id)}>
            <MoreVertIcon sx={{ color: '#18397A' }} />
          </IconButton>
          {openOptions && (
            <div className={styles.optionsWindow} onMouseLeave={() => setOpenOptions(false)}>
              <ButtonBase className={styles.options} onClick={() => setOpenOptions(false)}>
                Follow
              </ButtonBase>
              <ButtonBase
                className={styles.options}
                onClick={() => setOpenOptions(false)}
                sx={{ marginTop: '16px' }}
              >
                Connect
              </ButtonBase>
            </div>
          )}
        </div>
      </Card>
    </Grid>
  );
};

export default GridListItem;
