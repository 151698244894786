import { Box } from '@mui/material';
import styles from './AboutTab.module.scss';
import React, { useEffect, useState } from 'react';
import Image from '../../../../components/Image';
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import ButtonBase from '@mui/material/ButtonBase';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Grid from '@mui/material/Grid';

const CERTIFICATES = [
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
  {
    name: 'Certificate',
    src: '/images/company/certificate-example.png',
  },
];
const VIDEOS = [
  {
    name: 'Video 1',
    src: '/images/company/video-cover-example.png',
  },
  {
    name: 'Video 2',
    src: '/images/company/video-cover-example.png',
  },
  {
    name: 'Video 3',
    src: '/images/company/video-cover-example.png',
  },
  {
    name: 'Video 4',
    src: '/images/company/video-cover-example.png',
  },
  {
    name: 'Video 5',
    src: '/images/company/video-cover-example.png',
  },
  {
    name: 'Video 6',
    src: '/images/company/video-cover-example.png',
  },
  {
    name: 'Video 7',
    src: '/images/company/video-cover-example.png',
  },
];
const PRESENTATIONS = [
  {
    name: 'Presentation 1',
    src: '/images/company/presentation-example.png',
  },
  {
    name: 'Presentation 2',
    src: '/images/company/presentation-example.png',
  },
  {
    name: 'Presentation 3',
    src: '/images/company/presentation-example.png',
  },
  {
    name: 'Presentation 4',
    src: '/images/company/presentation-example.png',
  },
  {
    name: 'Presentation 5',
    src: '/images/company/presentation-example.png',
  },
  {
    name: 'Presentation 6',
    src: '/images/company/presentation-example.png',
  },
  {
    name: 'Presentation 7',
    src: '/images/company/presentation-example.png',
  },
];
const GOALS = [
  'no_poverty',
  'zero_hunger',
  'good_health',
  'quality_education',
  'gender_quality',
  'clear_water',
  'clean_energy',
  'economic_growth',
  'industry',
  'reduce_inequality',
  'sustainable_community',
  'responsible_consumption',
  'climate_action',
  'life_below_water',
  'life_on_land',
  'peace',
  'partnership',
];

const selectSx = {
  padding: '0px',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
};

const AboutTab = ({ servicesData, companyInfo, isProduction }) => {
  const [goalsCount, setGoalsCount] = useState(5);
  const [description, setDescription] = useState('');
  const [companyGoals, setCompanyGoals] = useState([]);
  const [tags, setTags] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    if (companyInfo && companyInfo.description) {
      setDescription(companyInfo.description);
    }
    if (companyInfo && companyInfo.tags) {
      let tagsData = [];
      companyInfo.tags.map((item) => {
        tagsData.push(item.tag);
      });
      setTags(tagsData);
    }

    if (companyInfo && companyInfo.companyGoals && companyInfo.companyGoals.length) {
      const goalsFromDB = companyInfo.companyGoals;
      let orderedGoals = [];
      for (let i = 0; i < GOALS.length; i++) {
        for (let j = 0; j < goalsFromDB.length; j++) {
          if (GOALS[i] === goalsFromDB[j].name) {
            orderedGoals.push(goalsFromDB[j]);
          }
        }
      }
      setCompanyGoals(orderedGoals);
    }
  }, [companyInfo]);

  useEffect(() => {
    setServiceList(servicesData);
  }, [servicesData]);

  useEffect(() => {
    const scrollBlock = document.getElementById('scroll-block-video');
    checkScrollable(scrollBlock, 'scroll-block-video-right-button');
  }, [VIDEOS]);

  useEffect(() => {
    const scrollBlock = document.getElementById('scroll-block-presentation');
    checkScrollable(scrollBlock, 'scroll-block-presentation-right-button');
  }, [PRESENTATIONS]);

  const checkScrollable = (block, buttonId) => {
    if (block) {
      const isScrollable = block.scrollWidth >= block.clientWidth;
      const scrollRight = document.getElementById(buttonId);
      if (isScrollable) {
        scrollRight.style.display = 'flex';
      }
    }
  };

  const scrollToLeft = (elementId) => {
    const scrollBlock = document.getElementById(elementId);
    if (scrollBlock) {
      const scrollHorizontal = scrollBlock.scrollLeft - 440;
      scrollBlock.scroll({
        left: scrollHorizontal,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = (elementId) => {
    const scrollBlock = document.getElementById(elementId);
    if (scrollBlock) {
      const scrollHorizontal = scrollBlock.scrollLeft + 440;
      scrollBlock.scroll({
        left: scrollHorizontal,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = (elementId, sliderArray) => {
    if (sliderArray.length) {
      const block = document.getElementById(`scroll-block-${elementId}`);
      const scrollLeft = block.scrollLeft;
      const scrollWidth = block.scrollWidth;
      const offsetWidth = block.offsetWidth;
      const scrollLeftButton = document.getElementById(`scroll-block-${elementId}-left-button`);
      const scrollRightButton = document.getElementById(`scroll-block-${elementId}-right-button`);

      if (scrollLeft <= 0) {
        scrollLeftButton.style.display = 'none';
      } else {
        scrollLeftButton.style.display = 'flex';
      }
      if (offsetWidth + scrollLeft >= scrollWidth) {
        scrollRightButton.style.display = 'none';
      } else {
        scrollRightButton.style.display = 'flex';
      }
    }
  };

  return (
    <Box>
      <Box className={styles.container}>
        <Box className={styles.title}>About company</Box>
        <Box className={styles.description}>{description}</Box>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.title}>About services</Box>
        {serviceList &&
          serviceList.length > 0 &&
          serviceList.map((service, index) => (
            <Box
              key={index}
              style={index > 0 ? { marginTop: 20, position: 'relative' } : { position: 'relative' }}
            >
              <Box className={styles.subTitle}>
                {service.name}
                <span>{service.category_name}</span>
              </Box>
              <Box className={styles.description}>{service.description}</Box>
            </Box>
          ))}
      </Box>
      {!isProduction && (
        <Box className={styles.container}>
          <Box className={styles.title}>Documents / Certificates / Patents / Licenses</Box>
          <Grid container spacing={2}>
            {CERTIFICATES.map((item, index) => (
              <Grid item xs={4} md={3} key={index}>
                <Box>
                  <Image src={item.src} fullHeight={true} fullWidth={true} />
                  <Box className={styles.certificateName}>{item.name}</Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box className={styles.paginationBlock}>
            <Pagination
              color="primary"
              variant="outlined"
              shape="rounded"
              page={1}
              count={3}
              className={styles.pagination}
              renderItem={(props) => (
                <PaginationItem
                  className={[
                    styles.paginationItem,
                    1 === props.page ? styles.activePaginationItem : '',
                  ]}
                  {...props}
                  disableRipple
                />
              )}
              // onChange={(event, value) => {
              //   setCurrentPage(value);
              // }}
            />
            <Box className={styles.paginationText}>Page 1 of 3</Box>
          </Box>
        </Box>
      )}
      {!isProduction && (
        <Box className={styles.container}>
          <Box className={styles.title}>Video and presentations</Box>
          <Box className={styles.subTitle}>Video</Box>
          <Box className={styles.scrollContainer}>
            <ButtonBase
              id="scroll-block-video-left-button"
              onClick={() => scrollToLeft('scroll-block-video')}
              className={styles.leftSlide}
            >
              <ChevronLeftIcon />
            </ButtonBase>
            <ScrollContainer
              id="scroll-block-video"
              onScroll={() => handleScroll('video', VIDEOS)}
              mouseScroll={{ buttons: [1] }}
            >
              <Box className={styles.videosContainer}>
                {VIDEOS.map((item, index) => (
                  <Box key={index} className={styles.videoItem}>
                    <Box className={styles.videoImage}>
                      <Image src={item.src} />
                      <PlayCircleIcon className={styles.videoPlayBtn} fontSize="large" />
                    </Box>
                    <p className={styles.videoName}>{item.name}</p>
                  </Box>
                ))}
              </Box>
            </ScrollContainer>
            <ButtonBase
              id="scroll-block-video-right-button"
              className={styles.rightSlide}
              onClick={() => scrollToRight('scroll-block-video')}
            >
              <ChevronRightIcon />
            </ButtonBase>
          </Box>
          <div className={styles.subTitle}>Presentations</div>
          <Box className={styles.scrollContainer}>
            <ScrollContainer
              id="scroll-block-presentation"
              onScroll={() => handleScroll('presentation', PRESENTATIONS)}
              mouseScroll={{ buttons: [1] }}
            >
              <Box className={styles.presentationsContainer}>
                <ButtonBase
                  id="scroll-block-presentation-left-button"
                  onClick={() => scrollToLeft('scroll-block-presentation')}
                  className={styles.leftSlide}
                >
                  <ChevronLeftIcon />
                </ButtonBase>
                {PRESENTATIONS.map((item, index) => (
                  <Box key={index} className={styles.presentationItem}>
                    <Box className={styles.presentationImage}>
                      <Image src={item.src} />
                    </Box>
                    <p className={styles.presentationName}>{item.name}</p>
                  </Box>
                ))}
                <ButtonBase
                  id="scroll-block-presentation-right-button"
                  className={styles.rightSlide}
                  onClick={() => scrollToRight('scroll-block-presentation')}
                >
                  <ChevronRightIcon />
                </ButtonBase>
              </Box>
            </ScrollContainer>
          </Box>
        </Box>
      )}
      <Box className={styles.container}>
        <Box className={styles.title} id="sdgs">
          SDGs
        </Box>
        <Box>
          {companyGoals.map(
            (goal, index) =>
              goalsCount >= index + 1 && (
                <Box key={index} className={styles.sdgsBlocks}>
                  <Image src={`/images/community/${goal.name}.png`} width={128} height={128} />
                  <Box>
                    <Box className={styles.subTitle}>{goal.name.replace('_', ' ')}</Box>
                    <Box className={styles.goalDescription}>{goal.description}</Box>
                  </Box>
                </Box>
              )
          )}
          {companyGoals.length > 5 && (
            <Box
              className={styles.more}
              onClick={() =>
                goalsCount === companyGoals.length
                  ? setGoalsCount(5)
                  : setGoalsCount(companyGoals.length)
              }
            >
              {goalsCount === companyGoals.length ? 'See less' : 'See more'}
            </Box>
          )}
        </Box>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.title}>Tags</Box>
        <Box>
          {tags.map((item, index) => (
            <Chip key={index} label={item} variant="outlined" className={styles.chip} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default AboutTab;
