import React, { useEffect, useState } from 'react';
import styles from './documentsUploadAccount.module.scss';
import CustomDeleteIconChips from '../../../../../deleteIconChips';
import { UploadFile } from './uploadFile';
import { UploadMpeg } from './uploadMpeg';
import { useFormContext, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { DropZone } from './dropZone';
import { useLazyQuery } from '@apollo/client';
import { GET_SERVICE_CATEGORIES } from '../../../../../../api';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField/TextField';
import SelectComponent from 'components/select';

const helperTextStyles = {
  marginTop: '2px',
  marginLeft: 0,
  marginRight: 0,
};

export const DocumentsUploadAccount = ({ setCategories, serviceData, categories }) => {
  const {
    register,
    control,
    setValue,
    resetField,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'service',
    keyName: 'key',
  });
  const [tagValue, setTagValue] = useState('');
  const [isVideoDropzoneOpen, setisVideoDropzoneOpen] = useState(false);
  const [isDocumentsDropzoneOpen, setisDocumentsDropzoneOpen] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [documentsFiles, setDocumentsFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tagError, setTagError] = useState('');
  const [tags, setTags] = useState([]);

  const [serviceCategories] = useLazyQuery(GET_SERVICE_CATEGORIES);

  const getServiceCategoriesList = async () => {
    const res = await serviceCategories();
    if (res.data && res.data.showAllServiceCategories) {
      setCategories(res.data.showAllServiceCategories);
    }
  };
  useEffect(() => {
    getServiceCategoriesList();
  }, []);

  useEffect(() => {
    const videos = getValues('videos');
    const documents = getValues('documents');
    setVideoFiles(videos);
    setDocumentsFiles(documents);
    const serviceOldValue = getValues('service');
    resetField('service');
    if (serviceOldValue && !serviceOldValue.length && !serviceData.length) {
      append({ serviceName: '', category: '', aboutService: '' });
    } else if (serviceData.length > 0) {
      serviceData.map((item) => {
        let data = {
          id: item.id,
          serviceName: item.name,
          category: item.category_id,
          aboutService: item.description,
        };
        append(data);
      });
    }
    const tagsOldValue = getValues('tags');
    if (tagsOldValue) {
      setTags(tagsOldValue);
    }
  }, []);

  let handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    if (tagValue) {
      if (tags.length < 10) {
        if (!tags.includes(tagValue)) {
          setTags([...tags, tagValue]);
          setTagValue('');
          setTagError('');
        } else {
          setTagError('Тag already exists.');
        }
      } else {
        setTagError('The number of tags cannot be more than 10.');
      }
    }
  };

  let onChangeTags = (value) => {
    setTagError('');
    setTagValue(value);
  };

  let onHandleDeleteTag = (index) => {
    let filteredTags = tags.filter((tag, i) => i !== index);
    setTags(filteredTags);
  };

  let onDroppedVideos = (files) => {
    setisVideoDropzoneOpen(false);
    setVideoFiles([...videoFiles, ...files]);
  };

  let onDroppedDocuments = (files) => {
    setisDocumentsDropzoneOpen(false);
    if (documentsFiles) {
      setDocumentsFiles([...documentsFiles, ...files]);
    } else {
      setDocumentsFiles(files);
    }
  };

  let onDeleteVideo = (name) => {
    let filteredVideos = videoFiles.filter((file) => file.name !== name);
    setVideoFiles(filteredVideos);
    setValue('videos', filteredVideos);
  };

  let onDeleteDocument = (name) => {
    let filteredDocuments = documentsFiles.filter((file) => file.name !== name);
    setDocumentsFiles(filteredDocuments);
    setValue('documents', filteredDocuments);
  };

  useEffect(() => {
    setValue('documents', documentsFiles);
  }, [documentsFiles]);

  useEffect(() => {
    setValue('videos', videoFiles);
  }, [videoFiles]);

  useEffect(() => {
    setValue('tags', tags);
  }, [tags]);

  return (
    <div>
      <TextField
        {...register('aboutCompany', {
          value: '',
          pattern: {
            value: /^[\s\S]{1,3000}$/,
            message: "You can't write more than 3000 characters",
          },
        })}
        multiline
        className={styles.textarea}
        error={!!errors.aboutCompany}
        helperText={errors.aboutCompany?.message}
        FormHelperTextProps={{
          style: {
            ...helperTextStyles,
          },
        }}
        sx={{ marginBottom: '12px', marginTop: '8px' }}
        label="Tell us more about company"
      />
      <div className={styles.documentsAccountLeft}>
        <TextField
          type="text"
          className={styles.input}
          label="Tags"
          value={tagValue}
          InputProps={{
            style: { cursor: 'pointer' },
            endAdornment: (
              <InputAdornment position="start">
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img onClick={() => addTag()} src="/images/plus-gray.svg" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => onChangeTags(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        {tagError ? <div className={styles.errorText}>{tagError}</div> : null}
      </div>
      <div className={styles.documentsAccountText}>
        Please add all relevant subcategories after the comma. It will make this profile more
        visible
      </div>
      <CustomDeleteIconChips tags={tags} handleDeleteTag={onHandleDeleteTag} />

      <h4 className={styles.documentsTitle}>About service</h4>
      {fields.map((item, index) => (
        <React.Fragment key={index}>
          <div className={styles.documentService}>
            <div className={styles.documentsServiceRow}>
              <TextField
                {...register(`service.[${index}].serviceName`)}
                className={styles.input}
                type="text"
                sx={{ marginTop: 0 }}
                label="Service Name"
              />
              <SelectComponent
                values={categories}
                valueField="id"
                key={selectedCategory}
                getValues={getValues}
                register={register}
                changeValue={(value) => setSelectedCategory(value)}
                labelField="name"
                name={`service.[${index}].category`}
                placeholder="Category"
                required={false}
              />
            </div>
          </div>
          <TextField
            {...register(`service[${index}].aboutService`, {
              value: '',
              pattern: {
                value: /^[\s\S]{1,3000}$/,
                message: "You can't write more than 3000 characters",
              },
            })}
            multiline
            className={styles.textarea}
            error={errors.service && errors.service[index] && !!errors.service[index]?.aboutService}
            helperText={
              errors.service &&
              errors.service[index] &&
              !!errors.service[index]?.aboutService &&
              errors.service[index]?.aboutService.message
            }
            FormHelperTextProps={{
              style: {
                ...helperTextStyles,
              },
            }}
            label="Tell us more about services"
          />
          {fields.length > 1 && (
            <div className={styles.deleteButtonArea}>
              <button
                type="button"
                className={styles.deleteButton}
                onClick={() => {
                  remove(index);
                }}
              >
                <img src="/images/delete.png" width={16} height={18} />
                <p style={{ marginLeft: '16px' }}>DELETE</p>
              </button>
            </div>
          )}
        </React.Fragment>
      ))}
      <button
        type="button"
        className={styles.addMServiceButton}
        onClick={() => append({ serviceName: '', category: '', aboutService: '' })}
      >
        <img src="/images/plus-icon.png" alt="" className={styles.plusIcon} />
        <p>ADD SERVICE</p>
      </button>
      <div className={styles.documentsFile}>
        <div className={styles.documentsTextRow}>
          <h3 className={styles.documentsTitle}>Documents / Certificates / Patents / Licenses</h3>
          {!isDocumentsDropzoneOpen && (
            <button
              disabled
              className={styles.addBtn}
              onClick={() => setisDocumentsDropzoneOpen(true)}
            >
              <AddIcon color="primary" />
              <p>Add Documents</p>
            </button>
          )}
        </div>
        {isDocumentsDropzoneOpen && (
          <div className={styles.dropzone}>
            <DropZone onDropped={onDroppedDocuments} />
          </div>
        )}
        <UploadFile files={documentsFiles} onDeleteDocument={onDeleteDocument} />
      </div>
      <div className={styles.documentsVideo}>
        <div className={styles.documentsTextRow}>
          <h3 className={styles.documentsTitle}>Videos</h3>
          {!isVideoDropzoneOpen && (
            <button disabled className={styles.addBtn} onClick={() => setisVideoDropzoneOpen(true)}>
              <AddIcon color="primary" />
              <p>Add File</p>
            </button>
          )}
        </div>
        {isVideoDropzoneOpen && <DropZone onDropped={onDroppedVideos} />}
        <UploadMpeg files={videoFiles} onDeleteVideo={onDeleteVideo} />
      </div>
      <div className={styles.documentsVideo}>
        <div className={styles.documentsTextRow}>
          <h3 className={styles.documentsTitle}>Presentations</h3>
          {!isVideoDropzoneOpen && (
            <button disabled className={styles.addBtn} onClick={() => setisVideoDropzoneOpen(true)}>
              <AddIcon color="primary" />
              <p>Add File</p>
            </button>
          )}
        </div>
        {isVideoDropzoneOpen && (
          <div className={styles.dropzone}>
            <DropZone onDropped={onDroppedVideos} />
          </div>
        )}
        <UploadMpeg files={videoFiles} onDeleteVideo={onDeleteVideo} />
      </div>
    </div>
  );
};
