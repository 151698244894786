import React from 'react';

const StripeIcon = ({ width = 16, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.56656 6.03959C6.56656 5.16834 7.29281 4.83271 8.45719 4.83271C10.1528 4.83271 12.3047 5.35209 14.0003 6.26646V1.02084C12.1528 0.282715 10.3091 0.00146484 8.46094 0.00146484C3.94156 0.00146484 0.929688 2.36084 0.929688 6.30584C0.929688 12.474 9.39844 11.474 9.39844 14.134C9.39844 15.1652 8.50406 15.4971 7.26156 15.4971C5.41781 15.4971 3.03469 14.7352 1.16406 13.7196V18.739C3.08859 19.5693 5.16179 19.9999 7.25781 20.0046C11.8909 20.0046 15.0822 18.0121 15.0822 14.0046C15.0822 7.35646 6.56656 8.54771 6.56656 6.03959Z" />
    </svg>
  );
};

export default StripeIcon;
