export async function checkDomain(url) {
  try {
    let domain = new URL(url);
    domain = domain.hostname.replace('www.', '');
    const checkGreenResponse = await fetch(
      `https://api.thegreenwebfoundation.org/api/v3/greencheck/${domain}`,
      {
        method: 'GET',
      }
    );
    const checkGreenResponseData = await checkGreenResponse.json();
    if (checkGreenResponseData) {
      return checkGreenResponseData.green;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
