import { MenuItem } from '@mui/material';
import Image from '../../../../../../../../components/Image';
import clsx from 'clsx';
import styles from './ActionMenuItem.module.scss';

const ActionMenuItem = ({ icon, text, onClick, isDelete, iconWidth, iconHeight }) => {
  const url = window.location.href;

  return (
    <MenuItem className={styles.menuItemWrapper} onClick={onClick}>
      <>
        {icon && (
          <div className={styles.iconWrapper}>
            <Image src={icon} width={iconWidth} height={iconHeight} />
          </div>
        )}
        <div className={clsx(styles.text, isDelete ? styles.delete : '')}>{text}</div>
      </>
    </MenuItem>
  );
};

export default ActionMenuItem;
