import { Box } from '@mui/material';
import React from 'react';
import AllTab from './AllTab';

const BlogTab = ({ activeTab, user }) => {
  return (
    <>
      {activeTab === 0 ? (
        <AllTab user={user} />
      ) : activeTab === 1 ? (
        <Box>{activeTab}</Box>
      ) : activeTab === 2 ? (
        <Box>{activeTab}</Box>
      ) : activeTab === 3 ? (
        <Box>{activeTab}</Box>
      ) : activeTab === 4 ? (
        <Box>{activeTab}</Box>
      ) : activeTab === 5 ? (
        <Box>{activeTab}</Box>
      ) : activeTab === 6 ? (
        <Box>{activeTab}</Box>
      ) : (
        <Box />
      )}
    </>
  );
};
export default BlogTab;
