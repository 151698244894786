import React, { useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styles from './ContactTab.module.scss';
import Image from '../../../../components/Image';
import ReactPhoneInput from 'react-phone-input-2';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { DELETE_COMPANY_CONTACT } from '../../../../api';
import { SocialMediaAccount } from '../../../../components/pages/commonBusinessProfileStepsPage/forms/socialForm/socialMediaAccount';
import { TextInput } from 'tt-ui-kit';

const ContactTab = ({
  socialLinks,
  contactsData,
  companyId,
  setEditContacts,
  editContacts,
  setContactsData,
  editSocialMedia,
  setEditSocialMedia,
}) => {
  const {
    setValue,
    getValues,
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
    keyName: 'key',
  });
  const [deleteCompanyContact] = useMutation(DELETE_COMPANY_CONTACT);

  useEffect(() => {
    if (editContacts && contactsData && contactsData.length) {
      contactsData.map((item) => {
        append({
          department_name: item.department_name,
          email: item.email,
          tel: item.tel,
          id: item.id,
          company_id: companyId,
        });
      });
    } else if (editContacts) {
      addContact();
    } else {
      setValue('contacts', []);
    }
  }, [editContacts]);

  const onHandlePhone = (value, country, index) => {
    if (country.dialCode === value) {
      setValue(`contacts.${index}.tel`, '');
    } else {
      setValue(`contacts.${index}.tel`, value);
    }
  };

  const addContact = () => {
    append({ department_name: '', email: '', tel: '', company_id: companyId });
  };

  const editSocial = () => {
    socialLinks.map((itemSocial) => {
      setValue(`social.${itemSocial.resource}.resource`, itemSocial.resource);
      setValue(`social.${itemSocial.resource}.link`, itemSocial.link);
    });
    setEditSocialMedia(true);
  };

  const removeContact = async (index) => {
    if (fields[index].id) {
      const res = await deleteCompanyContact({ variables: { id: fields[index].id } });
      if (res.data) {
        remove(index);
        setContactsData((current) => current.filter((item, i) => index !== i));
        return true;
      }
    }
    remove(index);
    return true;
  };

  return (
    <Box>
      <Box className={styles.container}>
        <Box className={styles.title}>Contact information</Box>
        {editContacts ? (
          <Box>
            <Box>
              {fields.map((field, index) => (
                <Box className={styles.contactsColumn} key={index}>
                  {index > 0 && <Divider sx={{ marginBottom: '16px', marginTop: '16px' }} />}
                  <Box className={styles.socialAccountLeft}>
                    <Controller
                      name={`contacts.${index}.department_name`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <TextInput
                          {...register(`contacts.${index}.department_name`, {
                            pattern: {
                              value: /^.{1,255}$/gm,
                              message:
                                'The department name must not be greater than 255 characters.',
                            },
                          })}
                          {...field}
                          label="Department name"
                          value={value}
                          onChange={onChange}
                          type="text"
                          required={false}
                          error={!!errors?.contacts?.[index]?.department_name}
                          helperText={errors?.contacts?.[index]?.department_name?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box className={styles.socialAccountRight}>
                    <Controller
                      name={`contacts.${index}.email`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <TextInput
                          {...register(`contacts.${index}.email`, {
                            pattern: {
                              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: 'Please enter a valid email address.',
                            },
                          })}
                          {...field}
                          label="Email"
                          value={value}
                          onChange={onChange}
                          type="text"
                          className={styles.form_input}
                          required={true}
                          error={!!errors?.contacts?.[index]?.email}
                          helperText={errors?.contacts?.[index]?.email?.message}
                        />
                      )}
                    />
                    <Box className={styles.phone}>
                      <ReactPhoneInput
                        {...register(`contacts.${index}.tel`)}
                        country="ua"
                        containerStyle={{
                          borderRadius: '12px',
                        }}
                        buttonStyle={{
                          boxShadow: 'none',
                          borderRight: '1px solid #EFEFEF',
                          background: 'white',
                          borderRadius: '12px',
                          width: '99px',
                          paddingLeft: '30px',
                        }}
                        inputClass={styles.phoneInput}
                        inputStyle={{ borderRadius: '12px' }}
                        specialLabel={''}
                        value={getValues(`contacts.${index}.tel`)}
                        onChange={(e, country) => onHandlePhone(e, country, index)}
                      />
                    </Box>
                  </Box>
                  {fields.length > 1 && (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      <button
                        type="button"
                        className={styles.removeContactButton}
                        onClick={() => removeContact(index)}
                      >
                        <Image src="/images/delete-blue.svg" alt="delete" />
                        <Typography>Delete</Typography>
                      </button>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            {fields.length < 10 ? (
              <button type="button" className={styles.addContactButton} onClick={addContact}>
                <Image src="/images/plus-icon.png" alt="plus" />
                <Typography>Add Contact information</Typography>
              </button>
            ) : null}
          </Box>
        ) : (
          contactsData.map((item, index) => (
            <Box key={`${item.department_name}_${index}`}>
              <Box className={styles.subTitle}>{item.department_name}</Box>
              <Box className={styles.departmentBlock}>
                {item.email && (
                  <Box className={styles.departmentBlockItem}>
                    <Image src={'/images/company/mail.svg'} width={13.67} height={13.66} />
                    <Box className={styles.departmentBlockText}>{item.email}</Box>
                  </Box>
                )}
                {item.tel && (
                  <div className={styles.departmentBlockItem}>
                    <Image src={'/images/company/phone.svg'} width={13.67} height={13.66} />
                    <div className={styles.departmentBlockText}>{item.tel}</div>
                  </div>
                )}
              </Box>
            </Box>
          ))
        )}
        {editContacts ? (
          <Box className={styles.editButton}>
            <Box className={styles.editing}>
              <button type="submit">Save</button>
              <Image
                src={'/images/company/close.svg'}
                width={14}
                height={14}
                onClick={() => {
                  setEditContacts(false);
                }}
              />
            </Box>
          </Box>
        ) : (
          <button type="button" className={styles.editButton} onClick={() => setEditContacts(true)}>
            <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
          </button>
        )}
      </Box>
      <Box className={styles.container}>
        <Box className={styles.title}>Social media</Box>
        <Box className={styles.socialBlock}>
          {editSocialMedia ? (
            <Box className={styles.editButton}>
              <Box className={styles.editing}>
                <button type="submit">Save</button>
                <Image
                  src={'/images/company/close.svg'}
                  width={14}
                  height={14}
                  onClick={() => setEditSocialMedia(false)}
                />
              </Box>
            </Box>
          ) : (
            <button type="button" className={styles.editButton} onClick={() => editSocial()}>
              <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
            </button>
          )}
          {editSocialMedia ? (
            <SocialMediaAccount register={register} errors={errors} setValue={setValue} />
          ) : (
            socialLinks.map((item) => (
              <a
                key={item.resource}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialButton}
              >
                <Image src={`/images/${item.resource}.svg`} width={40} height={40} />
              </a>
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ContactTab;
