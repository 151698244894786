import { Select, MenuItem, IconButton } from '@mui/material';
import styles from './mapHead.module.scss';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchMap from '../searchMap';
import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';
import AppsIcon from '@mui/icons-material/Apps';
import { Map } from '../../../../modules/tt-map';

/* function onClickProducts() {
  location.pathname = "/dashboard/products";
}
function onClickService() {
  location.pathname = "/dashboard/service";
} */

export const MapHead = (props) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '1020px',
          height: '70px',
        }}
      >
        <SearchMap></SearchMap>
        <div>
          <Select
            sx={{
              width: '148px',
              height: '48px',
              ml: '20px',
              borderRadius: '12px',
              backgroundColor: '#f9f9f9',
            }}
            id="demo-simple-select"
            value="10"
            className={styles.mapSelect}
          >
            <MenuItem value={10}>Sort by</MenuItem>
            <MenuItem value={20}>Registration country</MenuItem>
            <MenuItem value={30}>Gender</MenuItem>
          </Select>
        </div>
        <div>
          <Select
            sx={{
              width: '178px',
              height: '48px',
              ml: '20px',
              borderRadius: '12px',
              backgroundColor: '#f9f9f9',
            }}
            id="demo-simple-select"
            value="10"
            className={styles.filterSelect}
          >
            <MenuItem value={10}>Filters</MenuItem>
            <MenuItem value={20}>Registration country</MenuItem>
            <MenuItem value={30}>Gender</MenuItem>
          </Select>
        </div>
        <IconButton
          style={{
            background: 'white',
            borderRadius: '10px',
            marginLeft: '20px',
            width: '48px',
            height: '48px',
            border: '1px solid lightgrey',
          }}
          aria-label="add to shopping cart"
        >
          <ShoppingCartOutlinedIcon />
        </IconButton>
        <div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              marginTop: '20px',
              marginLeft: '250px',
            }}
          >
            <div className={styles.btn}>
              <AppsIcon onClick={props.onClickGridView} />
            </div>
            <div className={styles.btn}>
              <ViewListIcon onClick={props.onClickListView} />
            </div>
            <div className={styles.btn}>
              <MapIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '1020px',
          height: '720px',
          borderRadius: '12px',
          background: 'white',
          border: '1px solid #FFFFFF',
        }}
      >
        <div
          style={{
            height: '672px',
            width: '980px',
            margin: '20px',
          }}
        >
          <Map
            coordinatesList={[
              {
                lat: 27.8247427,
                lng: -82.75040159999999,
              },
            ]}
            apiKey={mapApiKey}
          />
        </div>
      </div>
    </div>
  );
};
