import React, { useContext, useState } from 'react';
import userContext from '../../context/User/userContext';
import { GET_NOTIFICATIONS, MARK_AS_READ_NOTIFICATIONS_GROUP } from '../../api';
import { HeaderNavbar } from '../../modules/tt-navmenu';
// import 'tt-navmenu/dist/index.css';
import { SignInUpModalWrapper } from '../Modals/SignInUpModal/SignInUpModal';

const Navbar = (props) => {
  const { avaProps, logOut, notifications, setNotifications } = props;
  const { user } = useContext(userContext);

  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };

  return (
    <>
      <HeaderNavbar
        avaProps={avaProps}
        getNotificationsQuery={GET_NOTIFICATIONS}
        markAsReadNotificationsMutation={MARK_AS_READ_NOTIFICATIONS_GROUP}
        anchorLoginEl={anchorLoginEl}
        setAnchorLoginEl={setAnchorLoginEl}
        user={user}
        notificationSocket={process.env.REACT_APP_WS}
        setShowNeedKYCModal={setShowNeedKYCModal}
        setIsAgreementModalOpen={setIsAgreementModalOpen}
        logOut={logOut} // TODO check if need
        redirectDomain={process.env.REACT_APP_TT}
        mode="market"
        notifications={notifications}
        setNotifications={setNotifications}
      />

      <SignInUpModalWrapper open={open} closeModal={handleLoginClose} />
    </>
  );
};

export default Navbar;
