import { Box, Hidden, MenuItem, Select, Tab, Tabs } from '@mui/material';
import styles from './DocumentFlowTab.module.scss';
import React, { useState } from 'react';
import Image from '../../../../components/Image';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import BillsAndInvoicesTab from './Tabs/BillsAndInvoicesTab';
import ContactsTab from './Tabs/ContactsTab';
import OtherTab from './Tabs/OtherTab';

const tabs = ['All documents', 'Bills and invoices', 'Contracts', 'NDA/MOU', 'Other'];

const rows = [
  { id: 1, name: 'Frozen yoghurt', date: '01.02.2023', size: '10 KB' },
  { id: 2, name: 'Ice cream sandwich', date: '01.02.2023', size: '10 KB' },
  { id: 3, name: 'Eclair', date: '01.02.2023', size: '10 KB' },
  { id: 4, name: 'Cupcake', date: '01.02.2023', size: '10 KB' },
  { id: 5, name: 'Gingerbread', date: '01.02.2023', size: '10 KB' },
];

const DocumentFlowTab = ({ activeTab, setActiveTab }) => {
  const [pageCount, setPageCount] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Box className={styles.container}>
      <div className={styles.title}>Document Flow</div>
      <Hidden mdDown>
        <div className={styles.tabs}>
          <button type="button" className={styles.createButton}>
            Create new
          </button>
        </div>
      </Hidden>
      {activeTab === 1 ? (
        <BillsAndInvoicesTab />
      ) : activeTab === 2 ? (
        <ContactsTab />
      ) : activeTab === 4 ? (
        <OtherTab />
      ) : (
        <Box />
      )}
      <div className={styles.paginationBlock}>
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          count={pageCount}
          className={styles.pagination}
          renderItem={(props) => (
            <PaginationItem
              className={[
                styles.paginationItem,
                currentPage === props.page ? styles.activePaginationItem : '',
              ]}
              {...props}
              disableRipple
            />
          )}
          onChange={(event, value) => {
            setCurrentPage(value);
          }}
        />
        <div className={styles.paginationText}>
          Page {currentPage} of {pageCount}
        </div>
      </div>
      <button type="button" className={styles.editButton}>
        <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
      </button>
    </Box>
  );
};
export default DocumentFlowTab;
