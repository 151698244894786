import { MenuItem } from '@mui/material';
import Image from '../../../../../../../../components/Image';
import clsx from 'clsx';
import styles from './ActionMenuItem.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ActionMenuItem = ({ icon, text, onClick, isDelete }) => {
  const url = window.location.href;

  return (
    <MenuItem className={styles.menuItemWrapper} onClick={onClick}>
      {text === 'Copy link' ? (
        <CopyToClipboard text={url}>
          <button type="button" className={styles.shareBtn}>
            {icon && (
              <div className={styles.iconWrapper}>
                <Image src={icon} width={20} height={20} />
              </div>
            )}
            <div className={styles.text}>{text}</div>
          </button>
        </CopyToClipboard>
      ) : (
        <>
          {icon && (
            <div className={styles.iconWrapper}>
              <Image src={icon} width={20} height={20} />
            </div>
          )}
          <div className={clsx(styles.text, isDelete ? styles.delete : '')}>{text}</div>
        </>
      )}
    </MenuItem>
  );
};

export default ActionMenuItem;
