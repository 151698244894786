import React from 'react';
import Box from '@mui/material/Box';
import styles from './ServiceModal.module.scss';
import { Modal, Button, MenuItem } from 'tt-ui-kit';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField/TextField';

const selectSx = {
  padding: '0px',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
};

export const ServiceModal = ({
  categories,
  setServiceForm,
  open,
  onClose,
  serviceForm,
  editServiceAbout,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      onClick={(e) => e.stopPropagation()}
      title={serviceForm.id ? 'Edit service ' : 'Add service '}
    >
      <Box className={styles.content}>
        {!serviceForm.id && (
          <Box className={styles.subtitle}>
            {
              "You can add and assign roles of managers and admins to manage the company's business account."
            }
          </Box>
        )}
        <TextField
          type="text"
          className={styles.textField}
          label="Service Name"
          value={serviceForm.name}
          onChange={(e) => setServiceForm((current) => ({ ...current, name: e.target.value }))}
        />
        <Select
          className={styles.documentsServiceSelect}
          size="medium"
          value={serviceForm.category_id || 'category'}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '12px',
                padding: '12px 0px 24px 0px',
                border: '1px solid #EFEFEF',
                borderTop: '0',
              },
            },
            sx: {
              '&& .Mui-selected': {
                color: '#143471 !important',
                backgroundColor: 'transparent !important',
              },
            },
          }}
          sx={selectSx}
          onChange={(e) => {
            setServiceForm((current) => ({
              ...current,
              category_id: e.target.value,
            }));
          }}
        >
          <MenuItem disabled value="category" style={{ padding: '16px' }}>
            Category
          </MenuItem>
          {categories.map((item) => (
            <MenuItem key={item.id} value={item.id} style={{ padding: '16px' }}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          value={serviceForm.description}
          onChange={(e) =>
            setServiceForm((current) => ({ ...current, description: e.target.value }))
          }
          multiline
          rows={4}
          className={styles.textField}
          label="Tell us more about services"
        />
        <Box className={styles.modalButtons}>
          <Button type="default" onClick={onClose}>
            cancel
          </Button>

          <Button
            disabled={!serviceForm.category_id}
            type="primary"
            isSubmit
            onClick={editServiceAbout}
          >
            save
            {serviceForm.id && (
              <Box component="span" className={styles.saveBtnEdit}>
                &nbsp;changes
              </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
