import styles from './Image.module.scss';

const Image = ({
  src,
  fullWidth = false,
  fullHeight = false,
  width,
  height,
  alt,
  style,
  title,
  onClick,
  color,
  ...restProps
}) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <img
    className={`${styles.customImage || ''} ${restProps?.className || ''}`}
    style={{
      ...style,
      width: `${fullWidth ? '100%' : width + 'px'}`,
      height: `${fullHeight ? '100%' : height + 'px'}`,
      cursor: onClick ? 'pointer' : '',
    }}
    src={src}
    alt={alt}
    title={title}
    onClick={onClick}
    color={color}
  />
);

export default Image;
