import { Box } from '@mui/material';
import React from 'react';
import AllEventsTab from './AllEventsTab';

const EventsTab = ({ activeTab }) => {
  return (
    <>
      {activeTab === 0 ? (
        <AllEventsTab />
      ) : activeTab === 1 ? (
        <Box>{activeTab}</Box>
      ) : activeTab === 2 ? (
        <Box>{activeTab}</Box>
      ) : (
        <Box />
      )}
    </>
  );
};
export default EventsTab;
