import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import styles from './AboutTab.module.scss';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Image from '../../../../components/Image';
import { ServiceModal } from './ServiceModal';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Chip from '@mui/material/Chip';
import { useLazyQuery, useMutation } from '@apollo/client';
import { VIDEOS, PRESENTATIONS, CERTIFICATES } from './fakeData';
import {
  EDIT_COMPANY_SERVICE,
  EDIT_COMPANY,
  GET_SERVICE_CATEGORIES,
  EDIT_COMPANY_TAGS,
  EDIT_COMPANY_GOALS,
  CREATE_COMPANY_SERVICE,
  DELETE_COMPANY_SERVICE,
} from '../../../../api';
import CustomDeleteIconChips from '../../../../components/deleteIconChips';
import InputAdornment from '@mui/material/InputAdornment';
import { ConfirmModal, Button, TextField, FileCheckIcon } from 'tt-ui-kit';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ButtonBase from '@mui/material/ButtonBase';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { useDropzone } from 'react-dropzone';

const GOALS = [
  'no_poverty',
  'zero_hunger',
  'good_health',
  'quality_education',
  'gender_quality',
  'clear_water',
  'clean_energy',
  'economic_growth',
  'industry',
  'reduce_inequality',
  'sustainable_community',
  'responsible_consumption',
  'climate_action',
  'life_below_water',
  'life_on_land',
  'peace',
  'partnership',
];

const AboutTab = ({ servicesData, companyInfo, setCompanyInfo, isProduction, setServicesData }) => {
  const [goalsCount, setGoalsCount] = useState(5);
  const [editDescription, setEditDescription] = useState(false);
  const [editDocuments, setEditDocuments] = useState(false);
  const [editVideos, setEditVideos] = useState(false);
  const [editPresentations, setEditPresentations] = useState(false);
  const [certificates, setCertificates] = useState(CERTIFICATES);
  const [videos, setVideos] = useState(VIDEOS);
  const [presentations, setPresentations] = useState(PRESENTATIONS);
  const [editTags, setEditTags] = useState(false);
  const [editGoals, setEditGoals] = useState(false);
  const [description, setDescription] = useState('');
  const [tagValue, setTagValue] = useState('');

  const [serviceForm, setServiceForm] = useState({
    name: '',
    category_id: '',
    description: '',
    company_id: '',
    category_name: '',
    id: '',
  });
  const [categories, setCategories] = useState([]);
  const [companyGoals, setCompanyGoals] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [openTextareaDialog, setOpenTextareaDialog] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);
  const [textValidationError, setTextValidationError] = useState(null);
  const [text, setText] = React.useState('');
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [sendGoalData, setSendGoalData] = useState([]);
  const [tagError, setTagError] = useState('');
  const [editCompany] = useMutation(EDIT_COMPANY);
  const [editCompanyService] = useMutation(EDIT_COMPANY_SERVICE);
  const [createCompanyService] = useMutation(CREATE_COMPANY_SERVICE);
  const [deleteCompanyService] = useMutation(DELETE_COMPANY_SERVICE);
  const [editCompanyTags] = useMutation(EDIT_COMPANY_TAGS);
  const [editCompanyGoals] = useMutation(EDIT_COMPANY_GOALS);
  const [serviceModal, setServiceModal] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDropped(files, 'file'),
  });
  const {
    acceptedFiles: acceptedFilesVideo,
    getRootProps: getRootPropsVideo,
    getInputProps: getInputPropsVideo,
  } = useDropzone({
    onDrop: (files) => onDropped(files, 'videos'),
  });
  const {
    acceptedFiles: acceptedFilesPresentations,
    getRootProps: getRootPropsPresentations,
    getInputProps: getInputPropsPresentations,
  } = useDropzone({
    onDrop: (files) => onDropped(files, 'presentations'),
  });
  const onDropped = (files, type) => {
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        uploadFile(files[i], type);
        reader.readAsDataURL(files[i]);
      }
    }
  };
  const uploadFile = async (file, type) => {
    if (type === 'certificate') {
      setCertificates((currentDocument) => [
        ...currentDocument,
        {
          original_name: file.name,
          size: file.size,
          src: '/images/company/certificate-example.png',
        },
      ]);
    } else if (type === 'videos') {
      setVideos((currentVideos) => [
        ...currentVideos,
        {
          original_name: file.name,
          size: file.size,
          src: '/images/company/certificate-example.png',
        },
      ]);
    } else if (type === 'presentations') {
      setPresentations((currentVideos) => [
        ...currentVideos,
        {
          original_name: file.name,
          size: file.size,
          src: '/images/company/certificate-example.png',
        },
      ]);
    }
  };

  useEffect(() => {
    if (companyInfo && companyInfo.description) {
      setDescription(companyInfo.description);
    }
    if (companyInfo && companyInfo.tags) {
      let tagsData = [];
      companyInfo.tags.map((item) => {
        tagsData.push(item.tag);
      });
      setTags(tagsData);
    }

    if (companyInfo && companyInfo.companyGoals) {
      const goalsFromDB = companyInfo.companyGoals;
      let orderedGoals = [];
      for (let i = 0; i < GOALS.length; i++) {
        for (let j = 0; j < goalsFromDB.length; j++) {
          if (GOALS[i] === goalsFromDB[j].name) {
            orderedGoals.push(goalsFromDB[j]);
          }
        }
      }
      setCompanyGoals(orderedGoals);
    }
  }, [companyInfo]);

  const [serviceCategories] = useLazyQuery(GET_SERVICE_CATEGORIES);

  const getServiceCategoriesList = async () => {
    const res = await serviceCategories();
    if (res.data && res.data.showAllServiceCategories) {
      setCategories(res.data.showAllServiceCategories);
    }
  };
  useEffect(() => {
    getServiceCategoriesList();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    if (tagValue) {
      if (tags.length < 10) {
        if (!tags.includes(tagValue)) {
          setTags([...tags, tagValue]);
          setTagValue('');
          setTagError('');
        } else {
          setTagError('Тag already exists.');
        }
      } else {
        setTagError('The number of tags cannot be more than 10.');
      }
    }
  };

  const onChangeTags = (value) => {
    setTagError('');
    setTagValue(value);
  };

  const editAbout = async () => {
    const res = await editCompany({
      variables: {
        input: {
          id: companyInfo.id,
          description: description,
        },
      },
    });
    if (res.data && res.data.editCompany) {
      setEditDescription(false);
    }
  };

  const editServiceAbout = async () => {
    if (serviceForm.id && serviceForm.category_id) {
      const res = await editCompanyService({
        variables: {
          input: {
            name: serviceForm.name,
            category_id: serviceForm.category_id,
            description: serviceForm.description,
            id: serviceForm.id,
          },
        },
      });
      if (res.data && res.data.editCompanyService) {
        let updatedData = servicesData.map((item) => {
          if (item.id === serviceForm.id) {
            return res.data.editCompanyService;
          }
          return item;
        });
        setServicesData(updatedData);
        setServiceModal(false);
      }
    } else if (serviceForm.category_id) {
      const res = await createCompanyService({
        variables: {
          input: {
            name: serviceForm.name,
            category_id: serviceForm.category_id,
            description: serviceForm.description,
            company_id: companyInfo.id,
          },
        },
      });
      if (res?.data?.createCompanyService) {
        setServicesData((current) => [...current, res.data.createCompanyService]);
        setServiceModal(false);
      }
    }
  };

  const updateCompanyTags = async () => {
    const res = await editCompanyTags({
      variables: {
        input: {
          tags: tags,
          company_id: companyInfo.id,
        },
      },
    });
    if (res.data && res.data.editCompanyTags) {
      setEditTags(false);
    }
  };

  const onHandleDeleteTag = (index) => {
    let filteredTags = tags.filter((tag, i) => index !== i);
    setTags(filteredTags);
  };

  const openEditService = (index) => {
    setServiceModal(true);
    setServiceForm({
      name: servicesData[index].name,
      category_id: servicesData[index].category_id,
      description: servicesData[index].description,
      company_id: servicesData[index].company_id,
      id: servicesData[index].id,
      category_name: servicesData[index].serviceCategory?.name,
    });
  };

  const addService = () => {
    setServiceModal(true);
    setServiceForm({
      name: '',
      category_id: '',
      description: '',
      company_id: '',
      category_name: '',
    });
  };

  const updateCompanyGoals = async () => {
    const res = await editCompanyGoals({
      variables: {
        input: {
          company_id: companyInfo.id,
          goals: sendGoalData,
        },
      },
    });
    if (res.data && res.data.editCompanyGoals) {
      setCompanyInfo((current) => ({ ...current, companyGoals: res.data.editCompanyGoals }));
      setEditGoals(false);
    }
  };

  const saveText = () => {
    let value = text.trim();
    if (value === '') {
      setTextValidationError('Please fill out the this field. This is mandatory.');
      return false;
    }
    if (value.length > 150) {
      setTextValidationError('This field cannot be longer than 150 characters.');
      return false;
    }
    setTextValidationError(null);
    const newSendGoalData = sendGoalData.map((item, index) => {
      if (index === selectedGoal) {
        return { ...item, description: value };
      }
      return item;
    });
    setSendGoalData(newSendGoalData);
    setSelectedGoal(null);
    setOpenTextareaDialog(false);
  };

  const openEditGoals = () => {
    const selectedSendGoalsData = [];
    const selectedGoalsData = [];
    companyGoals.map((item) => {
      selectedSendGoalsData.push({ name: item.name, description: item.description });
      selectedGoalsData.push(item.name);
    });
    setSelectedGoals(selectedGoalsData);
    setSendGoalData(selectedSendGoalsData);
    setEditGoals(true);
  };

  const deleteService = (id) => {
    setSelectedServiceId(id);
    setOpenDeleteModal(true);
  };

  const deleteQuestionHandler = async () => {
    setLoadingDeleteModal(true);
    const res = await deleteCompanyService({ variables: { id: selectedServiceId } });
    if (res.data) {
      setServicesData((current) => current.filter((item) => item.id !== selectedServiceId));
      setSelectedServiceId(null);
      setOpenDeleteModal(false);
      setLoadingDeleteModal(false);
      return true;
    }
    setLoadingDeleteModal(false);
  };

  useEffect(() => {
    const scrollBlock = document.getElementById('scroll-block-video');
    checkScrollable(scrollBlock, 'scroll-block-video-right-button');
  }, [videos, editVideos]);

  useEffect(() => {
    const scrollBlock = document.getElementById('scroll-block-presentation');
    checkScrollable(scrollBlock, 'scroll-block-presentation-right-button');
  }, [presentations, editPresentations]);

  const checkScrollable = (block, buttonId) => {
    if (block) {
      const isScrollable = block.scrollWidth >= block.clientWidth;
      const scrollRight = document.getElementById(buttonId);
      if (isScrollable) {
        scrollRight.style.display = 'flex';
      }
    }
  };

  const scrollToLeft = (elementId) => {
    const scrollBlock = document.getElementById(elementId);
    if (scrollBlock) {
      const scrollHorizontal = scrollBlock.scrollLeft - 440;
      scrollBlock.scroll({
        left: scrollHorizontal,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = (elementId) => {
    const scrollBlock = document.getElementById(elementId);
    if (scrollBlock) {
      const scrollHorizontal = scrollBlock.scrollLeft + 440;
      scrollBlock.scroll({
        left: scrollHorizontal,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = (elementId, sliderArray) => {
    if (sliderArray.length) {
      const block = document.getElementById(`scroll-block-${elementId}`);
      const scrollLeft = block.scrollLeft;
      const scrollWidth = block.scrollWidth;
      const offsetWidth = block.offsetWidth;
      const scrollLeftButton = document.getElementById(`scroll-block-${elementId}-left-button`);
      const scrollRightButton = document.getElementById(`scroll-block-${elementId}-right-button`);

      if (scrollLeft <= 0) {
        scrollLeftButton.style.display = 'none';
      } else {
        scrollLeftButton.style.display = 'flex';
      }
      if (offsetWidth + scrollLeft >= scrollWidth) {
        scrollRightButton.style.display = 'none';
      } else {
        scrollRightButton.style.display = 'flex';
      }
    }
  };

  const renderFileSize = (bite) => {
    const megabyte = bite / (1024 * 1024);
    return megabyte.toFixed(3);
  };

  const removeDocument = (index) => {
    setCertificates((currentDocuments) => currentDocuments.filter((item, i) => index !== i));
  };
  const removeVideos = (index) => {
    setVideos((currentDocuments) => currentDocuments.filter((item, i) => index !== i));
  };
  const removePresentations = (index) => {
    setPresentations((currentDocuments) => currentDocuments.filter((item, i) => index !== i));
  };

  return (
    <Box>
      <Box className={styles.container}>
        <Box className={styles.title}>About company</Box>
        {editDescription ? (
          <TextField
            className={[styles.textField, styles.aboutTextField]}
            label="About company"
            disableUnderline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
          />
        ) : (
          <Box className={styles.content}>
            <Box className={styles.description}>{description}</Box>
            {(companyInfo.legalType ||
              companyInfo.industryGroup ||
              companyInfo.subIndustry ||
              companyInfo.established_date) && (
              <Box className={styles.generalInfo}>
                {companyInfo.legalType && (
                  <Box className={styles.generalInfoItem}>
                    <Typography className={styles.infoTitle}>Company legal type:</Typography>
                    <Typography className={styles.infoValue}>
                      {companyInfo.legalType.name}
                    </Typography>
                  </Box>
                )}
                {companyInfo.industryGroup && (
                  <Box className={styles.generalInfoItem}>
                    <Typography className={styles.infoTitle}>Industry group:</Typography>
                    <Typography className={styles.infoValue}>
                      {companyInfo.industryGroup.name}
                    </Typography>
                  </Box>
                )}
                {companyInfo.subIndustry && (
                  <Box className={styles.generalInfoItem}>
                    <Typography className={styles.infoTitle}>Prime subindustry:</Typography>
                    <Typography className={styles.infoValue}>
                      {companyInfo.subIndustry.name}
                    </Typography>
                  </Box>
                )}
                {companyInfo.established_date && (
                  <Box className={styles.generalInfoItem}>
                    <Typography className={styles.infoTitle}>Establish date:</Typography>
                    <Typography className={styles.infoValue}>
                      {moment(companyInfo.established_date).format('DD.MM.YYYY')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
        {!editDescription && (
          <button
            type="button"
            className={styles.editButton}
            onClick={() => setEditDescription(true)}
          >
            <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
          </button>
        )}
      </Box>
      {editDescription && (
        <Box className={styles.editActions}>
          <Button
            type="mainButton"
            onClick={() => {
              setEditDescription(false);
              setDescription(companyInfo.description);
            }}
            className={styles.cancelBtn}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={editAbout}>
            Save
          </Button>
        </Box>
      )}
      <Box className={styles.container}>
        <Box className={styles.titleWithBtn}>
          <Box className={styles.title}>About services</Box>
          <Button type="link" className={styles.addServiceButton} onClick={() => addService()}>
            <Image src={'/images/company/plus.svg'} width={14} height={14} />
            <Typography>Add</Typography>
          </Button>
        </Box>
        {servicesData && servicesData.length > 0 && (
          <Box className={[styles.content, styles.serviceContent]}>
            <>
              {servicesData.map((service, index) => (
                <Box key={`key_${index}`} sx={{ position: 'relative' }}>
                  <Box className={styles.subTitle} sx={{ width: '80%' }}>
                    {service.name}
                    <Box component="span">{service?.serviceCategory?.name}</Box>
                  </Box>
                  <Box className={styles.description}>{service.description}</Box>
                  <Box className={styles.editServiceButtonBlock}>
                    <button type="button" onClick={() => openEditService(index)}>
                      <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
                    </button>
                    <button type="button" onClick={() => deleteService(service.id)}>
                      <Image src={'/images/company/delete.svg'} height={20} />
                    </button>
                  </Box>
                </Box>
              ))}
            </>
          </Box>
        )}
      </Box>
      <ServiceModal
        open={serviceModal}
        onClose={() => setServiceModal(false)}
        serviceForm={serviceForm}
        setServiceForm={setServiceForm}
        categories={categories}
        editServiceAbout={editServiceAbout}
      />
      {!isProduction && (
        <Box className={styles.container}>
          <Box className={styles.titleWithBtn}>
            <Box className={styles.title}>
              Documents
              <Box component="span" className={styles.fullTitle}>
                &nbsp;/ Certificates / Patents / Licenses
              </Box>
            </Box>
            {editDocuments && (
              <Box className={styles.addDocBtn} {...getRootProps()}>
                <input {...getInputProps()} />
                <Image src="/images/company/plus.svg" />
                <Box>Add</Box>
              </Box>
            )}
          </Box>
          {!editDocuments ? (
            <>
              <button
                type="button"
                className={styles.editButton}
                onClick={() => setEditDocuments(true)}
              >
                <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
              </button>
              {certificates.length > 0 && (
                <>
                  <Grid container spacing={2} className={styles.certificates}>
                    {certificates.map((item, index) => (
                      <Grid item xs={4} md={3} key={index}>
                        <Box>
                          <Image src={item.src} fullHeight={true} fullWidth={true} />
                          <Box className={styles.certificateName}>{item.original_name}</Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box className={styles.paginationBlock}>
                    <Pagination
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      page={1}
                      count={3}
                      className={styles.pagination}
                      renderItem={(props) => (
                        <PaginationItem
                          className={[
                            styles.paginationItem,
                            1 === props.page ? styles.activePaginationItem : '',
                          ]}
                          {...props}
                          disableRipple
                        />
                      )}
                      // onChange={(event, value) => {
                      //   setCurrentPage(value);
                      // }}
                    />
                    <Box className={styles.paginationText}>Page 1 of 3</Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              {certificates.length > 0 && (
                <Box className={styles.certificatesEditing}>
                  <Divider component="Box" />
                  <Box className={styles.certificatesList}>
                    {certificates.map((item, index) => (
                      <Box key={index} className={styles.docItem}>
                        <Box className={styles.docNameBlock}>
                          <Box className={styles.docImg}>
                            <FileCheckIcon />
                          </Box>
                          <Typography className={styles.docName}>{item.original_name}</Typography>
                        </Box>
                        <Box className={styles.docActions}>
                          <Box className={styles.fileSize}>{renderFileSize(item.size)}MB</Box>
                          <Box className={styles.removeFile} onClick={() => removeDocument(index)}>
                            <CloseIcon />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
      {editDocuments && (
        <Box className={styles.editActions}>
          <Button
            type="mainButton"
            onClick={() => setEditDocuments(false)}
            className={styles.cancelBtn}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={() => setEditDocuments(false)}>
            Save
          </Button>
        </Box>
      )}
      {!isProduction && (
        <Box className={styles.container}>
          <Box className={styles.title}>Video and presentations</Box>
          <Box className={editVideos ? styles.videoSubtitleEditing : styles.videoSubtitle}>
            {(videos.length > 0 || (editVideos && !videos.length)) && (
              <>
                <Box className={styles.subTitle}>Video</Box>
                {editVideos && (
                  <Box className={styles.addDocBtn} {...getRootPropsVideo()}>
                    <input {...getInputPropsVideo()} />
                    <Image src="/images/company/plus.svg" />
                    <Box>Add</Box>
                  </Box>
                )}
              </>
            )}
          </Box>
          {editVideos && (
            <>
              {videos.length > 0 && (
                <Box className={styles.certificatesEditing}>
                  <Box className={styles.certificatesList}>
                    {videos.map((item, index) => (
                      <Box key={index} className={styles.docItem}>
                        <Box className={styles.docNameBlock}>
                          <Box className={styles.docImg}>
                            <FileCheckIcon />
                          </Box>
                          <Typography className={styles.docName}>{item.original_name}</Typography>
                        </Box>
                        <Box className={styles.docActions}>
                          <Box className={styles.fileSize}>{renderFileSize(item.size)}MB</Box>
                          <Box className={styles.removeFile} onClick={() => removeVideos(index)}>
                            <CloseIcon />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}
          {!editVideos && (
            <>
              <button
                type="button"
                className={styles.editButton}
                onClick={() => {
                  setEditVideos(true);
                  setEditPresentations(true);
                }}
              >
                <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
              </button>
              {videos.length > 0 && (
                <Box
                  className={[
                    styles.filesContainer,
                    !presentations.length ? styles.noBorder : null,
                  ]}
                >
                  <ButtonBase
                    id="scroll-block-video-left-button"
                    onClick={() => scrollToLeft('scroll-block-video')}
                    className={styles.leftSlide}
                  >
                    <ChevronLeftIcon />
                  </ButtonBase>
                  <ScrollContainer
                    id="scroll-block-video"
                    onScroll={() => handleScroll('video', videos)}
                    mouseScroll={{ buttons: [1] }}
                  >
                    <Box className={styles.videosContainer}>
                      {videos.map((item, index) => (
                        <Box key={index} className={styles.videoItem}>
                          <Box className={styles.videoImage}>
                            <Image src={item.src} />
                            <PlayCircleIcon className={styles.videoPlayBtn} fontSize="large" />
                          </Box>
                          <Typography className={styles.videoName}>{item.original_name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </ScrollContainer>
                  <ButtonBase
                    id="scroll-block-video-right-button"
                    className={styles.rightSlide}
                    onClick={() => scrollToRight('scroll-block-video')}
                  >
                    <ChevronRightIcon />
                  </ButtonBase>
                </Box>
              )}
            </>
          )}

          <Box className={editPresentations ? styles.videoSubtitleEditing : styles.videoSubtitle}>
            {(presentations.length > 0 || (editPresentations && !presentations.length)) && (
              <>
                <Box className={styles.subTitle}>Presentations</Box>
                {editPresentations && (
                  <Box className={styles.addDocBtn} {...getRootPropsPresentations()}>
                    <input {...getInputPropsPresentations()} />
                    <Image src="/images/company/plus.svg" />
                    <Box>Add</Box>
                  </Box>
                )}
              </>
            )}
          </Box>
          {editPresentations && (
            <>
              {presentations.length > 0 && (
                <Box className={styles.certificatesEditing}>
                  <Box className={styles.certificatesList}>
                    {presentations.map((item, index) => (
                      <Box key={index} className={styles.docItem}>
                        <Box className={styles.docNameBlock}>
                          <Box className={styles.docImg}>
                            <FileCheckIcon />
                          </Box>
                          <Typography className={styles.docName}>{item.original_name}</Typography>
                        </Box>
                        <Box className={styles.docActions}>
                          <Box className={styles.fileSize}>{renderFileSize(item.size)}MB</Box>
                          <Box
                            className={styles.removeFile}
                            onClick={() => removePresentations(index)}
                          >
                            <CloseIcon />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}

          {!editPresentations && presentations.length > 0 && (
            <Box className={styles.filesContainer}>
              <ScrollContainer
                id="scroll-block-presentation"
                onScroll={() => handleScroll('presentation', presentations)}
                mouseScroll={{ buttons: [1] }}
              >
                <Box className={styles.presentationsContainer}>
                  <ButtonBase
                    id="scroll-block-presentation-left-button"
                    onClick={() => scrollToLeft('scroll-block-presentation')}
                    className={styles.leftSlide}
                  >
                    <ChevronLeftIcon />
                  </ButtonBase>
                  {presentations.map((item, index) => (
                    <Box key={index} className={styles.presentationItem}>
                      <Box className={styles.presentationImage}>
                        <Image src={item.src} />
                      </Box>
                      <Typography className={styles.presentationName}>{item.name}</Typography>
                    </Box>
                  ))}
                  <ButtonBase
                    id="scroll-block-presentation-right-button"
                    className={styles.rightSlide}
                    onClick={() => scrollToRight('scroll-block-presentation')}
                  >
                    <ChevronRightIcon />
                  </ButtonBase>
                </Box>
              </ScrollContainer>
            </Box>
          )}
        </Box>
      )}
      {(editVideos || editPresentations) && (
        <Box className={styles.editActions}>
          <Button
            type="mainButton"
            onClick={() => {
              setEditVideos(false);
              setEditPresentations(false);
              setVideos(VIDEOS);
              setPresentations(PRESENTATIONS);
            }}
            className={styles.cancelBtn}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setEditVideos(false);
              setEditPresentations(false);
            }}
          >
            Save
          </Button>
        </Box>
      )}
      <Box className={styles.container}>
        <Box className={styles.title} id="sdgs">
          SDGs
        </Box>
        <Box>
          {editGoals ? (
            <Box className={styles.communityContent}>
              {GOALS.map((goal, index) => (
                <Box key={index} className={styles.communityBlock}>
                  <img src={`/images/community/${goal}.png`}></img>
                  <Box className={styles.sdgItemInfo}>
                    <input type="hidden" value={goal} />
                    <FormGroup>
                      <FormControlLabel
                        className={styles.communityCheckbox}
                        control={
                          <Checkbox
                            value={goal}
                            checked={selectedGoals.includes(goal)}
                            onChange={(e) => {
                              if (selectedGoals.includes(goal)) {
                                setSelectedGoals(selectedGoals.filter((item) => item !== goal));
                                setSendGoalData(sendGoalData.filter((item) => item.name !== goal));
                              } else {
                                setSelectedGoals([...selectedGoals, goal]);
                                setSendGoalData([...sendGoalData, { name: goal, description: '' }]);
                              }
                            }}
                            sx={{
                              fontFamily: 'Montserrat',
                              color: '#C4C4C4',
                              paddingBottom: 0,
                              paddingTop: 0,
                              '&.Mui-checked': {
                                color: 'primary',
                              },
                            }}
                          />
                        }
                        label="We address this issue"
                      />
                    </FormGroup>
                    {selectedGoals.includes(goal) && (
                      <textarea
                        onClick={(e) => {
                          setText(e.target.value);
                          setOpenTextareaDialog(true);
                          setSelectedGoal(selectedGoals.indexOf(goal));
                        }}
                        value={sendGoalData[selectedGoals.indexOf(goal)].description}
                        className={styles.communityTextarea}
                        placeholder="Please add up to 150 characters"
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : companyGoals.length > 0 ? (
            <Box className={styles.content}>
              {companyGoals.map(
                (goal, index) =>
                  goalsCount >= index + 1 && (
                    <Box key={index} className={styles.sdgsBlocks}>
                      <Image src={`/images/community/${goal.name}.png`} width={128} height={128} />
                      <Box>
                        <Box className={styles.subTitle}>
                          {goal.name.replace('_', ' ').replace('_', ' ')}
                        </Box>
                        {goal.description && (
                          <Typography className={styles.goalDescription}>
                            {goal.description}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )
              )}
              {companyGoals.length > 5 && (
                <Box
                  className={styles.more}
                  onClick={() =>
                    goalsCount === companyGoals.length
                      ? setGoalsCount(5)
                      : setGoalsCount(companyGoals.length)
                  }
                >
                  {goalsCount === companyGoals.length ? 'See less' : 'See more'}
                </Box>
              )}
            </Box>
          ) : null}
          {!editGoals && (
            <button type="button" className={styles.editButton} onClick={() => openEditGoals()}>
              <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
            </button>
          )}
        </Box>
      </Box>
      {editGoals && (
        <Box className={styles.editActions}>
          <Button
            type="mainButton"
            onClick={() => setEditGoals(false)}
            className={styles.cancelBtn}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={updateCompanyGoals}>
            Save
          </Button>
        </Box>
      )}
      <Box className={styles.container}>
        <Box className={styles.title}>Tags</Box>
        {editTags ? (
          <Box className={styles.content}>
            <Box className={styles.documentsAccountLeft}>
              <TextField
                type="text"
                placeholder="Tags"
                className={styles.inputIcon}
                value={tagValue}
                onChange={(e) => onChangeTags(e.target.value)}
                onKeyPress={handleKeyPress}
                InputProps={{
                  style: { cursor: 'pointer' },
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                      <img onClick={() => addTag()} src="/images/plus-gray.svg" />
                    </InputAdornment>
                  ),
                }}
              />
              {tagError ? <Box className={styles.errorText}>{tagError}</Box> : null}
            </Box>
            <CustomDeleteIconChips tags={tags} handleDeleteTag={onHandleDeleteTag} />
          </Box>
        ) : tags.length > 0 ? (
          <Box className={styles.content}>
            <Box className={styles.tagsBlock}>
              {tags.map((item) => (
                <Chip key={item} label={item} variant="outlined" className={styles.chip} />
              ))}
            </Box>
          </Box>
        ) : null}
        {!editTags && (
          <button type="button" className={styles.editButton} onClick={() => setEditTags(true)}>
            <Image src={'/images/company/edit.svg'} width={13.67} height={13.66} />
          </button>
        )}
      </Box>
      {editTags && (
        <Box className={styles.editActions}>
          <Button type="mainButton" onClick={() => setEditTags(false)} className={styles.cancelBtn}>
            Cancel
          </Button>
          <Button type="primary" onClick={updateCompanyTags}>
            Save
          </Button>
        </Box>
      )}
      <Dialog
        open={openTextareaDialog}
        onClose={() => setOpenTextareaDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Payment How does your company address this issue?
        </DialogTitle>
        <DialogContent>
          <textarea
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            className={styles.communityDialogTextarea}
            placeholder="Please add up to 150 characters"
          />
          <Typography className={styles.errorText}>{textValidationError}</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '5px 20px 15px 20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{ color: '#18397A', borderColor: '#18397A', fontWeight: 700 }}
            variant="outlined"
            onClick={() => setOpenTextareaDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#18397A', fontWeight: 700 }}
            onClick={saveText}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        loading={loadingDeleteModal}
        title="Delete"
        subTitle="Do you really want to remove the service?"
        onSubmit={() => {
          deleteQuestionHandler();
        }}
      />
    </Box>
  );
};
export default AboutTab;
