import React, { useEffect, useState } from 'react';
import styles from './Checkout.module.scss';
import { Box, Typography } from '@mui/material';
import { Hidden } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button } from 'tt-ui-kit';
import TextField from '@mui/material/TextField/TextField';
import Image from '../../../Image';
import AddContactsModal from './AddContactsModal';
import AddDeliveriesModal from './AddDeliveriesModal';
import { useWindowSize } from '../../../../utils/useWindowSize';
import ContactItemModal from './AddContactsModal/ContactItemModal';
import DeliveryItemModal from './AddDeliveriesModal/DeliveryItemModal';
import { UPDATE_CONTACT_INFO, EDIT_ORDER, SHOW_CURRENT_USER_CONTACT_INFOS } from '../../../../api';
import { useLazyQuery, useMutation } from '@apollo/client';

const FAKE_CONTACTS = [
  {
    id: '1',
    firstName: 'Noah',
    lastName: 'Miller',
    phoneNumber: '380562312132',
    email: 'noah@example.com',
  },
  {
    id: '2',
    firstName: 'Olivia',
    lastName: 'Brown',
    phoneNumber: '380562312132',
    email: 'olivia@example.com',
  },
  {
    id: '3',
    firstName: 'Sophia',
    lastName: 'Williams',
    phoneNumber: '380562312132',
    email: 'sophia@example.com',
  },
  {
    id: '4',
    firstName: 'Liam',
    lastName: 'Garcia',
    phoneNumber: '380562312132',
    email: 'liam@example.com',
  },
];
const FAKE_DELIVERIES = [
  {
    id: '1',
    country: 'Russia',
    town: 'Moscow',
    address: 'Tverskaya 10',
    index: '101000',
    privateHouse: {
      apartment: 25,
      entrance: 3,
      floor: 5,
    },
  },
  {
    id: '2',
    country: 'United States',
    town: 'New York',
    address: 'Broadway 123',
    index: '10001',
    privateHouse: {
      apartment: 789,
      entrance: 2,
    },
  },
  {
    id: '3',
    country: 'France',
    town: 'Paris',
    address: 'Champs-Élysées 45',
    index: '75008',
    privateHouse: {
      entrance: 4,
      floor: 3,
    },
  },
  {
    id: '4',
    country: 'Germany',
    town: 'Berlin',
    address: 'Friedrichstraße 99',
    index: '10117',
    privateHouse: {
      apartment: 7,
      entrance: 1,
      floor: 4,
    },
  },
];

const CheckoutDataForm = ({ selectedContact, setSelectedContact, orderId }) => {
  const windowSize = useWindowSize();
  const { control, register } = useFormContext();
  const [addContactsModal, setAddContactsModal] = useState(false);
  const [addDeliveryModal, setAddDeliveryModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const [contactItemModal, setContactItemModal] = useState(null);
  const [deliveryItemModal, setDeliveryItemModal] = useState(null);
  const [editOrder] = useMutation(EDIT_ORDER);
  const [showCurrentUserContactInfos] = useLazyQuery(SHOW_CURRENT_USER_CONTACT_INFOS);
  const [updateContactInfo] = useMutation(UPDATE_CONTACT_INFO);
  const contactMethods = useForm();
  const contactItemMethods = useForm();
  const deliveryMethods = useForm();
  const deliveryItemMethods = useForm();

  useEffect(() => {
    (async () => {
      await showUserContactInfos();
    })();
  }, []);

  const setOrderContact = async (contactInfo) => {
    const response = await editOrder({
      variables: {
        input: [
          {
            id: orderId,
            contactInfo,
          },
        ],
      },
    });
    if (
      response &&
      response.data &&
      response.data.editOrder.length &&
      response.data.editOrder[0].contactInfo
    ) {
      setSelectedContact(response.data.editOrder[0].contactInfo);
      return response.data.editOrder[0].contactInfo;
    }
    return {
      type: 'error',
      message: 'Contact Not available',
    };
  };

  const editOrderData = async (contactInfo) => {
    if (contactInfo?.id !== selectedContact?.id || !selectedContact) {
      const data = await setOrderContact(contactInfo);
      if (contacts.length === 0 && data) {
        setContacts([data]);
      }
      return data;
    }
    return {
      type: 'error',
      message: 'Contact already selected',
    };
  };

  const editUserContactInfo = async (contactInfo) => {
    const response = await updateContactInfo({
      variables: {
        input: contactInfo,
      },
    });

    if (response && response.data && response.data.updateContactInfo) {
      setContacts((current) =>
        current.map((contact) => {
          if (contact.id === contactInfo.id) {
            return contactInfo;
          }
          return contact;
        })
      );

      if (selectedContact.id === contactInfo.id) {
        const { ____typename, ...newOrderContact } = contactInfo;
        delete newOrderContact.__typename;
        const data = await setOrderContact(newOrderContact);
        if (data.type === 'error') {
          openNotification(data);
        }
      }
    }
  };

  const showUserContactInfos = async () => {
    const response = await showCurrentUserContactInfos();
    if (response && response.data && response.data.showCurrentUserContactInfos) {
      setContacts(response.data.showCurrentUserContactInfos);
      if (!selectedContact) {
        if (response.data.showCurrentUserContactInfos.length > 0) {
          const { __typename, ...newOrderData } = response.data.showCurrentUserContactInfos[0];
          delete newOrderData.__typename;
          const data = await editOrderData(newOrderData);
          if (data.type === 'error') {
            openNotification(data);
          }
        }
      }
    }
  };

  return (
    <>
      <Box className={styles.dataForm}>
        <Box className={styles.contactHeader}>
          <Typography className={styles.contactTitle}>Contact information</Typography>
          {selectedContact && selectedContact.id ? (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setAddContactsModal(true)}
            >
              <Hidden smDown>
                <Box>My Contact Information</Box>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          ) : (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setContactItemModal('add')}
            >
              <Hidden smDown>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
                <Typography className={styles.addInfoText}>add Contact information</Typography>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          )}
        </Box>
        {selectedContact?.id && (
          <Box className={styles.dataFormFieldsContainer}>
            <Box className={styles.infos}>
              <Typography
                className={styles.infoText}
              >{`${selectedContact.first_name} ${selectedContact.last_name}`}</Typography>
              <Typography className={styles.infoText}>{selectedContact.tel}</Typography>
              <Typography className={styles.infoText}>{selectedContact.email}</Typography>
            </Box>
          </Box>
        )}
        <Box className={styles.contactHeader}>
          <Typography className={styles.contactTitle}>Delivery information</Typography>
          {selectedDelivery?.id ? (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setAddDeliveryModal(true)}
            >
              <Hidden smDown>
                <Box>My Delivery Information</Box>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          ) : (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setDeliveryItemModal('add')}
            >
              <Hidden smDown>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
                <Typography className={styles.addInfoText}>add Delivery information</Typography>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          )}
        </Box>
        {selectedDelivery?.id && (
          <Box className={styles.infos}>
            <Typography className={styles.infoText}>
              {`${selectedDelivery.country}, ${selectedDelivery.town}`},
            </Typography>
            <Typography className={styles.infoText}>{selectedDelivery.address},</Typography>
            <Typography className={styles.infoText}>{selectedDelivery.index}</Typography>
          </Box>
        )}
        <Box>
          <TextField
            {...register('comment', {
              pattern: {
                value: /^.{1,350}$/gm,
                message: "You can't write more than 350 characters.",
              },
            })}
            rows={2}
            multiline
            inputProps={{ style: { resize: 'vertical' } }}
            className={styles.textarea}
            label="Comment"
          />
        </Box>
      </Box>
      <FormProvider {...contactMethods}>
        <AddContactsModal
          contacts={contacts}
          open={addContactsModal}
          methods={contactMethods}
          onClose={() => setAddContactsModal(false)}
          setContacts={setContacts}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          editOrderData={editOrderData}
          editUserContactInfo={editUserContactInfo}
        />
      </FormProvider>
      <FormProvider {...contactItemMethods}>
        <ContactItemModal
          contact={contactItemModal}
          onClose={() => setContactItemModal(null)}
          editOrderData={editOrderData}
          editUserContactInfo={editUserContactInfo}
          setSelectedContact={setSelectedContact}
          allContacts={contacts}
          methods={contactItemMethods}
        />
      </FormProvider>
      <FormProvider {...deliveryMethods}>
        <AddDeliveriesModal
          deliveries={deliveries}
          methods={deliveryMethods}
          open={addDeliveryModal}
          onClose={() => setAddDeliveryModal(false)}
          setDeliveries={setDeliveries}
          selectedDelivery={selectedDelivery}
          setSelectedDelivery={setSelectedDelivery}
        />
      </FormProvider>
      <FormProvider {...deliveryItemMethods}>
        <DeliveryItemModal
          delivery={deliveryItemModal}
          onClose={() => setDeliveryItemModal(null)}
          setDeliveries={setDeliveries}
          setSelectedDelivery={setSelectedDelivery}
          allDeliveries={deliveries}
          methods={deliveryItemMethods}
        />
      </FormProvider>
    </>
  );
};

export default CheckoutDataForm;
