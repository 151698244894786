import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styles from './Result.module.scss';
import { Button } from 'tt-ui-lib/core';
import { VerifiedYesIcon, InfoIcon } from 'tt-ui-lib/icons';

const ResultCarbonCheckoutPage = () => {
  const navigate = useNavigate();
  const { status } = useParams();

  return status === 'error' ? (
    <div className={styles.page}>
      <InfoIcon className="icon-red-fill" style={{ width: 35, height: 35 }} />

      <p>Error while proceed your order payment, please try recreate order.</p>

      <Button
        type="primary"
        onClick={() => {
          navigate('/dashboard/marketplace');
        }}
      >
        Go to marketplace
      </Button>
    </div>
  ) : (
    <div className={styles.page}>
      <VerifiedYesIcon className="icon-green-fill" style={{ width: 35, height: 35 }} />

      <p>Your order is proceeded successfully!</p>

      <Button
        type="primary"
        onClick={() => {
          navigate('/dashboard/marketplace');
        }}
      >
        Go to marketplace
      </Button>
    </div>
  );
};

export default ResultCarbonCheckoutPage;
