import React, { useContext, useEffect, useState } from 'react';
import ProductAndServicesPage from '../ProductAndServicesPage';
import styles from './MarketplacePage.module.scss';
import CompanyInfoItem from '../../../views/CompanyPage/CompanyInfoItem';
import CompanyInfoSdgsItem from '../../../views/CompanyPage/CompanyInfoSdgsItem';
import CompanyInfoLocationItem from '../../../views/CompanyPage/CompanyInfoLocationItem';
import CompanyInfoAvatar from '../../../views/CompanyPage/CompanyInfoAvatar';
import LocationModal from '../../locationModal';
import { IconButton } from '@mui/material';
import Image from '../../Image';
import { useLazyQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GET_COMPANY_BY_ID } from '../../../api';
import CompanyInfoMenu from '../../../views/CompanyPage/CompanyInfoMenu';
import Box from '@mui/material/Box';
import { AddProposalModal } from '../../../modules/tt-modals';
import PartialLoader from '../../layout/PartialLoader';
import userContext from '../../../context/User/userContext';
import KybModal from 'components/Modals/KybModal';
import { openNotification } from 'tt-ui-lib/core';
import Cookies from 'js-cookie';
import ConnectStripeModal from '../../Modals/ConnectStripeModal';

const MarketplacePage = ({ isProduction }) => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [openStripeConnectionModal, setOpenStripeConnectionModal] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [companyInfo, setCompanyInfo] = useState({});
  const [isSeller, setIsSeller] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(userContext);
  const [isAddProposalModal, setIsAddProposalModal] = useState(false);
  const [proposalUserData, setProposalUserData] = useState([]);
  const [kybModalOpen, setKybModalOpen] = useState(false);
  const location = useLocation();
  const [getCompanyById] = useLazyQuery(GET_COMPANY_BY_ID, {
    variables: { id: companyId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (location && location.state && location.state.action === 'kyb-error') {
      openNotification({
        type: 'warning',
        message: 'You cant open this page, while KYB is not passed',
      });
      navigate(location.pathname, {});
    }
  }, [location]);

  const getCompany = async () => {
    const res = await getCompanyById();
    const companyInfo = res.data?.showCompanyById;
    if (companyInfo && userId === companyInfo.user_id) {
      setCompanyInfo(companyInfo);
      setIsSeller(true);
      setLoading(false);
    } else if (companyInfo && userId !== companyInfo.user_id && !companyInfo.is_draft) {
      setCompanyInfo(res.data.showCompanyById);
      setIsSeller(false);
      setLoading(false);
    } else if (companyInfo && userId !== companyInfo.user_id && companyInfo.is_draft) {
      navigate('/dashboard/marketplace', {
        replace: true,
        state: {
          isOpenNotification: true,
          openNotification: openNotification({
            type: 'error',
            message: 'You dont have access to this page',
          }),
        },
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getCompany();
    })();
  }, [user]);

  const createProposal = (type) => {
    navigate(`/dashboard/proposals/${type}/add?company=${companyId}&create-from=store`);
  };

  const onProposalModalClose = () => {
    setIsAddProposalModal(false);
  };

  const onModalClose = () => {
    setIsLocationModal(false);
  };

  const openMyCompanyPage = () => {
    navigate(`/dashboard/company/${companyId}/edit`);
  };

  const handleClickAddProposal = () => {
    setProposalUserData({ user: user });
    setIsAddProposalModal(true);
  };

  const openMessage = () => {
    window.location.replace(`
    ${process.env.REACT_APP_TT}/dashboard/messaging?company=${companyInfo?.id}
    `);
  };

  const joinMarket = () => {
    setOpenStripeConnectionModal(true);
  };

  return (
    <>
      <PartialLoader loading={loading} isLightContainer />
      <div className={isSeller ? styles.mainInformationSeller : styles.mainInformation}>
        <div className={styles.leftContent}>
          <CompanyInfoItem
            key={companyInfo.for_profit ? 'For profit' : 'Non profit'}
            iconSrc={
              companyInfo.for_profit
                ? '/images/company/for_profit.svg'
                : '/images/company/none_for_profit.svg'
            }
            iconWidth={24}
            iconHeight={24}
            text={companyInfo.for_profit ? 'For profit' : 'Non for profit'}
            isConfirmed={!!{}}
            isGovernmental={companyInfo.is_governmental}
          />
          {companyInfo.website && (
            <CompanyInfoItem
              key={companyInfo.website}
              iconSrc={'/images/company/smile.svg'}
              iconWidth={24}
              iconHeight={24}
              text={companyInfo.website}
              isConfirmed={!!{}}
              isLink={true}
            />
          )}
          {companyInfo.companyGoals?.length > 0 && (
            <CompanyInfoSdgsItem data={companyInfo && companyInfo.companyGoals} />
          )}
          {companyInfo.addressLocation && (
            <CompanyInfoLocationItem
              companyInfo={companyInfo}
              setIsLocationModal={setIsLocationModal}
            />
          )}
        </div>
        {companyInfo.id && <CompanyInfoAvatar user={user} companyInfo={companyInfo} size={'lg'} />}
        {isSeller ? (
          <div className={styles.rightContentSeller}>
            <div className={styles.sellerStoreActions}>
              {(isSeller && !isProduction) ||
              (isSeller &&
                isProduction &&
                user?.roles?.find((item) => item.name === 'transparenterra')) ? (
                <>
                  {companyInfo?.paymentAccountList.some((item) => item.completed_onboarding) ? (
                    <button
                      className={styles.addProposalBtn}
                      type="button"
                      onClick={handleClickAddProposal}
                    >
                      Add offer
                    </button>
                  ) : (
                    <button className={styles.joinMarketBtn} onClick={joinMarket}>
                      Join Market
                    </button>
                  )}

                  <button
                    className={styles.myCompanyBtn}
                    type="button"
                    onClick={() => openMyCompanyPage()}
                  >
                    My Company
                  </button>
                </>
              ) : null}
            </div>
            <CompanyInfoMenu
              companyId={companyId}
              type={companyInfo.type}
              companyName={companyInfo.company_name}
            />
          </div>
        ) : (
          <Box className={styles.rightBlock}>
            <div className={styles.rightContent}>
              <div className={styles.iconButtons}>
                <IconButton type="button" className={styles.messageIcon}>
                  <Image src="/images/company/message.svg" width={24} height={24} />
                </IconButton>
                <IconButton type="button">
                  <Image src="/images/company/follow.svg" width={22} height={16} />
                </IconButton>
                <IconButton type="button">
                  <Image src="/images/company/like.svg" width={20} height={18.35} />
                </IconButton>
              </div>
              <Box className={styles.buttons}>
                <button
                  className={`${styles.editBtn}
                   ${styles.messageBtn}`}
                  onClick={openMessage}
                  type="button"
                >
                  Message
                </button>
                <button className={styles.editBtn} type="button">
                  Hire
                </button>
                <button className={styles.editBtn} type="button">
                  Invite to job
                </button>
              </Box>
            </div>
          </Box>
        )}
      </div>
      {companyInfo.id && (
        <ProductAndServicesPage
          user={user}
          isSeller={isSeller}
          pageType="store"
          companyId={companyId}
          isProduction={isProduction}
        />
      )}
      <LocationModal
        title={'Map'}
        isModelOpen={isLocationModal}
        handleClose={onModalClose}
        setIsOpen={setIsLocationModal}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        background="#FFFFFF"
        isEditable={false}
        showSearch={false}
        zoom={19}
        isJoint
      />
      <AddProposalModal
        visible={isAddProposalModal}
        onClose={onProposalModalClose}
        onSubmit={createProposal}
        proposalUserData={proposalUserData}
      />
      <KybModal
        open={kybModalOpen}
        setOpen={setKybModalOpen}
        companyId={companyInfo?.id}
        mode="own"
      />
      <ConnectStripeModal
        open={openStripeConnectionModal}
        onClose={() => setOpenStripeConnectionModal(false)}
        companyId={companyId}
      />
    </>
  );
};

export default MarketplacePage;
