import React, { useEffect, useState } from 'react';
import { requestBlockchainProxy } from '../../../api';
import { setErrorMessage } from '../../../utils/appUtils';

import { Table, openNotification } from 'tt-ui-lib/core';
import { CopyIcon } from 'tt-ui-lib/icons';

import styles from './ProductAndServicesPage.module.scss';

const RegistryTable = () => {
  const getterHash = (val) =>
    val && val.length > 10
      ? `${val.substring(0, 5)}...${val.substring(val.length - 3)}`
      : val || '-';

  const getterDate = (val) => {
    if (val) {
      const _d = new Date(val);
      // eslint-disable-next-line no-unsafe-optional-chaining
      return `${_d?.getDate()?.toString()?.padStart(2, '0')}.${(_d?.getMonth() + 1)
        ?.toString()
        ?.padStart(2, '0')}.${_d?.getFullYear()?.toString().substring(2)} ${_d
        ?.getHours()
        ?.toString()
        ?.padStart(2, '0')}:${_d?.getMinutes()?.toString()?.padStart(2, '0')}`;
    }
    return '';
  };

  const getterValue = (val) => {
    const _val = parseFloat(val);
    return !isNaN(_val) ? (
      <span title={_val.toString()}>{String(Math.floor(_val * 100000) / 100000)}</span>
    ) : (
      <span>-</span>
    );
  };

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchData, setIsFetchData] = useState(true);

  const fetchData = () => {
    setIsLoading(true);
    const payload = {
      id: 'getCarbonContracts',
      method: 'getCarbonContracts',
    };

    requestBlockchainProxy(payload)
      .then((response) => {
        setTableData(response?.data?.result);
      })
      .catch(() => {
        setErrorMessage('Error while fetching registry data, try again later');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const copyToClipboard = (test) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(test).then(
        () => openNotification({ message: 'Success copied to clipboard', type: 'success' }),
        (err) =>
          openNotification({ message: `Could not copy text: ${err?.message}`, type: 'error' })
      );
    } else {
      openNotification({ message: "Can't copy to clipboard", type: 'error' });
    }
  };

  useEffect(() => {
    if (isFetchData) {
      fetchData();
      setIsFetchData(false);
    }
  }, [isFetchData]);

  const columns = [
    {
      key: 'carbon_address',
      dataIndex: 'carbon_address',
      title: 'Credit ID',
      ariaLabel: 'Credit ID',
      align: 'left',
      width: 140,
      render: (value) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CopyIcon
            style={{ width: 16, height: 16, cursor: 'pointer' }}
            onClick={(e) => {
              if (navigator.clipboard) {
                e.preventDefault();
                copyToClipboard(value);
              }
            }}
          />
          <span>{getterHash(value) || '-'}</span>
        </div>
      ),
    },
    {
      key: 'ts',
      dataIndex: 'ts',
      title: 'Date',
      ariaLabel: 'Date',
      align: 'left',
      width: 100,
      render: (value) => <span>{getterDate(value)}</span>,
    },
    {
      key: 'carbon_balance',
      dataIndex: 'carbon_balance',
      title: 'Value',
      ariaLabel: 'Value',
      align: 'left',
      width: 120,
      render: (value) => <span>{getterValue(value)}</span>,
    },
  ];

  return (
    <div className={styles.container}>
      <Table
        columns={columns}
        data={tableData || []}
        loading={isLoading}
        scroll={{ x: 870, y: '55vh' }}
      />
    </div>
  );
};

export default RegistryTable;
