import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styles from './carbonCredit.module.scss';
import { useQuery } from '@apollo/client';
import {
  GET_AVAILABLE_CURRENCIES,
  GET_CARBON_PROJECT_TYPE,
  GET_CARBON_STANDARDS,
  GET_UNITS,
  SHOW_REGIONS,
  GET_CARBON_PROVIDERS,
} from '../../../../api';
import AvatarUploader from '../../../avatarUploader';
import { Controller, useFormContext } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import LocationModal from '../../../locationModal';
import Image from '../../../Image';
import { Select, InputSelect, CheckBox, CalendarIcon } from 'tt-ui-kit';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField/TextField';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PartialLoader from '../../../layout/PartialLoader';
import NumberMaskInput from '../../../NumberMaskInput';

const helperTextStyles = {
  marginTop: '2px',
  marginLeft: 0,
  marginRight: 0,
};

export const CarbonCredit = ({
  setIsAddGoals,
  carbonCreditData,
  logoByProjectType,
  setLogoByProjectType,
  setIsRenderComponent,
  isRenderComponent,
}) => {
  const {
    getValues,
    setValue,
    watch,
    clearErrors,
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const [selectedUnitValue, setSelectedUnitValue] = useState(null);
  const [selectedCurrency, setSelectedCurrencyValue] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [dateValues, setDateValues] = useState({ from: null, to: null });
  const [goals, setGoals] = useState([]);
  const [coordinates, setCoordinates] = useState();
  const requiredMessage = 'This is a required field. Please fill in this field';
  const GOALS = [
    'no_poverty',
    'zero_hunger',
    'good_health',
    'quality_education',
    'gender_quality',
    'clear_water',
    'clean_energy',
    'economic_growth',
    'industry',
    'reduce_inequality',
    'sustainable_community',
    'responsible_consumption',
    'climate_action',
    'life_below_water',
    'life_on_land',
    'peace',
    'partnership',
  ];

  const { data: currencies, error } = useQuery(GET_AVAILABLE_CURRENCIES, {
    variables: { payment_system: 'stripe' },
  });

  const { data: units } = useQuery(GET_UNITS, {
    variables: { input: { type: 'mass', system_type: 'metric' } },
  });

  const { data: region } = useQuery(SHOW_REGIONS);
  const { data: standard } = useQuery(GET_CARBON_STANDARDS);
  const { data: projectType } = useQuery(GET_CARBON_PROJECT_TYPE);
  const { data: providers } = useQuery(GET_CARBON_PROVIDERS);

  useEffect(() => {
    const goalsList = getValues('goals');
    if (goalsList && goalsList.length) {
      let newGoalsList = [];
      goalsList.map((item) => {
        if (item.checked) {
          newGoalsList.push(item);
        }
      });
      setGoals(newGoalsList);
    }
  }, []);

  useEffect(() => {
    if (units) {
      if (selectedUnitValue === null) {
        setSelectedUnitValue(units?.getUnits[0]?.id);
      }
      setValue('unit_id', units?.getUnits.find((item) => item.id === selectedUnitValue)?.id);
    }
  }, [selectedUnitValue, units]);

  useEffect(() => {
    if (currencies) {
      if (selectedCurrency === null) {
        setSelectedCurrencyValue(currencies?.getAvailableCurrencies[0]?.id);
      }
      setValue(
        'currency_id',
        currencies?.getAvailableCurrencies.find((item) => item.id === selectedCurrency)?.id
      );
    }
  }, [selectedCurrency, currencies]);

  useEffect(() => {
    if (carbonCreditData?.id && !isRenderComponent) {
      setValue('name', carbonCreditData.name);
      setSelectedUnitValue(carbonCreditData.unit_id);
      setSelectedCurrencyValue(carbonCreditData.currency_id);
      setValue('discount_period', [
        new Date(carbonCreditData.discount_from_datetime),
        new Date(carbonCreditData.discount_to_datetime),
      ]);
      setValue('amount', carbonCreditData?.amount);
      setValue('price', carbonCreditData?.price);
      setValue('quantity', carbonCreditData?.quantity);
      setValue('ext_project_id', carbonCreditData?.ext_project_id);
      setValue('ext_credit_id', carbonCreditData?.ext_credit_id);
      setValue('link', carbonCreditData?.link);
      setValue('vintage_from', carbonCreditData?.vintage_from);
      setValue('vintage_to', carbonCreditData?.vintage_to);
      setValue('standard_id', carbonCreditData?.standard_id);
      setValue('provider_id', carbonCreditData?.provider_id);
      setValue('project_type_id', carbonCreditData?.projectType?.id);
      setLogoByProjectType(carbonCreditData?.projectType?.logoPath);
      setValue('region_id', carbonCreditData?.region_id);
      setValue('description', carbonCreditData?.description);
      setValue('is_fcem_deposit_available', carbonCreditData?.is_fcem_deposit_available);
      setValue('address_location_id', carbonCreditData?.address_location_id);
      setValue('address', carbonCreditData?.addressLocation?.address);

      setValue('location', carbonCreditData?.addressLocation);
      if (carbonCreditData?.creditGoals && carbonCreditData.creditGoals.length) {
        const goalsData = [];
        GOALS.forEach((goalName) => {
          const goal = carbonCreditData.creditGoals.find((item) => item.name === goalName);
          if (goal) {
            const { description, id, name } = goal;
            goalsData.push({ description, id, name, checked: true });
          } else {
            goalsData.push({ description: '', name: goalName, checked: false });
          }
        });
        setValue('goals', goalsData);
        setGoals(goalsData);
      }
      setIsRenderComponent(true);
    }
  }, [carbonCreditData]);

  const handleApplyLocation = (value) => {
    setValue('address', value.address);
    clearErrors('address');
    if (value.locationData) {
      const locationData = value.locationData;
      setValue('location', {
        address: value.address,
        data: {
          latLng: {
            lat: value.coordinates?.lat.toString(),
            lng: value.coordinates?.lng.toString(),
          },
          country: locationData.country || '',
          country_code: locationData.country_code || '',
          locality: locationData.city || locationData.town || '',
          postcode: locationData.postcode || '',
        },
      });
    }
  };

  const deleteGoals = () => {
    setValue('goals', []);
    setGoals([]);
  };

  const handlePasteNumberValidation = (event) => {
    const pastedNumber = event.clipboardData.getData('text');
    if (isNaN(pastedNumber) || pastedNumber < 0.1) {
      event.preventDefault();
    }
  };

  return (
    <>
      {isRenderComponent ? (
        <Box className={styles.container}>
          <Typography className={styles.pageTitle}>
            {carbonCreditData?.id ? 'Edit' : 'Create'} carbon credit
          </Typography>
          <div className={styles.topBlockContainer}>
            <AvatarUploader avatarSrc={logoByProjectType} isCreditPage />
            <div className={styles.topBlockFormContainer}>
              <TextField
                {...register('name', {
                  required: requiredMessage,
                  validate: (value) => {
                    if (value.length > 50) {
                      return "You can't write more than 50 characters";
                    }
                    if (/\p{Extended_Pictographic}/u.test(value)) {
                      return 'Emoji are not allowed';
                    }
                    return true;
                  },
                })}
                className={styles.form_input}
                label="Item Name *"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <Controller
                name="quantity"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                  pattern: {
                    value: /^(?:[1-9]\d{0,5}([.,]\d{0,2})?|0([.,]\d{0,2})?|999999([.,]99)?)?$/,
                    message: 'Please enter a number between 0.1 and 999999.99.',
                  },
                }}
                render={({ field: { value, onChange, ref, ...field } }) => (
                  <InputSelect
                    {...field}
                    label="Unit *"
                    className={styles.costInput}
                    onChange={(e) => {
                      if (!e.target.value.includes(' ')) {
                        onChange(e.target.value);
                      }
                    }}
                    onPaste={handlePasteNumberValidation}
                    onSelectChange={(e) => setSelectedUnitValue(e.target.value)}
                    selectItems={units?.getUnits?.map(({ id: value, ...rest }) => ({
                      value,
                      ...rest,
                    }))}
                    selectValue={selectedUnitValue ?? ''}
                    value={value}
                    inputRef={ref}
                    error={!!errors.quantity}
                    helperText={errors.quantity?.message}
                  />
                )}
              />
              <NumberMaskInput
                {...register('price', {
                  required: requiredMessage,
                  value: '',
                  pattern: {
                    value: /^(?:[1-9]\d{0,5}(?:\.\d{1,2})?|0\.\d{1,2}|999999\.99)$/,
                    message: 'Please enter a number between 0.1 and 999999.99.',
                  },
                })}
                value={watch('price')}
                error={!!errors?.price}
                helperText={errors.price && errors.price.message}
                FormHelperTextProps={{
                  style: { ...helperTextStyles },
                }}
                onChange={(e) => {
                  setValue('price', e.target.value);
                }}
                className={styles.costInput}
                label="Basic Price*"
                data-type="price"
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault();
                  }
                }}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 'any',
                    onWheel: (e) => {
                      e.target.blur();
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="start">
                      <Box className={styles.inputUnitText}>
                        {currencies &&
                          selectedCurrency &&
                          currencies.getAvailableCurrencies.length &&
                          currencies.getAvailableCurrencies?.find(
                            (item) => item.id === selectedCurrency
                          ).code}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                  pattern: {
                    value: /^(?:[1-9]\d{0,5}([.,]\d{0,2})?|0([.,]\d{0,2})?|999999([.,]99)?)?$/,
                    message: 'Please enter a number between 0.1 and 999999.99.',
                  },
                }}
                render={({ field: { value, onChange, ref, ...field } }) => (
                  <InputSelect
                    {...field}
                    className={styles.costInput}
                    label="Amount *"
                    onChange={(e) => {
                      if (!e.target.value.includes(' ')) {
                        onChange(e.target.value);
                      }
                    }}
                    onPaste={handlePasteNumberValidation}
                    onSelectChange={(e) => setSelectedUnitValue(e.target.value)}
                    selectItems={units?.getUnits?.map(({ id: value, ...rest }) => ({
                      value,
                      ...rest,
                    }))}
                    selectValue={selectedUnitValue ?? ''}
                    value={value}
                    inputRef={ref}
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                )}
              />
            </div>
          </div>
          <Box className={styles.inputsContainer}>
            <Controller
              name="provider_id"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: requiredMessage,
                },
              }}
              render={({ field: { value, onChange, ...field } }) => (
                <Select
                  {...field}
                  value={value ?? ''}
                  controlClassName={styles.selectInput}
                  label="Provider *"
                  onChange={onChange}
                  items={providers?.getCarbonProviders.map(({ id: value, ...rest }) => ({
                    value,
                    ...rest,
                  }))}
                  renderValue={(selected) => {
                    return (
                      selected &&
                      providers?.getCarbonProviders.find((item) => item.id === selected)?.name
                    );
                  }}
                  error={!!errors.provider_id}
                  helperText={errors.provider_id?.message}
                />
              )}
            />
            <TextField
              {...register('ext_project_id', {
                required: requiredMessage,
                validate: (value) => {
                  if (value.length > 50) {
                    return "You can't write more than 50 characters";
                  }
                  if (/\p{Extended_Pictographic}/u.test(value)) {
                    return 'Emoji are not allowed';
                  }
                  return true;
                },
              })}
              label="Project ID *"
              type="text"
              className={styles.form_input}
              error={!!errors.ext_project_id}
              helperText={errors.ext_project_id?.message}
            />
            <TextField
              {...register('ext_credit_id', {
                required: requiredMessage,
                validate: (value) => {
                  if (value.length > 50) {
                    return "You can't write more than 50 characters";
                  }
                  if (/\p{Extended_Pictographic}/u.test(value)) {
                    return 'Emoji are not allowed';
                  }
                  return true;
                },
              })}
              label="Credit ID *"
              type="text"
              className={styles.form_input}
              error={!!errors.ext_credit_id}
              helperText={errors.ext_credit_id?.message}
            />
            <TextField
              {...register('link', {
                required: requiredMessage,
                validate: (value) => {
                  const linkTest =
                    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
                  if (!linkTest.test(value)) {
                    return 'Invalid URL';
                  }
                  if (/\p{Extended_Pictographic}/u.test(value)) {
                    return 'Emoji are not allowed';
                  }
                  return true;
                },
              })}
              label="Link *"
              type="text"
              className={styles.form_input}
              error={!!errors.link}
              helperText={errors.link?.message}
            />
            <Controller
              style={{ width: '100%' }}
              {...register('vintage_from', {
                required: requiredMessage,
                value: '',
              })}
              control={control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Vintage from *"
                    minDate={1901}
                    maxDate={dateValues.to}
                    value={value ? new Date(`${value}`) : null}
                    onChange={(e) => {
                      setDateValues((prevValue) => ({ ...prevValue, from: new Date(e).getTime() }));
                      onChange(e);
                    }}
                    views={['year']}
                    className={styles.datepicker}
                    slotProps={{
                      textField: () => ({
                        error: !!errors.vintage_from,
                        readOnly: true,
                        helperText: errors.vintage_from?.message,
                      }),
                    }}
                    slots={{
                      openPickerIcon: () => (
                        <CalendarIcon
                          style={{
                            width: 26,
                            height: 26,
                            cursor: 'pointer',
                          }}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              style={{ width: '100%' }}
              {...register('vintage_to', {
                required: requiredMessage,
                value: '',
              })}
              control={control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Vintage to *"
                    minDate={dateValues.from || 1901}
                    value={value ? new Date(`${value}`) : null}
                    onChange={(e) => {
                      setDateValues((prevValue) => ({ ...prevValue, to: new Date(e).getTime() }));
                      onChange(e);
                    }}
                    views={['year']}
                    className={styles.datepicker}
                    slotProps={{
                      textField: () => ({
                        error: !!errors.vintage_to,
                        readOnly: true,
                        helperText: errors.vintage_to?.message,
                      }),
                    }}
                    slots={{
                      openPickerIcon: () => (
                        <CalendarIcon
                          style={{
                            width: 26,
                            height: 26,
                            cursor: 'pointer',
                          }}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="standard_id"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: requiredMessage,
                },
              }}
              render={({ field: { value, onChange, ...field } }) => (
                <Select
                  {...field}
                  value={value ?? ''}
                  controlClassName={styles.selectInput}
                  label="Standard *"
                  onChange={onChange}
                  items={standard?.getCarbonStandards.map(({ id: value, ...rest }) => ({
                    value,
                    ...rest,
                  }))}
                  renderValue={(selected) => {
                    return (
                      selected &&
                      standard?.getCarbonStandards.find((item) => item.id === selected)?.name
                    );
                  }}
                  error={!!errors.standard_id}
                  helperText={errors.standard_id?.message}
                />
              )}
            />
            <Controller
              name="project_type_id"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: requiredMessage,
                },
              }}
              render={({ field: { value, onChange, ...field } }) => (
                <Select
                  {...field}
                  value={value ?? ''}
                  label="Project Type *"
                  controlClassName={styles.selectInput}
                  onChange={onChange}
                  items={projectType?.getCarbonProjectType.map(({ id: value, ...rest }) => ({
                    value,
                    ...rest,
                  }))}
                  renderValue={(selected) => {
                    setLogoByProjectType(
                      projectType?.getCarbonProjectType.find((item) => item.id === selected)
                        ?.logoPath
                    );
                    return (
                      selected &&
                      projectType?.getCarbonProjectType.find((item) => item.id === selected)?.name
                    );
                  }}
                  error={!!errors.project_type_id}
                  helperText={errors.project_type_id?.message}
                />
              )}
            />
          </Box>
          <Box className={styles.formContainer}>
            <TextField
              {...register('address', {
                required: requiredMessage,
              })}
              placeholder="Location *"
              className={styles.locationInput}
              onClick={() => setIsLocationModal(true)}
              error={!!errors.address}
              InputProps={{
                style: { cursor: 'pointer' },
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <img src="/images/map-icon.svg" />
                  </InputAdornment>
                ),
              }}
              helperText={errors.address?.message}
            />
            <Box className={styles.regionSelectInputContainer}>
              <Controller
                name="region_id"
                required
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                }}
                render={({ field: { value, onChange, ...field } }) => (
                  <Select
                    {...field}
                    value={value ?? ''}
                    label="Region *"
                    controlClassName={styles.selectInput}
                    onChange={onChange}
                    items={region?.showRegions.map(({ id: value, ...rest }) => ({
                      value,
                      ...rest,
                    }))}
                    renderValue={(selected) => {
                      return (
                        selected && region?.showRegions.find((item) => item.id === selected)?.name
                      );
                    }}
                    error={!!errors.region_id}
                    helperText={errors.region_id?.message}
                  />
                )}
              />
            </Box>
          </Box>
          <Box className={styles.formContainer}>
            <Box className={styles.textareaContainer}>
              <TextField
                multiline
                maxRows={30}
                minRows={2}
                {...register('description', {
                  required: 'Please fill out the rest fields. This is mandatory.',
                  value: '',
                  validate: (value) => {
                    if (value.length > 3000) {
                      return "You can't write more than 3000 characters";
                    }
                    if (/\p{Extended_Pictographic}/u.test(value)) {
                      return 'Emoji are not allowed';
                    }
                    return true;
                  },
                })}
                key={carbonCreditData?.description}
                className={styles.modal_input}
                error={!!errors.description}
                helperText={errors.description?.message}
                label="Description *"
              />
            </Box>
          </Box>
          <Box className={styles.formContainer}>
            <Box className={styles.checkboxRows}>
              <Controller
                name="is_fcem_deposit_available"
                control={control}
                render={({ field: { value, onChange, innerRef, ...field } }) => (
                  <CheckBox
                    {...field}
                    name={'is_fcem_deposit_available'}
                    label="FCEM holders' loyalty program"
                    defaultValue={false}
                    value={value}
                    inputRef={innerRef}
                    onChange={onChange}
                  />
                )}
              />
              <Image src={'/images/payment/fcem.svg'} width={24} height={24} />
            </Box>
          </Box>
          {goals && goals.length ? (
            <Box className={styles.goalsContainer}>
              <Box className={styles.goalsList}>
                {goals.map(
                  (item) =>
                    item.checked && (
                      <Image
                        key={item.name}
                        src={`/images/community/${item.name}.png`}
                        width={28}
                        height={28}
                      />
                    )
                )}
              </Box>
              <button
                type="button"
                className={`${styles.buttonOutline} ${styles.buttonNonBorder}`}
                onClick={() => setIsAddGoals(true)}
              >
                <Image src="/images/edit-blue.svg" width={14} />
                <div>edit Goals</div>
              </button>
              <button type="button" className={styles.buttonOutline} onClick={() => deleteGoals()}>
                <Image src="/images/delete-blue.svg" width={18} />
                <div>Delete Goals</div>
              </button>
            </Box>
          ) : (
            <button
              type="button"
              className={styles.buttonOutline}
              onClick={() => setIsAddGoals(true)}
            >
              <Image src="/images/plus-blue.svg" width={16} />
              ADD Goals
            </button>
          )}
          <LocationModal
            title="Choose your location"
            isModelOpen={isLocationModal}
            handleClose={() => setIsLocationModal(false)}
            coordinatesValue={coordinates}
            handleApply={handleApplyLocation}
          />
        </Box>
      ) : (
        <PartialLoader loading={true} />
      )}
    </>
  );
};
