import React from 'react';
import { Box } from '@mui/material';
import styles from './ProductAndServicesPage.module.scss';
import { ProductCard } from '../ProductsPage/ProductCard';
import { ProductCardListMode } from '../ProductsPage/ProductCardListMode';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Map } from '../../../modules/tt-map';
import { Loader } from 'tt-ui-lib/core';

const ServicesTab = ({
  isLoading,
  activeMode,
  productsList,
  openProduct,
  pageCount,
  currentPage,
  setCurrentPage,
  mapApiKey,
  user,
  pageType,
  editProduct,
  handleLoginClick,
  onUpdate,
}) => {
  return (
    <>
      {isLoading ? (
        <Box className={styles.noDataBlock}>
          <Loader />
        </Box>
      ) : (
        <>
          {activeMode === 'gridMode' && productsList.length > 0 && (
            <Box className={styles.productList}>
              {productsList.map((item) => (
                <ProductCard
                  key={item.id}
                  existData={item}
                  onUpdate={onUpdate}
                  pressCard={() => openProduct(item.id, item.user_id)}
                  editProduct={(e) => editProduct(e, item.id, item.company_id)}
                  isSeller={!user.id || item.user_id === user?.id}
                  handleLoginClick={handleLoginClick}
                  user={user}
                  pageType={pageType}
                  viewBtnText="More"
                  buyBtnText="More"
                  productType="service"
                  middlePressCard={() => window.open(`/dashboard/service/${item.id}`, '_blank')}
                />
              ))}
            </Box>
          )}
          {activeMode === 'listMode' && productsList.length > 0 && (
            <Box className={styles.listMode}>
              {productsList.map((item) => (
                <ProductCardListMode
                  key={item.id}
                  existData={item}
                  onUpdate={onUpdate}
                  pressCard={() => openProduct(item.id, item.user_id)}
                  middlePressCard={() => window.open(`/dashboard/service/${item.id}`, '_blank')}
                  editProduct={(e) => editProduct(e, item.id, item.company_id)}
                  handleLoginClick={handleLoginClick}
                  user={user}
                  pageType={pageType}
                  productType="service"
                  viewBtnText="More"
                  buyBtnText="More"
                  isSeller={!user.id || item.user_id === user?.id}
                />
              ))}
            </Box>
          )}
          {activeMode !== 'map' && pageCount > 1 ? (
            <div className={styles.paginationBlock}>
              <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={currentPage}
                count={pageCount}
                className={styles.pagination}
                renderItem={(props) => (
                  <PaginationItem
                    className={[
                      styles.paginationItem,
                      currentPage === props.page ? styles.activePaginationItem : '',
                    ]}
                    {...props}
                    disableRipple
                  />
                )}
                onChange={(event, value) => {
                  localStorage.setItem('currentPage', JSON.stringify(value));
                  setCurrentPage(value);
                }}
              />
              <div className={styles.paginationText}>
                Page {currentPage} of {pageCount}
              </div>
            </div>
          ) : null}
          {activeMode === 'map' && productsList.length > 0 && (
            <Box className={styles.mapContainer}>
              <Box className={styles.map}>
                <Map
                  coordinatesList={[
                    {
                      lat: 27.8247427,
                      lng: -82.75040159999999,
                    },
                  ]}
                  apiKey={mapApiKey}
                />
              </Box>
            </Box>
          )}
          {productsList.length === 0 && !isLoading && (
            <Box className={styles.noDataBlock}>No items found...</Box>
          )}
        </>
      )}
    </>
  );
};

export default ServicesTab;
