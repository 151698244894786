import { SocialAccount } from './socialAccount';
import styles from './socialPage.module.scss';
import { SocialMediaAccount } from './socialMediaAccount';
import { useFormContext } from 'react-hook-form';
import { HelpTooltip } from 'tt-ui-kit';
import React from 'react';

export const SocialForm = ({ contactsData = [], setContactsData, socialsData = [] }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className={styles.socialBody}>
        <div className={styles.titleBlock}>
          <h2>Contact information</h2>
          <HelpTooltip
            tooltipText="This is public information"
            iconClassName={styles.helpIconStyle}
          />
        </div>
        <SocialAccount
          contacts={contactsData}
          setContactsData={setContactsData}
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <div className={styles.titleBlock}>
          <h2>Social media</h2>
        </div>
        <SocialMediaAccount
          socialsData={socialsData}
          register={register}
          errors={errors}
          setValue={setValue}
        />
      </div>
    </>
  );
};
