import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { Link, Route, useLocation, useSearchParams } from 'react-router-dom';
import { EDIT_PAYMENT_ACCOUNT_GET_LINK } from '../../../api';
import PartialLoader from '../../../components/layout/PartialLoader';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

//4. Create page with The Payment System redirection - Component to Register new account
const RegistrationPage = () => {
  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState(null);
  const [basePage, setBasePage] = useState(null);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const [sellerLinkMutation, { data, loading, error }] = useMutation(EDIT_PAYMENT_ACCOUNT_GET_LINK);

  useEffect(() => {
    if (searchParams) {
      const searchParamsId = searchParams.get('id');
      const basePage = searchParams.get('basePage');
      if (searchParamsId) {
        setCompanyId(searchParamsId);
      }
      if (basePage) {
        setBasePage(basePage);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (companyId && basePage) {
      sellerLinkMutation({
        variables: {
          input: {
            company_id: `${companyId}`,
            payment_system: `stripe`,
            return_url: `${process.env.REACT_APP_MARKET}/payment/return?basePage=${basePage}`,
            refresh_url: `${process.env.REACT_APP_MARKET}/payment/registerAccount?basePage=${basePage}&id=${companyId}`,
          },
        },
      });
    }
  }, [companyId, sellerLinkMutation, basePage]);

  useEffect(() => {
    if (data?.editPaymentAccountAndGetLink?.url) {
      //7. Redirect to Payment System url
      window.location.href = data?.editPaymentAccountAndGetLink?.url;
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setOpenErrorAlert(true);
      console.error(error);
    }
  }, [error]);

  return (
    <>
      <PartialLoader loading={loading} isLightContainer />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          Something went wrong, try again
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegistrationPage;
