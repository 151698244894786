import React, { useState, useCallback } from 'react';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import styles from './select.module.scss';
const SelectComponent = ({
  getValues,
  values,
  name,
  small,
  valueField,
  labelField,
  placeholder,
  defaultValue,
  isEdit,
  changeValue,
  disabled = false,
  required = true,
  onScrollSelect = false,
  menuProps = {},
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  if (isEdit) {
    setValue(name, defaultValue);
  }

  const getError = () => {
    if (name.includes('.')) {
      const parts = name.split('.');
      let value = errors;
      for (const part of parts) {
        let partValue = part;
        if (value && value.hasOwnProperty(partValue)) {
          value = value[partValue];
        } else if (Array.isArray(value)) {
          if (partValue.includes('[')) {
            partValue = partValue.replace('[', '');
            partValue = partValue.replace(']', '');
          }
          value = value[partValue];
        } else {
          return null;
        }
      }
      return value;
    }
    return errors[name];
  };

  return (
    <FormControl error={getError()} fullWidth>
      <Select
        disabled={disabled}
        placeholder={placeholder}
        className={styles.select}
        value={getValues(name)}
        displayEmpty
        defaultValue=""
        sx={{
          padding: '0px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
        }}
        inputProps={
          required
            ? register(name, {
                required: 'Please fill out the rest fields. This is mandatory.',
                value: '',
              })
            : register(name)
        }
        onChange={(e) => {
          setValue(name, e.target.value);
          changeValue(e.target.value);
          if (name === 'industry_group') {
            setValue('subindustry', '');
          }
        }}
        MenuProps={{ ...menuProps, style: { maxHeight: 300, maxWidth: 500 } }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {!!values &&
          values.map((item, index) => (
            <MenuItem
              className={styles.menuItem}
              key={index}
              value={valueField ? item[valueField] : item}
              disabled={item.disabled}
            >
              {item[labelField]}
            </MenuItem>
          ))}
      </Select>
      {getError() && !disabled && (
        <FormHelperText sx={{ marginLeft: 0 }}>{getError().message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectComponent;
