import { Modal } from 'tt-ui-kit';
import styles from './ConnectStripeModal.module.scss';
import React from 'react';
import { Box, Typography } from '@mui/material';
import StripeIcon from '../../icons/StripeIcon';

const ConnectStripeModal = ({ open, onClose, companyId }) => {
  const openWindow = (pageName, id) => {
    if (typeof window !== 'undefined' && id) {
      let url = `${process.env.REACT_APP_MARKET}/${pageName}?basePage=${window.location.pathname}&id=${id}`;
      window.open(`${url}`, '_blank');
    }
  };
  return (
    <Modal
      title={'Connect payment system'}
      open={open}
      onClose={onClose}
      className={styles.modal}
      closeOnlyByControls
    >
      <Box className={styles.body}>
        <Typography>
          To start accepting payments for your goods and services, please link your Stripe account.
        </Typography>
        <Box className={styles.modalAction}>
          <button
            onClick={() => openWindow(`payment/registerAccount`, companyId)}
            className={styles.connectStripeButton}
            type="button"
          >
            <span className={styles.iconWrapper}>
              <StripeIcon />
            </span>
            <span>CONNECT STRIPE</span>
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConnectStripeModal;
