import React, { useState, useEffect } from 'react';
import styles from './DescriptionTab.module.scss';
import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Goal from './Goal';

const DescriptionTab = ({ carbon, goals }) => {
  const [firstGoals, setFirstGoals] = useState([]);
  const [secondGoals, setSecondGoasls] = useState([]);

  useEffect(() => {
    if (goals?.length > 9) {
      setFirstGoals(goals.slice(0, 9));
      setSecondGoasls(goals.slice(9));
    } else {
      setFirstGoals(goals);
      setSecondGoasls([]);
    }
  }, [goals]);

  return (
    <Box className={styles.tabContainer}>
      <Box className={styles.block}>
        <Typography className={styles.parameters}>
          Project ID: <span>{carbon?.ext_project_id}</span>
        </Typography>
        <Typography className={styles.parameters}>
          Credit ID: <span>{carbon?.ext_credit_id}</span>
        </Typography>
        <Typography className={styles.parameters}>
          Vintage:{' '}
          <span>
            {carbon?.vintage_from}-{carbon?.vintage_to}
          </span>
        </Typography>
        <Typography className={styles.parameters}>
          Standard: <span>{carbon?.standard?.name}</span>
        </Typography>
        <Typography className={styles.parameters}>
          Project Type: <span>{carbon?.projectType?.name}</span>
        </Typography>
        <Link href={carbon?.link} target="_blank" className={styles.url}>
          {carbon?.link}
        </Link>
        <Box className={styles.address}>
          {carbon?.region?.name} {carbon?.addressLocation?.address}
        </Box>
      </Box>
      <Box className={styles.block}>
        <Typography className={styles.label}>About credit</Typography>
        <Box className={styles.description}>{carbon?.description}</Box>
      </Box>
      {(firstGoals?.length > 0 || secondGoals?.length > 0) && (
        <Box className={styles.block}>
          <Typography className={styles.label}>Goals</Typography>
          <Box className={styles.goalBlocks}>
            <Box className={styles.columnGroup}>
              {firstGoals?.map((item, index) => (
                <Goal item={item} key={index} />
              ))}
            </Box>
            <Box className={styles.columnGroup}>
              {secondGoals?.map((item, index) => (
                <Goal item={item} key={index} />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DescriptionTab;
