import React from 'react';
import Box from '@mui/material/Box';
import styles from './ExpertAssessmentTab.module.scss';

const ExpertAssessmentTab = () => {
  return (
    <>
      <Box className={styles.container}>
        <p className={styles.message}>
          The business profile is yet to complete its self-assessment process
        </p>
        <p className={styles.subMessage}>Currently, there are no results available on this page.</p>
      </Box>
    </>
  );
};

export default ExpertAssessmentTab;
