import React, { useState } from 'react';
import { openNotification } from 'tt-ui-lib/core';
import styles from './ComingSoon.module.scss';
import { checkSubscribeEmail, sendSubscribeEmail } from '../../subscribes/endpoints/subscribes';

const ComingSoon = ({ text, HideBoxShadow }) => {
  const [emailSend, setEmailSend] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [status, setStatus] = useState(true);

  const checkEmail = async () => checkSubscribeEmail(emailValue);

  const sendEmail = async () => sendSubscribeEmail(emailValue);

  const checkUserIsSubscribed = async () => {
    let result = { subscribed: false, succes: false };

    await checkEmail()
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          openNotification({ type: 'error', message: 'Please, sign in for subscribe.' });
        } else {
          openNotification({
            type: 'error',
            message: 'Error while making request. Try again later.',
          });
        }
      });

    return result;
  };

  const onSend = async () => {
    if (emailValue === '') {
      openNotification({
        type: 'error',
        message: 'Please, fill your email.',
      });
      setStatus(false);
      return;
    }

    const { subscribed, success } = await checkUserIsSubscribed();

    if (subscribed && success) {
      openNotification({
        type: 'error',
        message: 'This email is already subscribed. Try another email.',
      });
      setStatus(false);
    } else if (success) {
      sendEmail()
        .then((response) => {
          if (response.data.subscribed === true && response.data.success === true) {
            openNotification({ type: 'success', message: "You're subscribed to our updates!" });
            setStatus(true);
            setEmailSend(true);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            openNotification({ type: 'error', message: 'Please, sign in for subscribe.' });
          } else {
            openNotification({
              type: 'error',
              message: 'Error while making request. Try again later.',
            });
            setStatus(false);
          }
        });
    }
  };

  return (
    <>
      <div className={styles.container} style={{ boxShadow: HideBoxShadow ? 'none' : '' }}>
        <div className={styles.imgRow}>
          <img src="/images/comingSoon.svg" alt="comingSoon" className={styles.mainImg} />
        </div>
        <div className={styles.textRow}>
          <p>{text}</p>
        </div>
        {!emailSend ? (
          <div className={styles.inputBox}>
            <input
              placeholder="Your email"
              type="text"
              value={emailValue}
              onChange={(e) => {
                setStatus(true);
                setEmailValue(e.target.value);
              }}
              style={{ borderColor: status ? '#efefef' : '#D32F2F' }}
            />
            <button onClick={onSend} type="button">
              Keep me updated
            </button>
          </div>
        ) : (
          <span className={styles.span}>We’ll keep you updated</span>
        )}
        {/* {showModal && (
          <>
            <Backdrop
              open={showModal}
              onClose={() => setShowModal(false)}
              sx={{ backgroundColor: 'transparent' }}
            >
              <div
                className={styles.modalContainer}
                style={{ backgroundColor: status.success ? '#2D8342' : '#D32F2F' }}
              >
                <img
                  src={status.success ? '/images/Check.png' : '/images/Crosshair.png'}
                  alt="check"
                />
                <span>{status.success ? "You're subscribed to our updates!" : status.data}</span>
              </div>
            </Backdrop>
          </>
        )} */}
      </div>
    </>
  );
};

export default ComingSoon;
