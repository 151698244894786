import React, { useContext, useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Box } from '@mui/material';
import CompanyInfoLocationItem from '../CompanyPage/CompanyInfoLocationItem';
import CompanyInfoSdgsItem from '../CompanyPage/CompanyInfoSdgsItem';
import CompanyInfoItem from '../CompanyPage/CompanyInfoItem';
import CompanyInfoAvatar from '../CompanyPage/CompanyInfoAvatar';
import TabSelect from '../../components/tabs';
import AboutTab from './CompanyInfoTabs/AboutTab';
import LocationModal from '../../components/locationModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  GET_COMPANY_BY_ID,
  GET_COMPANY_SERVICES,
  GET_COMPANY_CONTACT_INFOS,
  GET_COMPANY_SOCIAL_LINKS,
} from '../../api';
import styles from './CompanyItemPage.module.scss';
import userContext from '../../context/User/userContext';
import PartialLoader from '../../components/layout/PartialLoader';
import ContactTab from './CompanyInfoTabs/ContactTab';
import { checkDomain } from '../../utils/checkDomain';
import SustainabilityTab from '../CompanyPage/CompanyInfoTabs/SustainabilityTab';
import ProposalsTab from '../CompanyPage/CompanyInfoTabs/ProposalsTab';
import JobsTab from '../CompanyPage/CompanyInfoTabs/JobsTab';
import BlogTab from '../CompanyPage/CompanyInfoTabs/BlogTab';
import EventsTab from '../CompanyPage/CompanyInfoTabs/EventsTab';
import ReviewsTab from '../CompanyPage/CompanyInfoTabs/ReviewsTab';
import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-lib/core';
const isProduction = process.env.REACT_APP_ENV === 'production';
let options = [
  {
    name: 'About',
    child: [],
  },
  {
    name: 'Contact',
    child: [],
  },
  {
    name: 'Sustainability',
    child: [
      {
        name: 'Self-assessment',
        child: [],
      },
      {
        name: 'Expert assessment',
        child: [],
      },
      {
        name: 'Presentations',
        child: [],
      },
      {
        name: 'Certificates',
        child: [],
      },
    ],
  },
  {
    name: 'Proposals',
    child: [],
  },
];

if (!isProduction) {
  options = [
    ...options,
    {
      name: 'Jobs',
      child: [
        {
          name: 'All posting',
          child: [],
        },
        {
          name: 'Open',
          child: [],
        },
        {
          name: 'Closed',
          child: [],
        },
        {
          name: 'Draft',
          child: [],
        },
      ],
    },
    {
      name: 'Blog',
      child: [
        {
          name: 'All',
          child: [],
        },
        {
          name: 'Posts',
          child: [],
        },
        {
          name: 'Videos',
          child: [],
        },
        {
          name: 'Articles',
          child: [],
        },
        {
          name: 'News',
          child: [],
        },
        {
          name: 'Podcasts',
          child: [],
        },
        {
          name: 'Activity',
          child: [],
        },
      ],
    },
    {
      name: 'Events',
      child: [
        {
          name: 'All events',
          child: [],
        },
        {
          name: 'Upcoming events',
          child: [],
        },
        {
          name: 'Past events',
          child: [],
        },
      ],
    },
    {
      name: 'Reviews ',
      child: [],
    },
  ];
}

export const CompanyItemPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });
  const [searchParams, setSearchParams] = useSearchParams();
  const [website, setWebsite] = useState(null);
  const [address, setAddress] = useState('');
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isCreatePost, setIsCreatePost] = useState(false);
  const [websiteGreen, setWebsiteGreen] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [activeChildTab, setActiveChildTab] = useState(0);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [getCompanyById, { data: company, loading }] = useLazyQuery(GET_COMPANY_BY_ID);
  const [getCompanyServices, { data: services }] = useLazyQuery(GET_COMPANY_SERVICES);
  const [getCompanyContactInfos, { data: infos }] = useLazyQuery(GET_COMPANY_CONTACT_INFOS);
  const [getCompanySocialLinks, { data: socials }] = useLazyQuery(GET_COMPANY_SOCIAL_LINKS);

  useEffect(() => {
    (async () => {
      await getCompanyById({
        variables: {
          id: id,
        },
      }).then();
    })();
  }, []);

  useEffect(() => {
    let activeTabFromUrl = searchParams.get('activeTab');
    if (activeTabFromUrl && activeTabFromUrl === 'proposals') {
      setActiveTab(3);
      searchParams.delete('activeTab');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (activeTab !== 5) {
      setIsCreatePost(false);
    }
  }, [activeTab]);

  const onModalClose = () => {
    setIsLocationModal(false);
  };

  useEffect(() => {
    if (company) {
      if (company.showCompanyById === null) {
        navigate('/dashboard/companiesList');
      } else if (company.showCompanyById?.user_id === userId) {
        navigate(`/dashboard/company/${id}/edit`);
      } else {
        if (company.showCompanyById.is_draft && company.showCompanyById?.user_id !== userId) {
          navigate('/dashboard/marketplace', {
            replace: true,
            state: {
              isOpenNotification: true,
              openNotification: openNotification({
                type: 'error',
                message: 'You dont have access to this page',
              }),
            },
          });
        } else {
          setCompanyInfo(company.showCompanyById);
          if (company.showCompanyById?.addressLocation) {
            setCoordinates({
              lng: parseFloat(company.showCompanyById?.addressLocation.lng),
              lat: parseFloat(company.showCompanyById?.addressLocation.lat),
            });
          }
          setWebsite(company.showCompanyById?.website);
          getCompanyServices({
            variables: {
              company_id: id,
            },
          }).then();
          getCompanyContactInfos({
            variables: {
              company_id: id,
            },
          }).then();

          getCompanySocialLinks({
            variables: {
              company_id: id,
            },
          }).then();
        }
      }
    }
  }, [company, userId]);

  useEffect(() => {
    if (website) {
      (async () => {
        const check = await checkDomain(website);
        setWebsiteGreen(check);
      })();
    }
  }, [website]);

  useEffect(() => {
    if (services?.getCompanyService) {
      setServicesData(services?.getCompanyService);
    }
  }, [services]);

  useEffect(() => {
    if (infos?.showAllCompanyContactInfos) {
      setContactsData(infos?.showAllCompanyContactInfos);
    }
  }, [infos]);

  useEffect(() => {
    if (socials?.showAllCompanySocialLinks) {
      setSocialLinks(socials?.showAllCompanySocialLinks);
    }
  }, [socials]);

  const openSdgs = () => {
    setActiveTab(0);
    const contentLayout = document.getElementById('content-layout');
    if (activeTab === 0) {
      const rect = document.getElementById('sdgs').getBoundingClientRect();
      contentLayout.scrollTo(0, rect.y - (220 - contentLayout.scrollTop));
    } else {
      setActiveTab(0);
      setTimeout(() => {
        const rect = document.getElementById('sdgs').getBoundingClientRect();
        contentLayout.scrollTo(0, rect.y - (220 - contentLayout.scrollTop));
      }, 1000);
    }
  };

  const openMessage = () => {
    window.location.replace(`
    ${process.env.REACT_APP_TT}/dashboard/messaging?company=${companyInfo?.id}
    `);
  };

  return (
    <div className={styles.fieldWrapper}>
      <PartialLoader loading={loading} isLightContainer />
      <div className={styles.mainInformation}>
        <div className={styles.leftContent}>
          {companyInfo?.for_profit && (
            <CompanyInfoItem
              key={companyInfo.for_profit ? 'For profit' : 'Non profit'}
              iconSrc={
                companyInfo.for_profit
                  ? '/images/company/for_profit.svg'
                  : '/images/company/none_for_profit.svg'
              }
              iconWidth={24}
              iconHeight={24}
              text={companyInfo.for_profit ? 'For profit' : 'Non for profit'}
              isConfirmed={!!{}}
              isGovernmental={companyInfo.is_governmental}
            />
          )}
          {/*<CompanyInfoItem*/}
          {/*  key={'450 performed'}*/}
          {/*  iconSrc={'/images/company/check.svg'}*/}
          {/*  iconWidth={16.3}*/}
          {/*  iconHeight={12.02}*/}
          {/*  text={'450 performed'}*/}
          {/*  isConfirmed={!!{}}*/}
          {/*/>*/}
          {/*<CompanyInfoReviewsItem ranking={4.5} reviews={115} />*/}
          {companyInfo?.website && (
            <CompanyInfoItem
              key={companyInfo.website}
              iconSrc={websiteGreen ? '/images/company/smile.svg' : '/images/company/sadness.svg'}
              iconWidth={24}
              iconHeight={24}
              text={companyInfo.website}
              isConfirmed={!!{}}
              isLink={true}
            />
          )}
          {companyInfo?.companyGoals?.length > 0 && (
            <CompanyInfoSdgsItem
              onClick={() => openSdgs()}
              data={companyInfo && companyInfo.companyGoals}
            />
          )}
          {companyInfo?.addressLocation && (
            <CompanyInfoLocationItem
              companyInfo={companyInfo}
              location={address}
              setIsLocationModal={setIsLocationModal}
            />
          )}
        </div>
        {companyInfo && <CompanyInfoAvatar user={user} companyInfo={companyInfo} />}
        <div className={styles.rightContent}>
          {/*<div*/}
          {/*  style={{*/}
          {/*    display: 'flex',*/}
          {/*    alignItems: 'center',*/}
          {/*    justifyContent: 'space-between',*/}
          {/*    padding: '0 11px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <IconButton type="button">*/}
          {/*    <Image src="/images/company/follow.svg" width={22} height={16} />*/}
          {/*  </IconButton>*/}
          {/*  <IconButton type="button">*/}
          {/*    <Image src="/images/company/like.svg" width={20} height={18.35} />*/}
          {/*  </IconButton>*/}
          {/*  <CompanyInfoMenu companyId={companyInfo.id} type={companyInfo.type} />*/}
          {/*</div>*/}
          {companyInfo?.id && (
            <button className={styles.editBtn} type="button" onClick={openMessage}>
              Message
            </button>
          )}
          {/*<button className={styles.editBtn} type="button">*/}
          {/*  Hire*/}
          {/*</button>*/}
          {/*<button className={styles.editBtn} type="button">*/}
          {/*  Invite to job*/}
          {/*</button>*/}
        </div>
      </div>
      <Box sx={{ width: '100%' }}>
        <TabSelect
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setActiveChildTab={setActiveChildTab}
          activeChildTab={activeChildTab}
          options={options}
        />
        {companyInfo?.id && (
          <>
            {activeTab === 0 ? (
              <AboutTab
                servicesData={servicesData}
                companyInfo={companyInfo}
                isProduction={isProduction}
              />
            ) : activeTab === 1 ? (
              <ContactTab contactsData={contactsData} socialLinks={socialLinks} companyId={id} />
            ) : activeTab === 2 ? (
              <SustainabilityTab activeTab={activeChildTab} setActiveTab={setActiveChildTab} />
            ) : activeTab === 3 ? (
              <ProposalsTab companyId={companyInfo.id} user={user} />
            ) : activeTab === 4 ? (
              <JobsTab
                createPost={setIsCreatePost}
                activeTab={activeChildTab}
                setActiveTab={setActiveChildTab}
              />
            ) : activeTab === 5 ? (
              <BlogTab user={user} activeTab={activeChildTab} />
            ) : activeTab === 6 && !isCreatePost ? (
              <EventsTab activeTab={activeChildTab} />
            ) : activeTab === 7 && !isCreatePost ? (
              <ReviewsTab />
            ) : (
              <Box />
            )}
          </>
        )}
      </Box>
      <LocationModal
        title={'TransparenTerra community map'}
        isModelOpen={isLocationModal}
        handleClose={onModalClose}
        setIsOpen={setIsLocationModal}
        address={address}
        coordinatesValue={coordinates}
        showApplyButton={false}
        background="#FFFFFF"
        isEditable={false}
        showSearch={false}
        zoom={19}
      />
    </div>
  );
};
