import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styles from './modalAdd.module.scss';
import { useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 844,
  border: 'none',
  bgcolor: 'background.paper',
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
  borderRadius: '12px',
  p: 4,
};

const buttonSx = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '48px',
  textTransform: 'uppercase',
  color: '#18397A',
};

const ModalAdd = ({ type }) => {
  const { register } = useFormContext();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button onClick={handleOpen} className={styles.addManagerButton} type="button">
        <AddIcon color="#18397A" style={{ marginRight: '17px' }} />
        Add suggestion
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '600',
              fontFamily: 'Montserrat',
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add suggestion
          </Typography>
          <div>
            {type === 'payment' ? (
              <textarea
                style={{
                  width: '100%',
                  borderRadius: '12px',
                  padding: '20px',
                  border: '1px solid #E3E3E3',
                  marginTop: '20px',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Montserrat',
                  height: '93px',
                }}
                maxLength={50}
                type="text"
                placeholder="Enter your accepting payment method. Use commas for several methods"
                {...register('paymentSuggestion')}
              />
            ) : (
              <textarea
                style={{
                  width: '100%',
                  borderRadius: '12px',
                  padding: '20px',
                  border: '1px solid #E3E3E3',
                  marginTop: '20px',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Montserrat',
                  height: '93px',
                }}
                maxLength={50}
                type="text"
                placeholder="Enter your delivery methods. Use commas for several methods."
                {...register('deliverySuggestion')}
              />
            )}
          </div>
          <div className={styles.buttons}>
            <button className={styles.buttonCancel} onClick={handleClose}>
              CANCEL
            </button>
            <button className={styles.buttonSend} onClick={handleClose}>
              SEND
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalAdd;
