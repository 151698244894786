import React, { useEffect, useState } from 'react';
import ErrorModal from 'components/Modals/ErrorModal';
import { useWindowSize } from 'utils/useWindowSize';
import styles from './PageContainer.module.scss';
import LoaderAfterDOM from '../LoaderAfterDOM';
import { SEO } from './RolePageContainer/SEO';

const MIN_RATIO_APPARENT_HEIGHT_TO_FULL = 0.6;

const PageContainer = ({ children, keywords, title, isImgBg }) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const windowSize = useWindowSize();

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const loader = document.getElementById('loadingsScreen');
  //     if (loader) {
  //       setTimeout(() => {
  //         loader.style.opacity = 0;
  //         loader.style.visibility = 'hidden';
  //       }, 1000);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (window) {
      if (windowSize.height / windowSize.availHeight <= MIN_RATIO_APPARENT_HEIGHT_TO_FULL) {
        setIsKeyboardOpen(true);
      }
    }
  }, [windowSize]);

  return (
    <>
      <SEO
        title={title || 'TransparenTerra'}
        ogTitle="TransparenTerra| ESG Navigation| To Restore Trust"
        ogDescription="Welcome to the cloud platform for direct impact investments that brings together experts, businesses, investors, and individuals to build transparency and ultimate trust."
        description="Welcome to the cloud platform for direct impact investments that brings together experts, businesses, investors, and individuals to build transparency and ultimate trust."
        name="TransparenTerra"
        type="article"
      />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className={`${styles.pageContainer} ${isImgBg && styles.imgBg}`} unselectable="on">
        {children}
      </div>
      {/*  <Loader /> */}
      <LoaderAfterDOM />
      <ErrorModal
        isModelOpen
        title="Please rotate your device"
        isKeyboardOpen={isKeyboardOpen}
        isOrientationModal
      />
    </>
  );
};

export default PageContainer;
