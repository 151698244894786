import axios from 'axios';
import Cookies from 'js-cookie';

export const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const axiosSSOConfigPrivate = (headers, options) => {
  const accessToken = Cookies.get('access_token');

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    ...options,
  };
};

export const axiosBlockchainProxyConfigPrivate = (headers, options) => {
  const accessBasicAuth = {
    username: 'admin',
    password: 'gfhjkm01',
  };

  return {
    auth: accessBasicAuth,
    headers: {
      ...headers,
    },
    ...options,
  };
};

export const axiosQAConfigPrivate = (headers, options) => {
  const accessToken = process.env.REACT_APP_CONCIERGE_SECRETS;

  return {
    headers: {
      Authorization: `${accessToken}`,
      ...headers,
    },
    ...options,
  };
};

// axiosClient.interceptors.request.use(async (config) => {
//   const accessToken = await Cookies.get('access_token');
//   const newConfig = config;
//
//   newConfig.headers.Authorization = `Bearer ${accessToken}`;
//
//   return newConfig;
// });
