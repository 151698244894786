import React from 'react';
import { Breadcrumbs, Link, Stack } from '@mui/material';
import styles from './breadcrumb.module.scss';

export const Breadcrumb = (props) => {
  const { data } = props;

  const breadcrumbs = [
    ...data.map((breadcrumb, index) => {
      if (index + 1 === data.length) {
        return (
          <Link className={styles.breadcrumb} underline="hover" color="black" key={index}>
            {breadcrumb.name}
          </Link>
        );
      } else {
        return (
          <Link
            className={styles.breadcrumb}
            underline="hover"
            color="black"
            to={breadcrumb.href}
            key={index}
            {...breadcrumb}
          >
            {breadcrumb.name}
          </Link>
        );
      }
    }),
  ];

  return (
    <Stack
      spacing={2}
      className={styles.container}
      style={{
        backgroundImage: "url('/images/breadimage.png')",
      }}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumbMobile}>
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};
