import Box from '@mui/material/Box';
import React from 'react';
import SelfAssessmentTab from './SelfAssessmentTab';
import ExpertAssessmentTab from './ExpertAssessmentTab';
import PresentationsTab from './PresentationsTab';
import CertificatesTab from './CertificatesTab';

const SustainabilityTab = ({ activeTab }) => {
  return (
    <>
      {activeTab === 0 ? (
        <SelfAssessmentTab />
      ) : activeTab === 1 ? (
        <ExpertAssessmentTab />
      ) : activeTab === 2 ? (
        <PresentationsTab />
      ) : activeTab === 3 ? (
        <CertificatesTab />
      ) : (
        <Box />
      )}
    </>
  );
};
export default SustainabilityTab;
