import IconButton from '@mui/material/IconButton';
import styles from './uploadFile.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { convertFileSize } from '../../../../../../utils/convertFileSize';

export const UploadMpeg = ({ files, onDeleteVideo }) => {
  return (
    <div>
      {files &&
        files.length > 0 &&
        files.map((file, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.fileName}>{file.name}</div>
            <div className={styles.fileSize}>{convertFileSize(file.size)}</div>
            <div className={styles.fileType}>{file.type}</div>
            <div className={styles.fileIcons}>
              <Tooltip title="Only for me" placement="top" arrow>
                <IconButton>
                  <img src="/images/Private.png" alt="priveate" />
                </IconButton>
              </Tooltip>
              <div onClick={() => onDeleteVideo(file.name)}>
                <IconButton aria-label="delete" disabled color="primary">
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
