import Image from '../../../components/Image';
import styles from './CompanyInfoLocationItem.module.scss';

const LOCATION_GRAY = '/images/company/location.svg';

const CompanyInfoLocationItem = ({ companyInfo, setIsLocationModal }) => (
  <>
    <div className={styles.itemWrapper} onClick={() => setIsLocationModal(true)}>
      <div className={styles.iconWrapper}>
        <Image src={LOCATION_GRAY} width={16} height={20} />
      </div>
      <div className={styles.location}>{companyInfo.addressLocation?.address}</div>
    </div>
  </>
);

export default CompanyInfoLocationItem;
