import React, { useState } from 'react';
import { IconButton, CloseIcon, Button, Hidden, ExpandMoreIcon } from 'tt-ui-kit';
import styles from './OrderReportModal.module.scss';
import { Modal, Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import Accordion from '@mui/material/Accordion/Accordion';

const CustomizedAccordion = {
  '& .MuiButtonBase-root': {
    padding: 0,
    minHeight: '0 !important',

    '& .MuiAccordionSummary-content': {
      margin: 0,

      '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#212121',
      },
    },
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  boxShadow: 'none',
};
const CustomizedAccordionDetails = {
  padding: 0,
};
const OrderReportModal = ({ open, handleClose, title }) => {
  const [expanded, setExpanded] = useState('');
  const [reportData, setReportData] = useState([
    {
      id: 1,
      label: 'Search Criteria Report',
      checked: true,
    },
    {
      id: 2,
      label: 'Product Summary Report',
      checked: true,
      children: [
        {
          id: 1,
          label: 'Include supplier information',
          checked: true,
        },
      ],
    },
    {
      id: 3,
      label: 'Individual Order Information',
      checked: true,
      children: [
        {
          id: 1,
          label: 'Include a maximum of one order per page',
          checked: true,
        },
        {
          id: 2,
          label: 'Include a maximum of two orders per page',
          checked: true,
        },
        {
          id: 3,
          label: 'Include customer contact information',
          checked: true,
        },
        {
          id: 4,
          label: 'Include order comments',
          checked: true,
        },
        {
          id: 5,
          label: 'Include supplier information',
          checked: true,
        },
        {
          id: 6,
          label: 'Include product pricing',
          checked: true,
        },
      ],
    },
    {
      id: 4,
      label: 'Customer Summary By Address',
      checked: true,
    },
    {
      id: 5,
      label: 'Customer Order Subtotals',
      checked: true,
    },
  ]);

  const onSubmit = () => {
    console.log(reportData);
  };

  const checkReport = (
    checked,
    reportIndex,
    childrenIndex,
    recursion = false,
    updatedReportData
  ) => {
    const updatedReports = [...reportData];
    if (reportIndex >= 0 && childrenIndex === null) {
      if (recursion) {
        updatedReportData[reportIndex].checked = updatedReportData[reportIndex].children.every(
          (child) => child.checked
        );
        setReportData(updatedReportData);
        return;
      }
      updatedReports[reportIndex].checked = checked;
      if (updatedReports[reportIndex].children) {
        updatedReports[reportIndex].children = updatedReports[reportIndex].children.map(
          (childrenItem) => ({ ...childrenItem, checked })
        );
      }
      setReportData(updatedReports);
      return;
    }

    if (reportIndex >= 0 && childrenIndex >= 0) {
      updatedReports[reportIndex].children[childrenIndex].checked = checked;
      if (checked) {
        setReportData(updatedReports);
        checkReport(checked, reportIndex, null, true, updatedReports);
      } else {
        updatedReports[reportIndex].checked = false;
        setReportData(updatedReports);
        return;
      }
      return;
    }
  };

  const checkboxItem = (item, reportIndex, childrenIndex = null) => (
    <FormControlLabel
      key={item.id}
      control={
        <Checkbox
          onChange={() => {
            checkReport(!item.checked, reportIndex, childrenIndex);
          }}
          checkedIcon={
            <CheckBoxOutlinedIcon style={{ color: '#212121', padding: '0 !important' }} />
          }
          checked={item.checked}
        />
      }
      className={styles.formControlLabel}
      label={<Typography className={styles.checkboxLabel}>{item.label}</Typography>}
    />
  );
  return (
    <Modal
      open={open !== null}
      BackdropProps={{
        style: {
          backgroundColor: 'white',
          backdropFilter: 'blur(14px)',
          background: 'rgba(250, 250, 250, 0.8)',
        },
      }}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className={styles.container}>
        <Box className={styles.modalTitle}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 30,
              top: 20,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.reports}>
          {reportData.map((item, reportIndex) => {
            if (item.children) {
              return (
                <Accordion
                  className={styles.accordionContainer}
                  sx={CustomizedAccordion}
                  key={item.id}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {checkboxItem(item, reportIndex)}
                  </AccordionSummary>
                  <AccordionDetails sx={CustomizedAccordionDetails}>
                    <Box className={styles.reportContainer}>
                      {item.children.map((childrenItem, index) =>
                        checkboxItem(childrenItem, reportIndex, index)
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            }
            return checkboxItem(item, reportIndex);
          })}
        </Box>
        <Box className={styles.buttonBlock}>
          <Button onClick={handleClose}>cancel</Button>
          <Button type="primary" onClick={onSubmit}>
            Generate
            <Hidden xlDown> Packaging Report</Hidden>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderReportModal;
