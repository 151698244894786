import React, { useState, useEffect } from 'react';
import CarbonItemPage from '../../components/pages/CarbonItemPage';
import { SHOW_CARBON_CREDIT } from '../../api';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import EmptyProductPage from '../../components/pages/ProductItemPage/EmptyProductPage';
import AuthWrapper from '../../components/AuthWarpper';
import Cookies from 'js-cookie';

export const CarbonItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [carbon, setCarbon] = useState({});
  const [notFoundCarbon, setNotFoundCarbon] = useState(false);
  const [showCarbonCredit] = useLazyQuery(SHOW_CARBON_CREDIT);
  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });
  const marketId = Cookies.get('marketId', { domain: domainValue });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await showCarbonCredit({
        variables: { id: id },
        fetchPolicy: 'network-only',
      });
      if (
        res.data &&
        res.data.showCarbonCreditById &&
        res.data.showCarbonCreditById.user_id !== userId &&
        res.data.showCarbonCreditById.user_id !== marketId
      ) {
        setNotFoundCarbon(false);
        setCarbon(res.data.showCarbonCreditById);
      } else if (
        res.data &&
        res.data.showCarbonCreditById &&
        (res.data.showCarbonCreditById.user_id === userId ||
          res.data.showCarbonCreditById.user_id === marketId)
      ) {
        navigate(`/dashboard/credit/${id}/preview`);
      } else {
        setNotFoundCarbon(true);
      }
      setLoading(false);
    })();
  }, [userId]);

  return notFoundCarbon ? (
    <AuthWrapper reloadAfterLogin>
      <EmptyProductPage />
    </AuthWrapper>
  ) : (
    <CarbonItemPage
      carbon={carbon}
      isSeller={false}
      loading={loading}
      setLoading={(e) => setLoading(e)}
    />
  );
};
