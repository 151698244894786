import { useState } from 'react';
import Image from '../../../../../../../components/Image';
import { styled } from '@mui/material/styles';
import { Menu, IconButton } from '@mui/material';
import clsx from 'clsx';
import ActionMenuItem from './ActionMenuItem';
import styles from './ActionMenu.module.scss';

const ActionMenu = ({ idButton, idMenu, edit, ...restProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Menu
      id="company-info-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));

  return (
    <>
      <IconButton
        id="menu-button"
        className={clsx(styles.menuBtn, restProps?.btnClassName)}
        aria-controls={open ? 'company-info-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        type="button"
        onClick={handleClick}
      >
        <Image src="/images/company/menu-dots-gray.svg" width={4} height={16} />
      </IconButton>
      <StyledMenu>
        <ActionMenuItem
          onClick={edit}
          text="Edit"
          icon="/images/company/edit-gray.svg"
          iconWidth={11.39}
          iconHeight={11.39}
        />
        <ActionMenuItem
          text="Delete"
          icon="/images/company/delete.svg"
          iconWidth={13.33}
          iconHeight={15}
        />
      </StyledMenu>
    </>
  );
};

export default ActionMenu;
