import React from 'react';
import styles from './managerAccount.module.scss';
import { TextField } from '@mui/material';
import { AdminsPage } from '../../../../adminsPage/index';

export const ManagerAccount = ({ user }) => {
  return (
    <>
      {'s' ? (
        <div>
          <div className={styles.managerRow}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '2px',
                width: '100%',
              }}
            >
              <TextField
                disabled
                value={user.first_name}
                className={styles.input}
                type="text"
                label="First name*"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '2px',
                width: '100%',
              }}
            >
              <TextField
                disabled
                value={user.last_name}
                className={styles.input}
                type="text"
                label="Last name*"
              />
            </div>
          </div>
        </div>
      ) : (
        <AdminsPage />
      )}
    </>
  );
};
