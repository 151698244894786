import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'tt-ui-kit';
import styles from './AddProposalModal.module.scss';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Radio from '@mui/material/Radio/Radio';
import PartialLoader from '../../components/PartialLoader';
import { Box, Typography } from '@mui/material';

const proposalList = [
  // {
  //   value: 'product',
  //   title: 'Product',
  //   disabled: false,
  //   description:
  //     'Generate a product page within your inventory with a detailed description to engage your customers. Add supplementary documents that substantiate the quality and certification of your products, reinforcing their credibility.',
  // },
  // {
  //   value: 'service',
  //   title: 'Service',
  //   disabled: false,
  //   description:
  //     'Specify the scope of your service provision and fill out the following form. Set up a work schedule so your clients can book a slot for a consultation.',
  // },
  {
    value: 'credit',
    title: 'Carbon credit',
    disabled: false,
    description: "Choose this option if you're about to sell the digitized carbon credits.",
  },
];

const Index = ({ visible, onClose, onSubmit, proposalUserData }) => {
  const [proposal, setProposal] = useState('product');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProposal('product');
    setTimeout(() => setLoading(false), 4000);
  }, [visible]);

  return (
    <Modal
      open={visible}
      onClose={() => onClose()}
      onClick={(e) => e.stopPropagation()}
      title="Select an option that fits your proposal best:"
      className={styles.modalContainer}
    >
      <FormControl>
        <RadioGroup
          column
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => setProposal(e.target.value)}
          defaultValue={proposal}
        >
          <PartialLoader loading={loading} isLightContainer />
          {proposalList.map((item) =>
            (item.value === 'credit' &&
              proposalUserData?.user?.roles_list &&
              JSON.parse(proposalUserData?.user?.roles_list).includes('transparenterra')) ||
            item.value !== 'credit' ? (
              <FormControlLabel
                key={item.value}
                value={item.value}
                style={{ display: 'flex', alignItems: 'flex-start', marginTop: 20 }}
                control={
                  <Radio
                    disabled={item.disabled}
                    sx={{
                      '&': {
                        color: '#212121',
                        padding: 0,
                      },
                      '&.Mui-checked': {
                        color: '#143471',
                      },
                    }}
                  />
                }
                label={
                  <Box className={styles.label}>
                    <Typography
                      className={`${styles.labelTitle} ${
                        proposal === item.value ? styles.activeLabelTitle : ''
                      }`}
                    >
                      {item.title}
                    </Typography>
                    <Typography className={styles.labelDescription}>{item.description}</Typography>
                  </Box>
                }
              />
            ) : null
          )}
        </RadioGroup>
        <Box className={styles.buttonGroup}>
          <Button type="default" onClick={() => onClose()}>
            cancel
          </Button>
          <Button type="primary" onClick={() => onSubmit(proposal)}>
            Create
          </Button>
        </Box>
      </FormControl>
    </Modal>
  );
};

export default Index;
