import clsx from 'clsx';
import React from 'react';

import styles from './PartialLoader.module.scss';

const PartialLoader = ({ loading, holderText, ...restProps }) => (
  <div
    className={clsx(
      styles.container,
      restProps?.isLightContainer ? styles.lightContainer : '',
      !loading ? styles.closed : ''
    )}
  >
    <div className={styles.logo} />
    <div className={styles.loading}>{holderText ? holderText : 'Loading...'}</div>
  </div>
);

export default PartialLoader;
