import { IconButton } from '@mui/material';
import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import styles from './deleteModal.module.scss';
import { Button } from 'tt-ui-kit';

const DeleteItemModal = ({ open, handleClose, deleteItem, title }) => {
  return (
    <Modal
      open={open !== null}
      BackdropProps={{
        style: {
          backgroundColor: 'white',
          backdropFilter: 'blur(14px)',
          background: 'rgba(250, 250, 250, 0.8)',
        },
      }}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className={styles.container}>
        <Box className={styles.modalTitle}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 30,
              top: 20,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.buttonBlock}>
          <Button onClick={handleClose}>cancel</Button>
          <Button type="primary" onClick={deleteItem}>
            yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteItemModal;
