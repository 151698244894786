import React from 'react';
import { Box } from '@mui/material';
import styles from './AllPostingTab.module.scss';
import ActionMenu from './ActionMenu';

const posts = [
  {
    name: 'Manager',
    status: 'open',
    subTitle: 'Hourly: $3.00-$10.00 Time: More than 6 months, 30+ hrs/week - Posted 12 minutes ago',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
  },
  {
    name: 'Manager',
    status: 'draft',
    subTitle: 'Hourly: $3.00-$10.00 Time: More than 6 months, 30+ hrs/week - Posted 12 minutes ago',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
  },
  {
    name: 'Manager',
    status: 'closed',
    subTitle: 'Hourly: $3.00-$10.00 Time: More than 6 months, 30+ hrs/week - Posted 12 minutes ago',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
  },
  {
    name: 'Manager',
    status: 'open',
    subTitle: 'Hourly: $3.00-$10.00 Time: More than 6 months, 30+ hrs/week - Posted 12 minutes ago',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
  },
];

const AllPostingTab = ({ setIsCreatePost }) => {
  return (
    <Box>
      {posts.map((post, index) => (
        <Box key={index} className={styles.cardContainer}>
          <Box className={styles.cardHeader}>
            <Box className={styles.cardHeaderNameBlock}>
              {post.status === 'draft' && (
                <Box className={styles.postStatus}>Job posting is closed</Box>
              )}
              <div
                className={styles.postName}
                style={post.status === 'draft' ? { color: '#C4C4C4' } : {}}
              >
                {post.name}
              </div>
            </Box>
            <Box className={styles.cardHeaderButtons}>
              {post.status !== 'draft' && (
                <button type="button" className={styles.postJob}>
                  {post.status !== 'closed' ? 'Post job' : 'Closed job'}
                </button>
              )}
              <ActionMenu edit={() => setIsCreatePost(true)} />
            </Box>
          </Box>
          <div className={styles.subTitle}>{post.subTitle}</div>
          <div className={styles.description}>{post.description}</div>
          <button type="button" className={styles.showMore}>
            Show more
          </button>
        </Box>
      ))}
    </Box>
  );
};
export default AllPostingTab;
