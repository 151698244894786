import React, { useState, useEffect, useRef } from 'react';
import { DownIcon, UpIcon, MUIBox } from 'tt-ui-kit';
import styles from './NestedSelect.module.scss';
import { Box, MenuItem } from '@mui/material';

const NestedSelect = ({
  options,
  setActiveTab,
  activeTab,
  setActiveChildTab,
  activeChildTab,
  disableSelectItem = false,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownChildIndex, setOpenDropdownChildIndex] = useState(null);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const selectDropdownItem = (index) => {
    setActiveTab(index);
    if (!options[index].child || (options[index].child && !options[index].child.length)) {
      setOpenDropdown(false);
      setOpenDropdownChildIndex(null);
      if (options[index].child) {
        setActiveChildTab(0);
      }
    } else {
      if (openDropdownChildIndex === index) {
        setOpenDropdownChildIndex(null);
      } else {
        setOpenDropdownChildIndex(index);
      }
      if (activeTab !== index) {
        setActiveChildTab(0);
      }
    }
  };

  const selectChildDropdownItem = (index) => {
    setActiveChildTab(index);
    setOpenDropdown(false);
  };

  return (
    <MUIBox ref={wrapperRef} className={styles.dropdownContainer}>
      <Box
        className={styles.dropdownInput}
        style={openDropdown ? { borderRadius: '16px 16px 0 0' } : {}}
        onClick={() => setOpenDropdown((current) => !current)}
      >
        <Box>{`${options[activeTab].name} ${
          options[activeTab].child && options[activeTab].child.length > 0
            ? ` - ${options[activeTab].child[activeChildTab].name}`
            : ''
        }`}</Box>
        {openDropdown ? (
          <UpIcon style={{ width: 14, height: 14, marginTop: '-4px' }} />
        ) : (
          <DownIcon style={{ width: 14, height: 14, marginTop: '-4px' }} />
        )}
      </Box>
      {openDropdown && (
        <Box className={styles.dropdownOptions}>
          {options.map((item, index) => (
            <MenuItem key={index} disabled={activeTab !== index && disableSelectItem}>
              <Box>
                <Box
                  className={styles.dropdownOptionBlock}
                  onClick={() => selectDropdownItem(index)}
                >
                  <Box
                    className={styles.dropdownOptionName}
                    style={
                      activeTab === index ? { color: '#143471', fontWeight: 700, fontSize: 16 } : {}
                    }
                  >
                    {item.name}
                  </Box>
                </Box>
                {openDropdownChildIndex === index && (
                  <Box>
                    {item.child.map((childItem, childIndex) => (
                      <Box
                        onClick={() => selectChildDropdownItem(childIndex)}
                        key={childIndex}
                        style={
                          activeChildTab === childIndex
                            ? { color: '#143471', fontWeight: 700, fontSize: 16 }
                            : {}
                        }
                        className={styles.dropdownChildOptionName}
                      >
                        {childItem.name}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </MenuItem>
          ))}
        </Box>
      )}
    </MUIBox>
  );
};
export default NestedSelect;
