import { axiosClient, axiosSSOConfigPrivate } from '../../api-config';
import { API_SUBSCRIBE_EMAIL_CHECK, API_SUBSCRIBE_EMAIL_SEND } from '../urls';

export const checkSubscribeEmail = async (email) => {
  const data = { email: email };
  return axiosClient.post(API_SUBSCRIBE_EMAIL_CHECK, data, axiosSSOConfigPrivate());
};

export const sendSubscribeEmail = async (email) => {
  const data = { email: email };
  return axiosClient.post(API_SUBSCRIBE_EMAIL_SEND, data, axiosSSOConfigPrivate());
};
