import React, { useContext, useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Box } from '@mui/material';
import CompanyInfoLocationItem from './CompanyInfoLocationItem';
import CompanyInfoSdgsItem from './CompanyInfoSdgsItem';
import CompanyInfoItem from './CompanyInfoItem';
import CompanyInfoAvatar from './CompanyInfoAvatar';
import CompanyInfoMenu from './CompanyInfoMenu';
import TabSelect from '../../components/tabs';
import AboutTab from './CompanyInfoTabs/AboutTab';
import ContactTab from './CompanyInfoTabs/ContactTab';
import DocumentFlowTab from './CompanyInfoTabs/DocumentFlowTab';
import JobsTab from './CompanyInfoTabs/JobsTab';
import LocationModal from '../../components/locationModal';
import { AddProposalModal } from '../../modules/tt-modals';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  EDIT_COMPANY_CONTACT_INFOS,
  EDIT_COMPANY_SOCIAL_LINKS,
  GET_COMPANY_EXTENDED_BY_ID,
  GET_COMPANY_SERVICES,
  GET_USER,
} from '../../api';
import styles from './CompanyInfo.module.scss';
import userContext from '../../context/User/userContext';
import PartialLoader from '../../components/layout/PartialLoader';
import { FormProvider, useForm } from 'react-hook-form';
import { checkDomain } from '../../utils/checkDomain';
import TeamTab from './CompanyInfoTabs/TeamTab';
import KybModal from 'components/Modals/KybModal';
import Cookies from 'js-cookie';
import ConnectStripeModal from '../../components/Modals/ConnectStripeModal';

const isProduction = process.env.REACT_APP_ENV === 'production';

let options = [
  {
    name: 'About',
    child: [],
  },
  {
    name: 'Contact',
    child: [],
  },
];
if (!isProduction) {
  options = [
    ...options,
    {
      name: 'Team',
      child: [],
    },
    {
      name: 'Аnalytics',
      // child: [
      //   {
      //     name: 'Profile analytics',
      //     child: [],
      //   },
      //   {
      //     name: 'Data analytics',
      //     child: [],
      //   },
      //   {
      //     name: 'Reviews',
      //     child: [],
      //   },
      // ],
      child: [],
    },
    {
      name: 'Document Flow',
      child: [
        {
          name: 'All documents',
          child: [],
        },
        {
          name: 'Bills and invoices',
          child: [],
        },
        {
          name: 'Contracts',
          child: [],
        },
        {
          name: 'NDA/MOU',
          child: [],
        },
        {
          name: 'Other',
          child: [],
        },
      ],
    },
    {
      name: 'Jobs',
      child: [
        {
          name: 'All posting',
          child: [],
        },
        {
          name: 'Open',
          child: [],
        },
        {
          name: 'Closed',
          child: [],
        },
        {
          name: 'Draft',
          child: [],
        },
      ],
    },
  ];
}
export const CompanyInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isCreatePost, setIsCreatePost] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeChildTab, setActiveChildTab] = useState(0);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [socialsData, setSocialsData] = useState([]);
  const [editContacts, setEditContacts] = useState(false);
  const [websiteGreen, setWebsiteGreen] = useState(null);
  const [editSocialMedia, setEditSocialMedia] = useState(false);
  const [isAddProposalModal, setIsAddProposalModal] = useState(false);
  const [proposalUserData, setProposalUserData] = useState([]);
  const [kybModalOpen, setKybModalOpen] = useState(false);
  const [openStripeConnectionModal, setOpenStripeConnectionModal] = useState(false);

  const methods = useForm();
  const [getCompanyById, { data: company, loading }] = useLazyQuery(GET_COMPANY_EXTENDED_BY_ID);
  const [getCompanyServices, { data: services }] = useLazyQuery(GET_COMPANY_SERVICES);
  const [editCompanySocialLinks] = useMutation(EDIT_COMPANY_SOCIAL_LINKS);
  const [editCompanyContactInfos] = useMutation(EDIT_COMPANY_CONTACT_INFOS);
  const [getUser] = useLazyQuery(GET_USER);

  useEffect(() => {
    if (user?.id && id) {
      getCompanyById({
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      }).then();
    }
  }, [user, id]);

  useEffect(() => {
    if (activeTab !== 5) {
      setIsCreatePost(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (userId && id && company) {
      if (company.showCompanyById === null) {
        navigate('/dashboard/companiesList');
      } else if (company.showCompanyById?.user_id !== userId) {
        navigate(`/dashboard/company/${id}`);
      } else {
        setCompanyInfo(company.showCompanyById);
        if (company.showCompanyById?.addressLocation) {
          setCoordinates({
            lng: parseFloat(company.showCompanyById?.addressLocation.lng),
            lat: parseFloat(company.showCompanyById?.addressLocation.lat),
          });
        }
        if (company.showCompanyById.companyContactInfos) {
          setContactsData(company.showCompanyById.companyContactInfos);
        }
        if (company.showCompanyById.companySocialLinks) {
          setSocialsData(company.showCompanyById.companySocialLinks);
        }
        setWebsite(company.showCompanyById?.website);
        getCompanyServices({
          variables: {
            company_id: id,
          },
          fetchPolicy: 'network-only',
        }).then();
      }
    }
  }, [userId, id, company]);

  useEffect(() => {
    if (website) {
      (async () => {
        const check = await checkDomain(website);
        setWebsiteGreen(check);
      })();
    }
  }, [website]);

  useEffect(() => {
    if (services?.getCompanyService) {
      setServicesData(services?.getCompanyService);
    }
  }, [services]);

  const openSdgs = () => {
    setActiveTab(0);
    const contentLayout = document.getElementById('content-layout');
    if (activeTab === 0) {
      const rect = document.getElementById('sdgs').getBoundingClientRect();
      contentLayout.scrollTo(0, rect.y - (220 - contentLayout.scrollTop));
    } else {
      setActiveTab(0);
      setTimeout(() => {
        const rect = document.getElementById('sdgs').getBoundingClientRect();
        contentLayout.scrollTo(0, rect.y - (220 - contentLayout.scrollTop));
      }, 1000);
    }
  };

  const onModalClose = () => {
    setIsLocationModal(false);
  };

  const onProposalModalClose = () => {
    setIsAddProposalModal(false);
  };

  const createProposal = (type) => {
    navigate(`/dashboard/proposals/${type}/add?company=${id}&create-from=company`);
  };

  const openMyShopPage = () => {
    navigate(`/dashboard/company/${id}/store`);
  };

  const onSubmit = async (data) => {
    switch (activeTab) {
      case 1:
        if (data.social && editSocialMedia) {
          const socialLinks = Object.keys(data.social)
            .map((key) => ({
              ...data.social[key],
            }))
            .filter((item) => item.link !== '');
          let companySocialLinks = await editCompanySocialLinks({
            variables: {
              input: {
                company_id: id,
                socialLinks: socialLinks,
              },
            },
          });
          if (companySocialLinks?.data && companySocialLinks.data.editCompanySocialLinks) {
            setSocialsData(companySocialLinks.data.editCompanySocialLinks);
          }
          setEditSocialMedia(false);
        }

        if (data.contacts && editContacts) {
          let accounts = data.contacts.map((account) => {
            const itemData = {
              email: account.email,
              tel: account.tel,
              department_name: account.department_name,
            };
            if (account.id) {
              itemData.id = account.id;
            }
            return itemData;
          });
          const response = await editCompanyContactInfos({
            variables: {
              input: {
                company_id: id,
                companyInfos: accounts,
              },
            },
          });
          if (response?.data && response.data.editCompanyContactInfos.length > 0) {
            setContactsData(response.data.editCompanyContactInfos);
            setEditContacts(false);
          }
        }
      default:
        return false;
    }
  };

  const joinMarket = () => {
    setOpenStripeConnectionModal(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.fieldWrapper}>
          <PartialLoader loading={loading} isLightContainer />
          <div className={styles.mainInformation}>
            <div className={styles.leftContent}>
              {companyInfo?.id && (
                <CompanyInfoItem
                  key={companyInfo.for_profit ? 'For profit' : 'Non profit'}
                  iconSrc={
                    companyInfo.for_profit
                      ? '/images/company/for_profit.svg'
                      : '/images/company/none_for_profit.svg'
                  }
                  iconWidth={24}
                  iconHeight={24}
                  text={companyInfo.for_profit ? 'For profit' : 'Non for profit'}
                  isConfirmed={!!{}}
                  isGovernmental={companyInfo.is_governmental}
                />
              )}
              {companyInfo?.website && websiteGreen !== null && (
                <CompanyInfoItem
                  key={companyInfo.website}
                  iconSrc={
                    websiteGreen ? '/images/company/smile.svg' : '/images/company/sadness.svg'
                  }
                  iconWidth={24}
                  iconHeight={24}
                  text={companyInfo.website}
                  isConfirmed={!!{}}
                  isLink={true}
                />
              )}
              {companyInfo?.companyGoals?.length > 0 && (
                <CompanyInfoSdgsItem
                  onClick={() => openSdgs()}
                  data={companyInfo && companyInfo.companyGoals}
                />
              )}
              {companyInfo?.addressLocation && (
                <CompanyInfoLocationItem
                  companyInfo={companyInfo}
                  location={address}
                  setIsLocationModal={setIsLocationModal}
                />
              )}
            </div>
            {companyInfo && <CompanyInfoAvatar user={user} companyInfo={companyInfo} />}
            <div className={styles.rightContent}>
              {companyInfo?.paymentAccountList.some((item) => item.completed_onboarding) ? (
                <button
                  type="button"
                  className={styles.addProposalBtn}
                  onClick={() => openMyShopPage()}
                >
                  Go to Market
                </button>
              ) : (
                <button className={styles.joinMarketBtn} onClick={joinMarket}>
                  Joint Market
                </button>
              )}
              {companyInfo?.id && (
                <CompanyInfoMenu
                  companyId={companyInfo.id}
                  type={companyInfo.type}
                  companyName={companyInfo.company_name}
                />
              )}
            </div>
          </div>
          <Box>
            <TabSelect
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setActiveChildTab={setActiveChildTab}
              activeChildTab={activeChildTab}
              options={options}
            />
            {companyInfo?.id && (
              <>
                {activeTab === 0 ? (
                  <AboutTab
                    isProduction={isProduction}
                    servicesData={servicesData}
                    setServicesData={setServicesData}
                    companyInfo={companyInfo}
                    setCompanyInfo={setCompanyInfo}
                  />
                ) : activeTab === 1 ? (
                  <ContactTab
                    contactsData={contactsData}
                    socialLinks={socialsData}
                    companyId={id}
                    setContactsData={setContactsData}
                    setEditContacts={setEditContacts}
                    editContacts={editContacts}
                    setEditSocialMedia={setEditSocialMedia}
                    editSocialMedia={editSocialMedia}
                  />
                ) : activeTab === 2 ? (
                  <TeamTab />
                ) : activeTab === 3 ? (
                  /* <AnalyticsTab activeTab={activeChildTab} setActiveTab={setActiveChildTab} id={id} /> */
                  <Box className={styles.comingSoonBlock}>
                    <img src="/images/comingSoon.svg" alt="comingSoon" />
                  </Box>
                ) : activeTab === 4 ? (
                  <DocumentFlowTab activeTab={activeChildTab} setActiveTab={setActiveChildTab} />
                ) : activeTab === 5 && !isCreatePost ? (
                  <JobsTab
                    createPost={setIsCreatePost}
                    activeTab={activeChildTab}
                    setActiveTab={setActiveChildTab}
                  />
                ) : (
                  <Box />
                )}
              </>
            )}
          </Box>
          <LocationModal
            title={'TransparenTerra community map'}
            isModelOpen={isLocationModal}
            handleClose={onModalClose}
            setIsOpen={setIsLocationModal}
            address={address}
            coordinatesValue={coordinates}
            isEditable={false}
            showSearch={false}
            zoom={19}
            background="#FFFFFF"
          />
          <AddProposalModal
            visible={isAddProposalModal}
            onClose={onProposalModalClose}
            onSubmit={createProposal}
            proposalUserData={proposalUserData}
          />
        </form>
      </FormProvider>
      <KybModal
        open={kybModalOpen}
        setOpen={setKybModalOpen}
        companyId={companyInfo?.id}
        mode="own"
      />
      <ConnectStripeModal
        open={openStripeConnectionModal}
        onClose={() => setOpenStripeConnectionModal(false)}
        companyId={companyInfo?.id}
      />
    </>
  );
};
