import React, { useContext, useEffect, useState } from 'react';
import styles from './CreditOrderInfo.module.scss';
import { Box, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AddPersonIcon,
  Button,
  CalendarIcon,
  MessageIcon,
  PersonIcon,
  WarningIcon,
} from 'tt-ui-kit';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { redirectToMessengerWithUser } from 'utils/appUtils';
import moment from 'moment/moment';
import { Timer } from '../../CartPage/OrderPlacedPaid/Timer';
import userContext from '../../../../context/User/userContext';
import CheckoutProduct from '../../CartPage/CheckoutProduct';
import { orderCreditStatusesSeller } from '../../OrdersPage/OrdersList/orderCreditStatusesSeller';
import { orderCreditStatusesCostumer } from '../../OrdersPage/OrdersList/orderCreditStatusesCostumer';

const CreditOrderInfo = ({ orderData, orderButtonActions, setOrderData, orderViewer }) => {
  const { user } = useContext(userContext);
  const customerInfo = orderData.order.user;
  const [creditStatuses, setOrderCreditStatuses] = useState({});
  const [carbonCredit, setCarbonCredit] = useState({});
  const navigate = useNavigate();

  const messageTo = (to) => {
    redirectToMessengerWithUser(to);
  };
  useEffect(() => {
    setCarbonCredit(orderData.orderItems[0].carbonCredit);
  }, [orderData]);

  useEffect(() => {
    setOrderCreditStatuses(
      orderViewer === 'seller' ? orderCreditStatusesSeller : orderCreditStatusesCostumer
    );
  }, [orderViewer]);

  return (
    <>
      {Object.keys(creditStatuses).length > 0 && (
        <Box className={styles.middleContent}>
          <Box className={styles.container}>
            <Box className={styles.header}>
              <Box className={styles.leftAside}>
                <ArrowBackIosNewIcon
                  fontSize="medium"
                  style={{ color: '#212121' }}
                  className={styles.backBtn}
                  onClick={() => navigate(-1)}
                />
                <Typography className={styles.orderId}>№ {orderData.id}</Typography>
              </Box>
              <Box
                className={styles.statusBlock}
                sx={{ backgroundColor: creditStatuses[orderData.status]?.color }}
              >
                {creditStatuses[orderData.status]?.status}
              </Box>
              {creditStatuses[orderData.status]?.options && (
                <Box className={styles.options}>
                  <MoreVertIcon sx={{ color: '#212121' }} />
                </Box>
              )}
            </Box>
            {(creditStatuses[orderData.status].hasTimer ||
              creditStatuses[orderData.status].note) && (
              <Box className={styles.timerBlock}>
                {creditStatuses[orderData.status].note && (
                  <Box className={styles.warningInfoBlock}>
                    <WarningIcon style={{ width: 24, height: 24 }} />
                    <Typography className={styles.title}>
                      {creditStatuses[orderData.status].note}
                    </Typography>
                  </Box>
                )}
                {creditStatuses[orderData.status].hasTimer && (
                  <Timer
                    startDate={orderData.order.created_at}
                    minutes={creditStatuses[orderData.status].minutes}
                    setOrderData={setOrderData}
                  />
                )}
              </Box>
            )}

            <Box className={styles.orderDateContainer}>
              <Box className={styles.orderDateBlock}>
                <CalendarIcon style={{ width: 24, height: 24 }} />
                <Typography className={styles.orderDateTitle}>Order date</Typography>
              </Box>
              <Typography className={styles.orderDate}>
                {moment(orderData.created_at).format('DD.MM.YYYY')}
              </Typography>
            </Box>
            <Box className={styles.contactsHeader}>
              <Box className={styles.contactInfosExpanded}>
                <Box className={styles.title}>
                  <PersonIcon />
                  <Typography className={styles.text}>Contacts</Typography>
                </Box>
                <Box className={styles.userInfos}>
                  {(customerInfo.first_name || customerInfo.last_name) && (
                    <Typography className={styles.infoItem}>
                      {customerInfo.first_name} {customerInfo.last_name}
                    </Typography>
                  )}
                  {customerInfo.tel && (
                    <Typography className={styles.infoItem}>{customerInfo.tel}</Typography>
                  )}
                  {customerInfo.email && (
                    <Typography className={styles.infoItem}>{customerInfo.email}</Typography>
                  )}
                </Box>
              </Box>
              {customerInfo?.id !== user.id && (
                <Box className={styles.actions}>
                  <Button
                    type="mainButton"
                    className={styles.btn}
                    onClick={() => messageTo(customerInfo?.user_id || customerInfo?.id)}
                  >
                    <MessageIcon />
                  </Button>
                  <Button type="mainButton" className={styles.btn}>
                    <AddPersonIcon />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box className={styles.listContainer}>
            <CheckoutProduct
              id={carbonCredit.id}
              productId={carbonCredit.id}
              title={carbonCredit?.name || ''}
              image={carbonCredit?.projectType?.logoPath}
              description={carbonCredit.description || ''}
              count={carbonCredit.quantity}
              price={carbonCredit.price || null}
              showingPrice={carbonCredit.price}
              discountPrice={carbonCredit.discount_price}
              isOrderPage={true}
              companyName={carbonCredit.company.company_name}
              companyAvatar={carbonCredit.company.logos}
            />
          </Box>
          {creditStatuses[orderData.status] && (
            <Box className={styles.orderActionGroup}>
              <Box className={styles.orderAction}>
                {creditStatuses[orderData.status].buttonGroups.leftAside &&
                  creditStatuses[orderData.status].buttonGroups.leftAside.map((buttonItem) => (
                    <Button
                      disabled={buttonItem.disabled}
                      key={buttonItem.id}
                      type={buttonItem.type}
                      className={`${styles.transparentButton} ${styles.buttonItem}`}
                      onClick={() => orderButtonActions[buttonItem.action]()}
                    >
                      {buttonItem.icon}
                      {buttonItem.title}
                    </Button>
                  ))}
              </Box>
              {(orderData.order.status !== 'request_customization' ||
                orderData.order.customization.histories[0].modifier_id !== user.id) && (
                <Box className={styles.orderAction}>
                  {creditStatuses[orderData.status].buttonGroups.rightAside &&
                    creditStatuses[orderData.status].buttonGroups.rightAside.map((buttonItem) => (
                      <Button
                        disabled={buttonItem.disabled}
                        key={buttonItem.id}
                        type={buttonItem.type}
                        isSubmit={!!buttonItem.isSubmit}
                        style={{ padding: '110px !important' }}
                        className={`${styles[buttonItem.className]}
                                      ${styles.buttonItem}`}
                        onClick={() => orderButtonActions[buttonItem.action]()}
                        sx={buttonItem.style || {}}
                      >
                        {buttonItem.title}
                      </Button>
                    ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default CreditOrderInfo;
