export const VIDEOS = [
  {
    original_name: 'Video 1',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
  {
    original_name: 'Video 2',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
  {
    original_name: 'Video 3',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
  {
    original_name: 'Video 4',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
  {
    original_name: 'Video 5',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
  {
    original_name: 'Video 6',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
  {
    original_name: 'Video 7',
    size: 12,
    src: '/images/company/video-cover-example.png',
  },
];
export const PRESENTATIONS = [
  {
    original_name: 'Presentation 1',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
  {
    original_name: 'Presentation 2',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
  {
    original_name: 'Presentation 3',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
  {
    original_name: 'Presentation 4',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
  {
    original_name: 'Presentation 5',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
  {
    original_name: 'Presentation 6',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
  {
    original_name: 'Presentation 7',
    size: 12,
    src: '/images/company/presentation-example.png',
  },
];
export const CERTIFICATES = [
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
  {
    original_name: 'Certificate',
    size: 7,
    src: '/images/company/certificate-example.png',
  },
];
