import React, { useEffect } from 'react';
import styles from './socialMediaAccount.module.scss';
import 'react-phone-input-2/lib/style.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField/TextField';
import Image from '../../../../../../components/Image';
const validationTexts = {
  invalidURL: 'Invalid URL',
};
export const SocialMediaAccount = ({ socialsData = [], register, errors, setValue }) => {
  useEffect(() => {
    if (socialsData.length > 0) {
      socialsData.map((item) => {
        setValue(`social.${item.resource}.link`, item.link);
      });
    }
  }, []);
  return (
    <Box className={styles.socialsContent}>
      <Box className={styles.socialItem}>
        <Image height={40} width={40} src="/images/instagram.svg" />
        <Box className={styles.socialMediaAccountRight}>
          <input type="hidden" {...register('social.instagram.resource')} value="instagram" />
          <TextField
            type="text"
            className={styles.input}
            defaultValue=""
            label="Instagram"
            {...register('social.instagram.link', {
              pattern: {
                value:
                  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                message: validationTexts.invalidURL,
              },
            })}
          />
          {errors?.social?.instagram?.link && (
            <span className={styles.errorText}>{errors?.social?.instagram.link?.message}</span>
          )}
        </Box>
      </Box>
      <Box className={styles.socialItem}>
        <Image height={40} width={40} src="/images/twitter.svg" />
        <Box className={styles.socialMediaAccountRight}>
          <input type="hidden" {...register('social.twitter.resource')} value="twitter" />
          <TextField
            type="text"
            className={styles.input}
            label="Twitter"
            {...register('social.twitter.link', {
              pattern: {
                value:
                  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                message: validationTexts.invalidURL,
              },
            })}
          />
          {errors?.social?.twitter?.link && (
            <span className={styles.errorText}>{errors?.social?.twitter.link?.message}</span>
          )}
        </Box>
      </Box>
      <Box className={styles.socialItem}>
        <Image height={40} width={40} src="/images/telegram.svg" />
        <Box className={styles.socialMediaAccountRight}>
          <input type="hidden" {...register('social.telegram.resource')} value="telegram" />
          <TextField
            type="text"
            className={styles.input}
            label="Telegram"
            {...register('social.telegram.link', {
              pattern: {
                value:
                  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                message: validationTexts.invalidURL,
              },
            })}
          />
          {errors?.social?.telegram?.link && (
            <span className={styles.errorText}>{errors?.social?.telegram.link?.message}</span>
          )}
        </Box>
      </Box>
      <Box className={styles.socialItem}>
        <Image height={40} width={40} src="/images/linkedin.svg" />
        <Box className={styles.socialMediaAccountRight}>
          <input type="hidden" {...register('social.linkedin.resource')} value="linkedin" />
          <TextField
            type="text"
            className={styles.input}
            label="Linkedin"
            {...register('social.linkedin.link', {
              pattern: {
                value:
                  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                message: validationTexts.invalidURL,
              },
            })}
          />
          {errors?.social?.linkedin?.link && (
            <span className={styles.errorText}>{errors?.social?.linkedin.link?.message}</span>
          )}
        </Box>
      </Box>
      <Box className={styles.socialItem}>
        <Image height={40} width={40} src="/images/facebook.svg" />
        <Box className={styles.socialMediaAccountRight}>
          <input type="hidden" {...register('social.facebook.resource')} value="facebook" />
          <TextField
            type="text"
            className={styles.input}
            label="Facebook"
            {...register('social.facebook.link', {
              pattern: {
                value:
                  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                message: validationTexts.invalidURL,
              },
            })}
          />
          {errors?.social?.facebook?.link && (
            <span className={styles.errorText}>{errors?.social?.facebook.link?.message}</span>
          )}
        </Box>
      </Box>
    </Box>
  );
};
