import Box from '@mui/material/Box';
import React, { useState } from 'react';
import styles from './AllEventsTab.module.scss';
import Image from '../../../../../components/Image';
import ButtonBase from '@mui/material/ButtonBase';
import { Menu, styled } from '@mui/material';
import ActionMenuItem from '../../DocumentFlowTab/Tabs/ContactsTab/ActionMenu/ActionMenuItem';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const AllEventsTab = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledMenu = styled((props) => (
    <Menu
      id="event-info-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08) !important',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));
  return (
    <>
      <Box className={styles.container}>
        {[1, 2, 3, 4, 5, 6].map((item, index) => (
          <Box key={index} className={styles.eventItem}>
            <Box className={styles.leftBlock}>
              <Image
                src="/images/templates/carbon-credit-image-example.png"
                height={96}
                width={96}
                style={{ borderRadius: '12px' }}
              />
            </Box>
            <Box className={styles.rightBlock}>
              <Box className={styles.eventInfo}>
                <Box className={styles.eventTitle}>
                  Virtual Writing Hour with the National Portrait Gallery
                </Box>
                <Box className={styles.eventDate}>Thu, Sep 1, 2022 8:30 PM EEST</Box>
                <Box className={styles.status}>Online</Box>
              </Box>
              <ButtonBase type="button" className={styles.optionsButton} onClick={handleClick}>
                <Image src="/images/company/menu-dots.svg" width={4} height={16} />
              </ButtonBase>
            </Box>
          </Box>
        ))}
        <StyledMenu>
          <ActionMenuItem text="Share" icon="/images/company/share-network.svg" />
          <ActionMenuItem text="Export" icon="/images/company/share.svg" />
        </StyledMenu>
        <div className={styles.paginationBlock}>
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={1}
            count={5}
            className={styles.pagination}
            renderItem={(props) => (
              <PaginationItem
                className={`${styles.paginationItem} ${
                  1 === props.page ? styles.activePaginationItem : ''
                }`}
                {...props}
                disableRipple
              />
            )}
            // onChange={(event, value) => setPage(value)}
          />
        </div>
      </Box>
    </>
  );
};
export default AllEventsTab;
