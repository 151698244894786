import React, { useContext, useEffect } from 'react';
import { DisconnectHandler } from '../modules/tt-disconnect';
import { useLocation } from 'react-router-dom';
import { LOGOUT_USER } from '../api';
import { useMutation } from '@apollo/client';
import { logoutUserHandler } from './appUtils';
import userContext from '../context/User/userContext';
import Cookies from 'js-cookie';

const DisconnectHandlerWrapper = () => {
  const location = useLocation();
  const { user } = useContext(userContext);
  const [logoutUser] = useMutation(LOGOUT_USER);
  const domainValue = process.env.REACT_APP_DOMAIN;
  const token = Cookies.get('access_token');
  const cookieUserId = Cookies.get('userId');

  const logOut = () => {
    logoutUserHandler(logoutUser, null, false);
  };

  const getUser = () => {
    return {
      data: {
        currentUser: user,
      },
    };
  };

  useEffect(() => {
    if (user.id && cookieUserId) {
      const clientUserId = user.id;

      if (clientUserId !== cookieUserId) {
        window.location.reload();
      }
    }
  }, [user, cookieUserId, window.location]);

  return token ? (
    <DisconnectHandler
      getUser={getUser}
      location={location}
      userId={user?.id}
      logoutHandler={logOut}
      accessToken={user?.access_token}
      logoutDelay={1200000}
      domainValue={domainValue}
    />
  ) : null;
};

export default DisconnectHandlerWrapper;
