import { Box, Tab, Tabs, Hidden } from '@mui/material';
import styles from './Tabs.module.scss';
import React from 'react';
import NestedSelect from '../nestedSelect';

const TabSelect = ({
  options,
  setActiveTab,
  activeTab,
  setActiveChildTab,
  activeChildTab,
  disableTabs = false,
}) => {
  const selectDropdownItem = (index) => {
    setActiveTab(index);
    if (!options[index].child.length) {
      setActiveChildTab(0);
    } else {
      if (activeTab !== index) {
        setActiveChildTab(0);
      }
    }
  };

  return (
    <Box>
      <Box className={styles.tabsContainer}>
        <Hidden mdDown>
          <Box
            className={`${styles.tabs} ${
              options[activeTab].child && options[activeTab].child.length ? styles.parentTabs : null
            }`}
          >
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
              value={activeTab}
              onChange={(event, value) => selectDropdownItem(value)}
            >
              {options.map((tab, index) => (
                <Tab
                  disabled={activeTab !== index && disableTabs}
                  key={index}
                  sx={disableTabs && activeTab !== index && { opacity: '0.5' }}
                  className={`${styles.tabsItem} ${
                    activeTab === index ? styles.tabsItemActive : null
                  }`}
                  label={tab.name}
                />
              ))}
            </Tabs>
          </Box>
          {options[activeTab].child && options[activeTab].child.length ? (
            <Box className={styles.tabs}>
              <Tabs
                TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
                value={activeChildTab}
                onChange={(event, value) => setActiveChildTab(value)}
              >
                {options[activeTab].child.map((tab, index) => (
                  <Tab
                    key={index}
                    className={`${styles.tabsItem} ${
                      activeChildTab === index ? styles.tabsItemActive : null
                    }`}
                    label={tab.name}
                  />
                ))}
              </Tabs>
            </Box>
          ) : null}
        </Hidden>
      </Box>
      <Hidden mdUp style={{ width: '100%' }}>
        <NestedSelect
          options={options}
          disableSelectItem={disableTabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setActiveChildTab={setActiveChildTab}
          activeChildTab={activeChildTab}
        />
      </Hidden>
    </Box>
  );
};
export default TabSelect;
