import React, { useState } from 'react';
import { Box, Hidden, Typography, Menu, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import Image from '../../../../../Image';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from '../deliveryPage.module.scss';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomizedAccordion = {
  '& .MuiButtonBase-root': {
    '&.Mui-expanded': {
      transform: 'none',
    },
    padding: '0px !important',
    margin: '0px !important',
    minHeight: '0px !important',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: '25px',
      '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#212121',
      },
    },
  },
  '&.MuiPaper-root::before': {
    backgroundColor: 'transparent',
  },
};

export const PickupItem = ({
  pickup,
  currenciesList,
  index,
  pickupFields,
  anchorEl,
  setAnchorEl,
  deletePickupMobile,
  editPickup,
  editPickupMobile,
  setIsEditModal,
  openNewPickupModal,
  pickupRemove,
  selectedCurrency,
}) => {
  const [expanded, setExpanded] = useState(0);
  const openOptions = Boolean(anchorEl);

  const StyledMenu = styled((props) => (
    <Menu
      id="pickup-options-menu"
      anchorEl={anchorEl}
      open={openOptions}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function findorderedDays(days) {
    days = [...days];
    days = days.sort((a, b) => a.id - b.id);
    const orderedDays = [];
    let startDay = days[0];
    let endDay = days[0];

    for (let i = 1; i < days.length; i++) {
      const currentDay = days[i];
      const previousDay = days[i - 1];

      if (currentDay.id === previousDay.id + 1) {
        endDay = currentDay;
      } else {
        if (startDay === endDay) {
          orderedDays.push(startDay.name);
        } else if (startDay.id + 1 !== endDay.id) {
          orderedDays.push(`${startDay.name}-${endDay.name}`);
        } else {
          orderedDays.push(startDay.name);
          orderedDays.push(endDay.name);
        }
        startDay = currentDay;
        endDay = currentDay;
      }
    }

    // Add the last consecutive days
    if (startDay === endDay) {
      orderedDays.push(startDay.name);
    } else {
      orderedDays.push(`${startDay.name}-${endDay.name}`);
    }

    return `${orderedDays.join(', ')} `;
  }

  const openCloseEpanded = (index) => {
    if (pickupFields.length > 0 || pickup) {
      if (index === expanded) {
        setExpanded(null);
      } else {
        setExpanded(index);
      }
    }
  };

  return (
    <Accordion
      sx={CustomizedAccordion}
      key={pickup.key}
      className={styles.accordionContainer}
      expanded={expanded === index}
      onChange={() => openCloseEpanded(index)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`$Pickup name (${pickup.name})`}
        id={`pickup${pickup.id}`}
      >
        <Box className={styles.platformPickup}>
          <Box className={styles.pickupTitle}>
            <Image
              src="/images/market-place/pickup.svg"
              width={19.5}
              className={styles.pickupIcon}
            />
            <Typography className={styles.title}>
              {pickup.name} {pickup.district && `(${pickup.district})`}
            </Typography>
          </Box>
          <Hidden mdDown>
            <Box
              className={styles.pickupActions}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RemoveRedEyeOutlinedIcon />
              <Image
                src="/images/delete-icon-black.svg"
                height={18}
                width={16}
                onClick={() => {
                  pickupRemove(index);
                }}
              />
              <EditIcon
                onClick={() => {
                  editPickup(pickup, index);
                  setIsEditModal(true);
                  openNewPickupModal();
                }}
              />
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box className={styles.optionsIcon} onClick={handleClick}>
              <MoreVertIcon />
            </Box>
            <StyledMenu>
              <Box className={styles.optionsMenu}>
                <Box
                  className={styles.menuItem}
                  onClick={(e) => {
                    deletePickupMobile(e, pickup, index);
                  }}
                >
                  <Image src="/images/delete-icon-black.svg" height={18} width={16} />
                  <Typography>Delete</Typography>
                </Box>
                <Box
                  className={styles.menuItem}
                  onClick={(e) => {
                    editPickupMobile(e, pickup, index);
                  }}
                >
                  <EditIcon />
                  <Typography>Edit</Typography>
                </Box>
              </Box>
            </StyledMenu>
          </Hidden>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px !important' }}>
        <Box className={styles.pickupInfo}>
          <Box className={styles.infoItem}>
            {pickup.addressLocation && pickup.addressLocation.address && (
              <Box className={styles.info}>
                <Image src="/images/market-place/location.svg" width={19.5} />
                <Typography className={styles.title}>{pickup.addressLocation.address}</Typography>
              </Box>
            )}
            {pickup.schedules &&
              pickup.schedules.map(
                (schedule) =>
                  (schedule.weekDays.length > 0 || schedule.from || schedule.to) && (
                    <Box className={styles.info} key={schedule.key}>
                      <Image src="/images/market-place/clock-icon.svg" width={19.5} />
                      <Typography className={styles.title}>
                        {!!schedule.weekDays.length && findorderedDays(schedule.weekDays)}
                        {!!schedule.from &&
                          !!schedule.to &&
                          `${moment(dayjs.utc(new Date(schedule.from)).tz(dayjs.tz.guess()).$d).format('HH:mm')} - 
                          ${moment(dayjs.utc(new Date(schedule.to)).tz(dayjs.tz.guess()).$d).format('HH:mm')}`}
                      </Typography>
                    </Box>
                  )
              )}
            {(pickup.contact_person || pickup.tel || pickup.email) && (
              <Box className={styles.info}>
                <Image src="/images/market-place/person-icon.svg" width={19.5} />
                <Box className={styles.contact}>
                  {pickup.contact_person && <Typography>{pickup.contact_person}</Typography>}
                  {pickup.tel && <Typography>{pickup.tel}</Typography>}
                  {pickup.email && <Typography>{pickup.email}</Typography>}
                </Box>
              </Box>
            )}
            {pickup.shippingCost?.value && (
              <Box className={styles.info}>
                <Image src="/images/market-place/many-icon.svg" width={19.5} />
                <Typography className={styles.title}>
                  {pickup.shippingCost?.value}&nbsp;
                  {currenciesList.find((currency) => currency.id === selectedCurrency)?.symbol}
                </Typography>
              </Box>
            )}
            {pickup.delivery_pickup_price && (
              <Box className={styles.info}>
                <Image src="/images/market-place/many-icon.svg" width={19.5} />
                <Typography className={styles.title}>
                  {pickup.delivery_pickup_price}&nbsp;
                  {currenciesList.find((currency) => currency.id === selectedCurrency)?.symbol}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography className={styles.description}>{pickup.info}</Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
