import { Box } from '@mui/material';
import styles from './ContactTab.module.scss';
import React from 'react';
import Image from '../../../../components/Image';

const ContactTab = ({ socialLinks, contactsData }) => {
  return (
    <Box>
      <Box className={styles.container}>
        <div className={styles.title}>Contact information</div>
        {contactsData.map((item, index) => (
          <div key={index}>
            <div className={styles.subTitle}>{item.department_name}</div>
            <div className={styles.departmentBlock}>
              {item.email && (
                <div className={styles.departmentBlockItem}>
                  <Image src={'/images/company/mail.svg'} width={13.67} height={13.66} />
                  <div className={styles.departmentBlockText}>{item.email}</div>
                </div>
              )}
              {item.tel && (
                <div className={styles.departmentBlockItem}>
                  <Image src={'/images/company/phone.svg'} width={13.67} height={13.66} />
                  <div className={styles.departmentBlockText}>{item.tel}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </Box>
      <Box className={styles.container}>
        <div className={styles.title}>Social media</div>
        <div className={styles.socialBlock}>
          {socialLinks.map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="_blank"
              className={styles.socialButton}
              rel="noopener noreferrer"
            >
              <Image src={`/images/${item.resource}.svg`} width={40} height={40} />
            </a>
          ))}
        </div>
      </Box>
    </Box>
  );
};
export default ContactTab;
