import React from 'react';
import styles from './searchMap.module.scss';
import SearchIcon from '@mui/icons-material/Search';

const SearchMap = () => (
  <div>
    <div style={{ position: 'relative' }}>
      <input placeholder="Search... " className={styles.searchTestInput} />
      <div style={{ position: 'absolute', top: '14px', left: '15px' }}>
        <SearchIcon />
      </div>
    </div>
  </div>
);

export default SearchMap;
