import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import styles from './deleteIconChips.module.scss';
import CloseIcon from '@mui/icons-material/Close';

const CustomDeleteIconChips = ({ tags, handleDeleteTag }) => {
  return (
    <>
      {tags && tags.length > 0 && (
        <div className={styles.deleteChips}>
          <Stack direction="row" spacing={1}>
            {tags &&
              tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(index)}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                  className={styles.chip}
                />
              ))}
          </Stack>
        </div>
      )}
    </>
  );
};

export default CustomDeleteIconChips;
