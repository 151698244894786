import styles from './socialAccount.module.scss';
import React, { useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { DELETE_COMPANY_CONTACT } from '../../../../../../api';

export const SocialAccount = ({ contacts = [], setContactsData }) => {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [deleteCompanyContact] = useMutation(DELETE_COMPANY_CONTACT);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let onHandlePhone = (value, index) => {
    setValue(`accounts.${index}.tel`, value);
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'accounts',
    keyName: 'key',
  });

  let addAccounts = (account = { department_name: '' }) => {
    append(account);
  };

  useEffect(() => {
    setValue('accounts', []);
    if (contacts && contacts.length) {
      contacts.map((contact) => {
        addAccounts({
          department_name: contact.department_name,
          email: contact.email,
          id: contact.id,
          tel: contact.tel,
        });
      });
    }
    let accounts = getValues('accounts');
    if (accounts && accounts.length === 0) {
      addAccounts();
    }
  }, []);

  let removeAccount = async (index) => {
    if (fields[index].id) {
      const res = await deleteCompanyContact({ variables: { id: fields[index].id } });
      if (res.data) {
        setContactsData((current) => current.filter((item, i) => i !== index));
        remove(index);
        return true;
      }
    }
    remove(index);
    return true;
  };

  return (
    <div className={styles.socialAccountContainer}>
      <div>
        {fields.map((field, index) => (
          <div className={styles.socialAccountColumn} key={field.key}>
            {index > 0 && <Divider sx={{ marginBottom: '16px', marginTop: '16px' }} />}
            <div className={styles.socialAccountLeft}>
              <TextField
                type="text"
                label="Department name"
                {...register(`accounts.${index}.department_name`)}
                style={{
                  width: '100%',
                  borderColor: errors?.accounts?.[index]?.department_name && 'red',
                }}
                className={styles.input}
              />
              {errors?.accounts?.[index]?.department_name && (
                <span className={styles.errorsText}>
                  Please fill out the rest fields. This is mandatory.
                </span>
              )}
            </div>
            <div className={styles.socialAccountRight}>
              <TextField
                type="email"
                label="Email"
                {...register(`accounts.${index}.email`)}
                className={styles.input}
              />
              <div className={styles.phone}>
                <ReactPhoneInput
                  {...register(`accounts.${index}.tel`)}
                  country="ua"
                  containerStyle={{
                    borderRadius: '12px',
                  }}
                  buttonStyle={{
                    boxShadow: 'none',
                    borderRight: '1px solid #EFEFEF',
                    background: 'white',
                    borderRadius: '12px',
                    width: '99px',
                    paddingLeft: '30px',
                  }}
                  inputClass={styles.phoneInput}
                  inputStyle={{ borderRadius: '12px' }}
                  specialLabel={''}
                  value={getValues(`accounts.${index}.tel`)}
                  onChange={(e) => onHandlePhone(e, index)}
                />
              </div>
            </div>
            {fields.length > 1 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <button
                  type="button"
                  className={styles.removeContactButton}
                  onClick={() => removeAccount(index)}
                >
                  <DeleteIcon />
                  <p>Delete</p>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      {fields.length < 10 ? (
        <div>
          <button type="button" className={styles.addManagerButton} onClick={() => addAccounts()}>
            <img src="/images/plus-icon.png" alt="" className={styles.plusIcon} />
            <p>{screenWidth <= 500 ? 'Add information' : 'Add Contact information'}</p>
          </button>
        </div>
      ) : null}
    </div>
  );
};
