import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PartialLoader from '../../../components/layout/PartialLoader';

/*
 * 8. On the return page check if the user closed the parent window or not.
 */
const ReturnPage = () => {
  const [searchParams] = useSearchParams();
  const [basePage, setBasePage] = useState(null);

  useEffect(() => {
    (async () => {
      const basePage = searchParams.get('basePage');
      if (basePage) {
        setBasePage(basePage);
      }
    })();
  }, [searchParams]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    // 8. ... If the user didn't close the parent window, send postMessage to the parent window and close the current tab.
    if (window.opener) {
      window.opener.postMessage(
        {
          callback: 'onRegister',
          data: {
            status: 'ok',
          },
        },
        `${process.env.REACT_APP_MARKET}/${basePage}`
      );
      window.close();
    } else {
      // 8. ... If the user closes the parent window, redirect to the page which will be suited to business logic.
      window.location.href = process.env.REACT_APP_MARKET;
    }
  }, [basePage]);

  return (
    <>
      <PartialLoader loading={true} isLightContainer />
    </>
  );
};

export default ReturnPage;
