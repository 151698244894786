import React, { useEffect, useState } from 'react';
import { Modal, MUIBox, TextField, Typography } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import Box from '@mui/material/Box';
import { ReactComponent as CheckSvg } from '../../assets/svg/check.svg';
import { ReactComponent as CopySvg } from '../../assets/svg/copy.svg';
import { ReactComponent as InstagramSvg } from '../../assets/svg/social-media/instagram.svg';
import { ReactComponent as TwitterSvg } from '../../assets/svg/social-media/twitter.svg';
import { ReactComponent as LinkedInSvg } from '../../assets/svg/social-media/linkedIn.svg';
import { ReactComponent as FacebookSvg } from '../../assets/svg/social-media/facebook.svg';
import { ReactComponent as MessengerSvg } from '../../assets/svg/social-media/messenger.svg';
import { ReactComponent as WhatsappSvg } from '../../assets/svg/social-media/whatsapp.svg';
import { ReactComponent as TelegramSvg } from '../../assets/svg/social-media/telegram.svg';
import { ReactComponent as ViberSvg } from '../../assets/svg/social-media/viber.svg';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from 'react-share';

import styles from './ShareModal.module.scss';

export const ShareModalWrapper = ({ open, closeModal, link = '' }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyText, setCopyText] = useState('');

  useEffect(() => {
    if (link) {
      setCopyText(link);
    } else {
      setCopyText(window.location.href);
    }
  }, []);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    }
  }, [copySuccess]);

  const onCopy = () => {
    navigator.clipboard.writeText(copyText).then(() => {
      openNotification({
        type: 'success',
        message: 'Link copied in clipboard',
      });
      setCopySuccess(true);
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      onClick={(e) => e.stopPropagation()}
      title="Share"
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box>
        <Box className={styles.shareModalContent}>
          <Box className={styles.copyBlock}>
            <TextField
              className={styles.copyInput}
              variant="standard"
              hiddenLabel
              value={copyText}
              disabled
              sx={{
                '.MuiInputBase-root:before': {
                  borderColor: '#000000',
                  borderBottomStyle: 'solid !important',
                },
                '.MuiInputBase-input': {
                  color: '#000000',
                  '-webkit-text-fill-color': '#000000 !important',
                },
              }}
            />
            {copySuccess ? (
              <Box className={styles.copyBlockButton}>
                <CheckSvg />
                <Typography
                  className={`${styles.copyBlockButtonGreenText} ${styles.copyBlockButtonText}`}
                >
                  Copied!
                </Typography>
              </Box>
            ) : (
              <MUIBox className={styles.copyBlockButton} onClick={() => onCopy()}>
                <CopySvg />
                <Typography className={styles.copyBlockButtonText}>Copy link</Typography>
              </MUIBox>
            )}
          </Box>
          <Box className={styles.socialMediaList}>
            <InstapaperShareButton url={copyText} quote="Instagram">
              <Box className={styles.socialMedia}>
                <InstagramSvg />
                <Typography className={styles.copyBlockButtonText}>Instagram</Typography>
              </Box>
            </InstapaperShareButton>
            <TwitterShareButton url={copyText} quote="Twitter">
              <Box className={styles.socialMedia}>
                <TwitterSvg />
                <Typography className={styles.copyBlockButtonText}>Twitter</Typography>
              </Box>
            </TwitterShareButton>
            <LinkedinShareButton url={copyText} quote="Linkedin">
              <Box className={styles.socialMedia}>
                <LinkedInSvg />
                <Typography className={styles.copyBlockButtonText}>Linkedin</Typography>
              </Box>
            </LinkedinShareButton>
            <FacebookShareButton url={copyText} quote="Facebook">
              <Box className={styles.socialMedia}>
                <FacebookSvg />
                <Typography className={styles.copyBlockButtonText}>Facebook</Typography>
              </Box>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={copyText} quote="Facebook Messenger">
              <Box className={styles.socialMedia}>
                <MessengerSvg />
                <Typography className={styles.copyBlockButtonText}>Facebook Messenger</Typography>
              </Box>
            </FacebookMessengerShareButton>
            <WhatsappShareButton url={copyText} quote="Whatsapp">
              <Box className={styles.socialMedia}>
                <WhatsappSvg />
                <Typography className={styles.copyBlockButtonText}>Whatsapp</Typography>
              </Box>
            </WhatsappShareButton>
            <TelegramShareButton url={copyText} quote="Telegram">
              <Box className={styles.socialMedia}>
                <TelegramSvg />
                <Typography className={styles.copyBlockButtonText}>Telegram</Typography>
              </Box>
            </TelegramShareButton>
            <ViberShareButton url={copyText} quote="Viber">
              <Box className={styles.socialMedia}>
                <ViberSvg />
                <Typography className={styles.copyBlockButtonText}>Viber</Typography>
              </Box>
            </ViberShareButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
