import { Box } from '@mui/material';
import styles from './TeamTab.module.scss';
import React, { useState } from 'react';
import Image from '../../../../components/Image';
import GridMode from '../../../../components/GridMode';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useWindowSize } from '../../../../utils/useWindowSize';

const USERS = [
  {
    id: '0758a463-1747-481b-827f-5de4f888811e',
    first_name: 'Andreas',
    last_name: 'Jew',
    created_at: '2022-11-08 06:36:27',
    avatar: 'no avatar',
    invited_by_name: 'admin transparenterra',
    profession: 'Manager of PinCollet',
    roles: [
      {
        id: '2',
        name: 'expert',
      },
    ],
  },
  {
    id: '0758a463-1747-481b-827f-5de4f888811e',
    first_name: 'Andreas',
    last_name: 'Jew',
    created_at: '2022-11-08 06:36:27',
    avatar: 'no avatar',
    invited_by_name: 'admin transparenterra',
    profession: 'Manager of PinCollet',
    roles: [
      {
        id: '2',
        name: 'expert',
      },
    ],
  },
  {
    id: '0758a463-1747-481b-827f-5de4f888811e',
    first_name: 'Andreas',
    last_name: 'Jew',
    created_at: '2022-11-08 06:36:27',
    avatar: 'no avatar',
    invited_by_name: 'admin transparenterra',
    profession: 'Manager of PinCollet',
    roles: [
      {
        id: '2',
        name: 'expert',
      },
    ],
  },
];

const TeamTab = () => {
  const [pageCount, setPageCount] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const windowSize = useWindowSize();

  return (
    <Box>
      <Box className={styles.container}>
        <div className={styles.title}>New Connections</div>
        <div className={styles.collectionBlock}>
          <div className={styles.collectionItem}>
            <div className={styles.collectionItemLeftContent}>
              <Image src={'/images/company/userTemplate.png'} width={100} height={100} />
              <div>
                <div className={styles.itemTitle}>Marvin Plow</div>
                <div className={styles.description}>Invited by Robert Fox</div>
                <div className={styles.infoBlock}>
                  <Image src={'/images/company/bag.svg'} width={13.33} height={12.67} />
                  <div className={styles.info}>
                    CEO of <span>Gillette</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.collectionItemRightContent}>
              <button className={styles.btnOutline} type="button">
                Connections
              </button>
              <button className={styles.btn} type="button">
                reject
              </button>
            </div>
          </div>
          {windowSize.width > 1016 && (
            <div className={styles.collectionItem}>
              <div className={styles.collectionItemLeftContent}>
                <Image src={'/images/company/userTemplate.png'} width={100} height={100} />
                <div>
                  <div className={styles.itemTitle}>Marvin Plow</div>
                  <div className={styles.description}>Invited by Robert Fox</div>
                  <div className={styles.infoBlock}>
                    <Image src={'/images/company/bag.svg'} width={13.33} height={12.67} />
                    <div className={styles.info}>
                      CEO of <span>Gillette</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.collectionItemRightContent}>
                <button className={styles.btnOutline} type="button">
                  Connections
                </button>
                <button className={styles.btn} type="button">
                  reject
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={styles.viewAllBlock}>
          <p>View all</p>
          <Image src={'/images/company/arrowRight.svg'} width={7.85} height={13.33} />
        </div>
      </Box>
      <div className={styles.adminContainer}>
        <div className={styles.adminBlock}>
          <div className={styles.adminBlockTitle}>Admins and managers</div>
          <button type="button" className={styles.addButton}>
            <Image src={'/images/plus-white.svg'} width={14} height={14} />
            <p>{windowSize.width > 700 ? 'Add manager' : 'Add'}</p>
          </button>
        </div>
        <GridMode loading={false} users={USERS} />
      </div>
      <div className={styles.adminContainer}>
        <div className={styles.adminBlock}>
          <div className={styles.adminBlockTitle}>Employees</div>
        </div>
        <GridMode loading={false} users={USERS} />
        <div className={styles.paginationBlock}>
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={currentPage}
            count={pageCount}
            className={styles.pagination}
            renderItem={(props) => (
              <PaginationItem
                className={[
                  styles.paginationItem,
                  currentPage === props.page ? styles.activePaginationItem : '',
                ]}
                {...props}
                disableRipple
              />
            )}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
          />
          <div className={styles.paginationText}>
            Page {currentPage} of {pageCount}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default TeamTab;
