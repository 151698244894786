import styles from './OtherTab.module.scss';
import React from 'react';
import Image from '../../../../../../components/Image';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const rows = [
  { title: 'Lorem ipsum dolor sit amet', date: '01.02.2023', size: '1.5 MB' },
  { title: 'Lorem ipsum dolor sit amet', date: '01.02.2023', size: '1.5 MB' },
  { title: 'Lorem ipsum dolor sit amet', date: '01.02.2023', size: '1.5 MB' },
  { title: 'Lorem ipsum dolor sit amet', date: '01.02.2023', size: '1.5 MB' },
  { title: 'Lorem ipsum dolor sit amet', date: '01.02.2023', size: '1.5 MB' },
  { title: 'Lorem ipsum dolor sit amet', date: '01.02.2023', size: '1.5 MB' },
];

const OtherTab = () => {
  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: 32, boxShadow: 'none' }}
      sx={{ width: '100%' }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 'max-content' }}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableTitle}>Title </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Date
            </TableCell>
            <TableCell className={styles.tableTitle} align="left">
              Size
            </TableCell>
            <TableCell className={styles.tableTitle} align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={styles.tableTitle} component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell className={styles.tableCellGray} align="left">
                {row.date}
              </TableCell>
              <TableCell className={styles.tableCellGray} align="left">
                {row.size}
              </TableCell>
              <TableCell align="right">
                <div className={styles.tableCellActions}>
                  <button type="button" className={styles.tableCellAction}>
                    <Image
                      src={'/images/company/arrowSquareOut.svg'}
                      width={11.39}
                      height={11.39}
                    />
                  </button>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/edit-gray.svg'} width={11.67} height={15} />
                  </button>
                  <button type="button" className={styles.tableCellAction}>
                    <Image src={'/images/company/delete.svg'} width={11.67} height={15} />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default OtherTab;
